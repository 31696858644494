import {isEmpty, has, isArray, forEach } from "lodash";
import { callGet, callPost, callPut, callDelete, makeRequestDispatch, singleRequestDispatch } from "../api"
import { CUSTOMER_LIST, CUSTOMER_SEARCH_EMAIL, CUSTOMER_UPDATE, CUSTOMER_SET_DEVELOPER, CUSTOMER_SET_LEVEL, CUSTOMER_SET_BADGES } from "./types";

// List Customers
export const listCustomers = (params) => dispatch => {
  return singleRequestDispatch(
    callGet('/admin/monitor/user', params ),
    dispatch,
    CUSTOMER_LIST
  )
}

// Update Status based on type
export const searchAgent = (bodyParams) => dispatch => {
  return singleRequestDispatch(
    callPost('/admin/user/getByEmail', bodyParams),
    dispatch,
    CUSTOMER_SEARCH_EMAIL
  )
}

// Update Status based on type
export const updateStatus = (bodyParams) => dispatch => {
    return singleRequestDispatch(
      callPost('/admin/user/updateStatus', bodyParams),
      dispatch,
      CUSTOMER_UPDATE
    )
}
// Update Status based on type
export const setUserDeveloper = (bodyParams) => dispatch => {
    return singleRequestDispatch(
      callPost('/admin/user/developer', bodyParams),
      dispatch,
      CUSTOMER_SET_DEVELOPER
    )
}

// Update Status based on type
export const setUserLevelProject = (bodyParams) => dispatch => {
  return singleRequestDispatch(
    callPost('/admin/user/developer/level', bodyParams),
    dispatch,
    CUSTOMER_SET_LEVEL
  )
}

// Update Status based on type
export const setUserBadge = (bodyParams) => dispatch => {
  return singleRequestDispatch(
    callPost('/admin/user/badge', bodyParams),
    dispatch,
    CUSTOMER_SET_BADGES
  )
}

  
  