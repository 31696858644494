/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'; // ES6
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from "@material-ui/lab"
import { useHistory } from "react-router-dom"
import { 
    Grid,
    InputBase,
    TextField,
    Typography,
    Paper,
    IconButton,
    Button,
    Divider
  } from "@material-ui/core"
import { has, isEmpty, debounce } from "lodash"
import SearchIcon from "@material-ui/icons/SearchOutlined"
import Geocode from "react-geocode"
import config from "../config"
import GMap from "./GoogleMapPointer"
import { addPopularValidate } from "../validations/common"

Geocode.setApiKey(config.gmApiKey);
Geocode.enableDebug();

/**
  * Get the city and set the city input value to the one selected
  *
  * @param addressArray
  * @return {string}
  */
 const getCity = ( addressArray ) => {
    let city = '';
    for( let i = 0; i < addressArray.length; i++ ) {
        if ( addressArray[i].types[0] && 'administrative_area_level_2' === addressArray[i].types[0] ) {
            city = addressArray[i].long_name;
            return city;
        }
    }
}

/**
* Get the area and set the area input value to the one selected
*
* @param addressArray
* @return {string}
*/
const getArea = ( addressArray ) => {
    let area = '';
    for( let i = 0; i < addressArray.length; i++ ) {
        if ( addressArray[i].types[0] ) {
            for ( let j = 0; j < addressArray[i].types.length; j++ ) {
                if ( 
                    'sublocality_level_1' === addressArray[i].types[j] || 
                    'locality' === addressArray[i].types[j] ) {
                        area = addressArray[i].long_name;
                        return area;
                }
            }
        }
    }
}

/**
* Get the address and set the address input value to the one selected
*
* @param addressArray
* @return {string}
*/
const getState = (addressArray) => {
    let state = '';
    for( let i = 0; i < addressArray.length; i++ ) {
        for( let i = 0; i < addressArray.length; i++ ) {
            if ( addressArray[i].types[0] && 'administrative_area_level_1' === addressArray[i].types[0] ) {
                state = addressArray[i].long_name;
                return state;
            }
        }
    }
}

const useStyles = makeStyles((theme) => ({
    container:{
        padding:theme.spacing(3),
        background:'#f5f5f5',
        [theme.breakpoints.down('sm')]:{
            padding:0
        }
    },
    paper: {
        width:700,
        padding: theme.spacing(0),
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(3)
    },
    containerAutocomplete:{},
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: "100%",
    },
    input: {
        height:'2.286rem',
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    formControl:{
        margin:theme.spacing(3,0)
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));


const ModalSearchCity = props => {
    const classes = useStyles();
    const history = useHistory()
    const [loc, setLoc] = useState([0,0])
    const [address, setAddress] = useState('')
    const [google, setGoogle] = useState(null)
    const [state, setState] = useState({
        fetch:false,
        data:null,
        location:null,
        needZipcode:false,
        errors:null,
        message:null,
        errorMessage:'',
        loading:false,
        success:false
    });

    const [fields, setFields] = useState({
        name:'',
        address:'',
        lat:'',
        lng:'',
        radius:100
    })

    useEffect(()=>{
        if(props.edit) {
            setFields({
                ...fields,
                lat:props.lat,
                lng:props.lng,
                name:props.name,
                radius:props.radius?props.radius:100
            })
        }
    },[])

    const handleChange = (ev) => {

        setFields({
          ...fields,
          [ev.target.name]:ev.target.value
        })
    }

    const [query, setQuery] = useState("");
    const autoCompleteRef = useRef(null);

    const handleInput = (ev) => {
        const addr = ev.target.value
        setAddress(addr)
        if( addr.length > 2 ) {
            //setQuery(addr)
            //autocompletion()
            debounce(autocompletion, 200, {maxWait:1000})
        }
    }

    const autocompletion = () => {
        let a = new google.maps.places.Autocomplete(
            autoCompleteRef.current,
            { types: ["(cities)"], componentRestrictions: { country: "id" } }
        );
        a.setFields(["address_components", "formatted_address"]);
        a.addListener("place_changed", () => {
            const addressObject = a.getPlace();
            const query = addressObject.formatted_address;
            searchFromAddress(query)
        })
    }

    const searchAddress = (latitude, longitude) => {
        Geocode.fromLatLng(latitude.toString(),longitude.toString())
            .then(
                response => {
                    const { lat, lng } = response.results[0].geometry.location;
                    const address2 = response.results[0].formatted_address
                    let addressArray =  response.results[0].address_components
                    let city = getCity(addressArray)
                    let area = getArea(addressArray)
                    let state2 = getState(addressArray)

                    setFields({
                        ...fields,
                        address:address2,
                        lat:lat.toString(),
                        lng:lng.toString(),
                    })              
                    setState({
                        ...state,
                        fetch:true,
                    })
                }
            )
    }

    const searchFromAddress = (addr) => {
        // Get latidude & longitude from address.
        Geocode.fromAddress(addr).then(
            response => {
                const { lat, lng } = response.results[0].geometry.location;

                const address2 = response.results[0].formatted_address
                let addressArray =  response.results[0].address_components
                let city = getCity(addressArray)
                let area = getArea(addressArray)
                let state2 = getState(addressArray)
                
                setFields({
                    ...fields,
                    address:address2,
                    lat:lat,
                    lng:lng
                })
                setState({
                    ...state,
                    fetch:true,
                })
            },
            error => {
                console.error(error);
            }
        );
    }

    const onSearch = async() => {
        try {
            await addPopularValidate(fields)
        } catch (error) { 
            setState({
                ...state,
                errors:error
            })
            return
        }

        searchAddress(fields.lat, fields.lng)
    }

    const onMapLoaded = (mapProps, map) => {
        //console.log(google.maps)
        setGoogle(mapProps.google)
    }

    const onSave = async() => {
        try {
            await addPopularValidate(fields)
        } catch (error) { 
            setState({
                ...state,
                errors:error
            })
            return
        }
        props.onSave({
            name:fields.name,
            city:fields.name,
            radius:fields.radius,
            latlong:{
                lat:fields.lat,
                long:fields.lng
            }
        })
    }

    const onUpdate = async() => {
        try {
            await addPopularValidate(fields)
        } catch (error) { 
            setState({
                ...state,
                errors:error
            })
            return
        }
        props.onUpdate({
            name:fields.name,
            city:fields.name,
            radius:fields.radius,
            latlong:{
                lat:fields.lat,
                long:fields.lng
            }
        })
    }

    const onDragEnd = (coord, mapProps) => {

        const { latLng } = coord;
        const lat = latLng.lat();
        const lng = latLng.lng();

        searchAddress(lat, lng)
        setLoc([lat, lng])
        //setGoogle(mapProps.google)
    }

    return (<Paper elevation={3} 
                style={{outlineOffset:0,padding:40}} 
                className={classes.paper}>
                    
                <Alert severity="info" style={{marginBottom:20}}>
                    Please go to <a target="_blank" href="https://www.google.com/maps">https://www.google.com/maps</a> to search a region and then paste Latitude and Longitude
                </Alert>
                <Typography variant="h6" gutterBottom component="h6">
                    {props.edit ? 'Update City' : 'Create One'}
                </Typography>

                <Grid container direction="row" spacing={2}>
                    <Grid item sm={9}>
                        <TextField
                            required
                            id="name"
                            fullWidth
                            margin="normal"
                            name="name"
                            label="Name"
                            variant="outlined"
                            onChange={handleChange}
                            value={fields.name}
                            error={has(state.errors, 'name') ? true : false}
                            helperText={has(state.errors, 'name') ? state.errors.name : ''}
                            placeholder="Enter a name"
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <TextField
                            required
                            id="radius"
                            fullWidth
                            margin="normal"
                            type="number"
                            name="radius"
                            label="Radius (m)"
                            variant="outlined"
                            onChange={handleChange}
                            value={fields.radius}
                            error={has(state.errors, 'radius') ? true : false}
                            helperText={has(state.errors, 'radius') ? state.errors.radius : ''}
                            placeholder="Enter a radius"
                        />
                    </Grid>
                </Grid>
        
                {/*<TextField
                    required
                    id="city"
                    fullWidth
                    name="city"
                    margin="normal"
                    style={{marginBottom:20}}
                    label="City"
                    variant="outlined"
                    inputRef={autoCompleteRef} 
                    value={address} 
                    onChange={handleInput}
                    placeholder="Enter a city"
                />*/}

                <Grid container direction="row" spacing={2}>
                    <Grid item sm={5}>
                        <TextField
                            required
                            id="lat"
                            fullWidth
                            name="lat"
                            margin="normal"
                            style={{marginBottom:20}}
                            label="Latitude"
                            variant="outlined"
                            value={fields.lat} 
                            error={has(state.errors, 'lat') ? true : false}
                            helperText={has(state.errors, 'lat') ? state.errors.lat : 'Between -10 to 7'}
                            onChange={handleChange}
                            placeholder="Latitude between -10 to 7"
                        />
                    </Grid>
                    <Grid item sm={5}>
                        <TextField
                            required
                            id="lng"
                            fullWidth
                            name="lng"
                            margin="normal"
                            style={{marginBottom:20}}
                            label="Longitude"
                            variant="outlined"
                            value={fields.lng} 
                            error={has(state.errors, 'lng') ? true : false}
                            helperText={has(state.errors, 'lng') ? state.errors.lng : 'Between 95 to 141'}
                            onChange={handleChange}
                            placeholder="Longitude between 95 to 141"
                        />
                    </Grid>
                    <Grid item sm={2}>
                        <Button disabled={state.loading} style={{padding:15, marginTop:15}} onClick={onSearch} fullWidth={true} variant="outlined" color="default">
                            <SearchIcon />
                        </Button>
                    </Grid>
                </Grid>
            
                {state.fetch && <div style={{marginTop:10, height: "50vh", width: "100%", position:"relative" }}>
                    {state.fetch && <GMap 
                        onMapLoaded={onMapLoaded} 
                        onDragEnd={onDragEnd}
                        lat={parseFloat(fields.lat)} 
                        lng={parseFloat(fields.lng)} 
                    />}
                 </div>}

                <Button 
                    style={{marginBottom:20, marginTop:20}} 
                    size={"large"} 
                    disableElevation 
                    disabled={state.loading} variant="contained" color="primary" onClick={()=>props.edit ? onUpdate() : onSave()}>
                    {props.edit ? 'Update' : `Save & Add`}
                </Button>

                <Button disabled={state.loading} onClick={props.onClose} variant="outlined" color="default">
                    Close
                </Button>
        </Paper>)
}

export default ModalSearchCity