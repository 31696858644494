import {isEmpty, has, isArray, forEach } from "lodash";
import { callGet, callPost, callPut, callDelete, makeRequestDispatch, forkRequestDispatch, singleRequestDispatch } from "../api"
import { VARIOUS_LIST, ADD_VARIOUS, UPDATE_VARIOUS, REMOVE_VARIOUS } from "../actions/types";

// List Various
export const listVarious = () => dispatch => {
  return singleRequestDispatch(
    callGet('/various'),
    dispatch,
    VARIOUS_LIST
  )
}

// Add new various
export const addVarious = (bodyParams) => dispatch => {
  return forkRequestDispatch(
    callPost('/admin/various', bodyParams),
    dispatch,
    ADD_VARIOUS
  )
}

// Update single various
export const updateVarious = (id, bodyParams) => dispatch => {
  return forkRequestDispatch(
    callPut('/admin/various/'+id, bodyParams),
    dispatch,
    UPDATE_VARIOUS
  )
}

// Delete various
export const removeVarious = (id) => dispatch => {
  return singleRequestDispatch(
    callDelete('/admin/various/'+id),
    dispatch,
    REMOVE_VARIOUS
  )
}
