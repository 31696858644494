import React, {  Component, useState, useEffect, useRef  } from 'react';
import clsx from 'clsx';
import { useHistory } from "react-router-dom"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import config from "../../config"
import { makeStyles } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Backdrop from '@material-ui/core/Backdrop'
import Modal from '@material-ui/core/Modal'
import Fade from '@material-ui/core/Fade'
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Tags from "../../components/Tags"
import Tagging from "../../components/Tagging"
import TagRegion from "../../components/TagRegion"
import { EditorState, convertFromRaw, convertToRaw, ContentState, AtomicBlockUtils, Modifier, Entity } from 'draft-js'
import GalleryEditor, { Media } from "../../components/Editor/LauncherGallery"
import PaperTitle from "../../components/PaperTitle"
import { useDropzone } from 'react-dropzone'
import { useSnackbar } from "notistack"
import { isEmpty, isNil, kebabCase, has, isString } from "lodash"
import { listCategory, listTags, addBlog, updateBlog, uploadImages } from "../../actions/blog"
import SaveIcon from "@material-ui/icons/Save"
import CategoryModal from "../../components/Category"
import MediaGallery from "../../components/MediaGallery"
import Editor from "../../components/Editor" 
import draftToHtml from "draftjs-to-html"
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    marginBottom:20,
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 440,
  },
  fab:{
    position:"absolute", bottom:20, right:20,
    zIndex:1002
  },
  dropzone:{
    border:"4px dashed #ddd",
    display:"flex",
    height:320,
    marginTop:20,
    padding:15,
    cursor:'pointer',
    textAlign:"center",
    alignItems:"center",
    justifyContent:"center",
    color:"#aaa"
  },
  // modal styling
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
  },
  backdrop:{
      backgroundColor:'rgba(0, 0, 0, 0.86)'
  }
}));

const AddEditBlog = (props) => {
  const classes = useStyles()
  const history = useHistory()
 
  const { enqueueSnackbar } = useSnackbar()
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [preload, setPreload] = useState(false)
  const [fab, setFab] = useState(false)
  const [editorState, setEditorState] = useState(null)
  const [image, setImage] = useState(null)
  const [editImage, setEditImage] = useState(null)
  const [modal, setModal] = useState(false)
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [categories, setCategories] = useState([])
  const [tagsRaw, setTagsRaw] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])
  const [selectedTags, setSelectedTags] = useState([])
  const [selectedRegions, setSelectedRegions] = useState([])
  const [cats, setCats] = useState([])
  const [tags, setTags] = useState([])
  const [params, setParams] = useState({limit:30,page:0})
  const [heading, setHeading] = useState("Add New Blog")
  const [gallery, setGallery] = useState(false)
  const [featured, setFeatured] = useState(false)
  const [fields, setFields] = useState({
    title:'',
    authorName:'',
    isFeatured:false,
    categories:[],
    geotaggings:[],
    tags:'',
    seo_title:'',
    seo_desc:'',
    seo_keyword:''
  })

  useEffect(() => {
    refetch()
    setPreload(false)
    if( ! isNil(props.location.state) ) {
      let blog = props.location.state
      setData(blog)
      setHeading(`Edit Blog`)
      //setContentState()
      setSelectedCategories(blog.categories)
      //setTags
      if( !isEmpty(blog.tags) ) {
        setSelectedTags(blog.tags.map(tg=>tg.name))
      }
      if( !isEmpty(blog.geotaggings) ) {
        setSelectedRegions(blog.geotaggings.map(tg=>({_id:tg._id,name:tg.name,type:tg.type})))
      }
      setImage(blog.images[0])
      setFields({
        title:blog.title,
        authorName:blog.authorName,
        isFeatured:blog.isFeatured,
        seo_title:blog.seo_title,
        seo_desc:blog.seo_desc,
        seo_keyword:blog.seo_keyword,
        geotaggings:!isEmpty(blog.geotaggings) ? blog.geotaggings.map(geo=>geo._id) : [],
        tags:!isEmpty(blog.tags) ? blog.tags.map(tg=>tg.name).join(', ') : "",
        categories: isEmpty(blog.categories) ? [] : blog.categories.map(item=>item._id)
      })
      setPreload(true)
    } else {
      setHeading("Add New Blog")
      setPreload(true)
    }

  }, []);

  useEffect(() => {
    if( categories ) {
      let a = categories.map(item=>({
        name:item.name,
        slug:item.slug,
        statusActive:item.statusActive,
        _id:item._id,
      }))
      setCats(a)
    }
  }, [categories]);

  const handleScroll = () => {
    const doc = document
    const main = doc.querySelector("main")
   
    const currentScrollY = main.scrollTop;
    
    if (currentScrollY > 130 ) {
      setFab(true)
    } else {
      setFab(false)
    }
  } 

  useEffect(()=>{
    if( typeof window !== "undefined" ) {
      const doc = document
      const main = doc.querySelector("main")
      main.addEventListener("scroll", handleScroll);
    }
  },[])

  useEffect(() => {
    if( tagsRaw ) {
      let a = tagsRaw.map(item=>item.name)
      setTags(a)
    }
  }, [tagsRaw]);

  const handleChange = (ev) => {
    setFields({
      ...fields,
      [ev.target.name]:ev.target.value
    })
  }

  const onCategoryChange = (value, reason) => {
    setSelectedCategories(value)
    let a = value.map(item=>item._id)
    setFields({
      ...fields,
      categories:a
    })
  }

  const onTagsChange = (value, reason) => {
    setSelectedTags(value)
    let a = value.map(item=>{
      if( isString(item) ) {
        return item
      } else {
        return item?.name
      }
    })
    setFields({
      ...fields,
      tags:a.join(', ')
    })
  }

  const onLocationTag = (values) => {
    setSelectedRegions(values)
    let a = values.map(value=>value._id)
    setFields({
      ...fields,
      geotaggings:a
    })
  }

  const onModalCategory = () => {
    setModal(!modal)
  }

  const onSave = () => {
    let params2 = fields
    params2.content = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    let stripedHtml =  params2.content.replace(/<[^>]+>/g, '');

    if( isEmpty(fields.title) || fields.title.length < 20 ) {
      enqueueSnackbar("Blog title is required and must be greater than 20 chars",{variant:"error"})
      return;
    }

    if( isEmpty(fields.authorName)) {
      enqueueSnackbar("Blog author is required",{variant:"error"})
      return;
    }
    if( isEmpty(stripedHtml.trim()) || stripedHtml.trim().length < 200 ) {
      enqueueSnackbar("content is required and must be greater than 200 chars",{variant:"error"})
      return
    }

    if( isEmpty(fields.categories)  ) {
      enqueueSnackbar("Category is required, min. 1 category selected",{variant:"error"})
      return
    }

    if( isEmpty(image) ) {
      enqueueSnackbar("Image is required, please upload it!",{variant:"error"})
      return;
    }
    
    setLoading(true)
   
    params2.slug = kebabCase(fields.title)
    if( data ) {
      params2.images = [image._id]
      props.updateBlog(data._id, params2)
        .then(res=>{  
            setLoading(true)
            enqueueSnackbar(res?.message?res.message:"The blog is updated",{variant:"success"})
            setTimeout(() => {
              history.push('/blog')
            }, 1000);
        }).catch(err=>{
          enqueueSnackbar(err?.response?err.response.data.message:"Error add new blog",{variant:"error"})
        }).finally(_=>{
          setLoading(false)
        })

    } else {
      params2.images = [image._id]
      props.addBlog(params2)
        .then(res=>{  
          enqueueSnackbar(res?.message?res.message:"New blog is published",{variant:"success"})
        }).catch(err=>{
          enqueueSnackbar(err?.response?err.response.data.message:"Error add new blog",{variant:"error"})
        }).finally(_=>{
          setLoading(false)
        })
    }
  }

  const onRemove = () => {
    setImage(null)
  }

  const onEditRemove = () => {
    setEditImage(null)
  }

  const onUpdatedEditor = (editState) => {
    setEditorState(editState)
  }

  const onClose = () => {
    setModal(false)
  }

  const refetch = () => {
    let apiCalls = [
      props.listCategory(params),
      props.listTags(params)
    ]

    Promise.all(apiCalls).then(values=>{
      let categoriesData = values[0]
      let tagsData = values[1]
      setCategories(categoriesData)
      setTagsRaw(tagsData)
    }).catch(err=>{
      console.log(err)
    })
  }

  const mediaBlockRenderer = (block) => {
    if (block.getType() === 'atomic') {
      return {
        component: Media,
        editable: true
      };
    }
  }

  const onOpenFeatured = () => {
    setFeatured(true)
  }

  const onCloseFeatured = () => {
    setFeatured(false)
  }

  return (<Grid container direaction={"row"} justify="space-between" 
          alignItems={"space-between"} spacing={3}>
          {fab && <Fab className={classes.fab} onClick={()=>onSave()} color="primary" aria-label="add">
            <SaveIcon color={"white"} style={{color:"white"}} />
          </Fab>}
          <MediaGallery 
              title={"Pick One"}
              expanded={featured} 
              doCollapse={onCloseFeatured} 
              pushItem={(item)=>{
                  setImage(item)
                  onCloseFeatured()
              }}
          />
          <Modal 
            className={classes.modal}
            open={modal}
            onClose={onClose}
            disableBackdropClick={false}
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
                classes:{
                    root:classes.backdrop
                }
            }}
            disableScrollLock={false}
          >
              <Fade in={modal}>
                  <CategoryModal data={categories} onClose={onClose} refetch={refetch} />
              </Fade>
          </Modal>
           
          <Grid item sm={6}>
              <Typography variant="h5" gutterBottom component="h5">{heading}</Typography>
              <Button onClick={()=>history.push('/blog')} variant="outlined" color="primary">Back</Button>
          </Grid>
          <Grid item sm={6} style={{textAlign:'right'}}>
              <Button color="primary" 
                  disabled={loading} 
                  disableElevation size="large" 
                  variant="contained" onClick={()=>onSave()}> 
                    {loading?'Saving...' : 'Save'} 
              </Button>
          </Grid>
          <Grid item xs={12} md={8} lg={12}>
              <Paper elevation={3} className={classes.paper}>
                  <Grid container spacing={3}>
                      <Grid item sm={12}>
                        <TextField
                          required
                          id="title"
                          fullWidth
                          name="title"
                          label="Title"
                          variant="outlined"
                          onChange={handleChange}
                          value={fields.title}
                          inputProps={{
                            maxlength:70
                          }}
                          placeholder="Enter a blog title (Max. 70 Chars)"
                        />
                      </Grid>
                      
                      <Grid item sm={9}>
                        {selectedCategories ? <Tags 
                          id="category" 
                          value={selectedCategories}
                          onChange={(ev,value,reason)=>{
                            onCategoryChange(value, reason)
                          }}
                          label="Pilih Kategori" 
                          placeholder="Kategori" 
                          fieldComparator={"_id"}
                          fieldLabel={"name"}
                          options={cats} /> : <Tags 
                          id="category" 
                          onChange={(ev,value,reason)=>{
                            onCategoryChange(value, reason)
                          }}
                          label="Pilih Kategori" 
                          placeholder="Kategori" 
                          fieldComparator={"_id"}
                          fieldLabel={"name"}
                          options={cats} />}
                      </Grid>

                      <Grid item sm={3}>
                          <Button color="secondary" disableElevation size="large" variant="contained" onClick={()=>onModalCategory()}>Tambah Kategori</Button>
                      </Grid>

                      <Grid item sm={12}>
                        <TextField
                          required
                          id="authorName"
                          fullWidth
                          name="authorName"
                          label="Author"
                          variant="outlined"
                          onChange={handleChange}
                          value={fields.authorName}
                          placeholder="Enter author name"
                        />
                      </Grid>

                      <Grid item sm={12}>
                        <FormControlLabel
                          control={<Switch color="primary" checked={fields.isFeatured} onChange={()=>{
                            setFields({
                              ...fields,
                              isFeatured:!fields.isFeatured
                            })
                          }} name="isFeatured" />}
                          label="Featured"
                        />
                      </Grid>
                  </Grid>
              </Paper>

              <Paper elevation={3} className={classes.paper}>
                  <PaperTitle title="Content" desc="Make it content blog, support HTML tag" />
                  {preload && <Editor initialEditorState={data?.content} onFinishChange={onUpdatedEditor} />}
              </Paper>

              <Paper elevation={3} style={{maxHeight:400}} className={classes.paper}>
                  <PaperTitle title={"Tagging"} desc={"Set Geotagging & Label Tags"} />
                  <Grid container spacing={3} style={{marginTop:10}}>
                      <Grid item sm={6}>
                        {selectedTags ? <Tagging 
                          id="category" 
                          value={selectedTags}
                          onChange={(ev,value,reason)=>{
                            onTagsChange(value, reason)
                          }}
                          label="Tags" 
                          placeholder="Enter tags ex. unit, properti (separate comma)" 
                          fieldComparator={"_id"}
                          fieldLabel={"name"}
                          options={tags} /> : <Tagging 
                          id="tags" 
                          onChange={(ev,value,reason)=>{
                            onTagsChange(value, reason)
                          }}
                          label="Tags" 
                          placeholder="Enter tags ex. unit, properti (separate comma)" 
                          fieldComparator={"_id"}
                          fieldLabel={"name"}
                          options={tags} />}
                      </Grid>
                      <Grid item sm={6}>
                          {selectedRegions ? <TagRegion 
                            id="searchRegions" 
                            onChange={(value)=>{
                              onLocationTag(value)
                            }}
                            value={selectedRegions}
                            label="Tag Location" 
                            placeholder="Enter location tag (separate comma)" 
                            fieldComparator={"_id"}
                            fieldLabel={"name"}
                          /> : <TagRegion 
                              id="searchRegions" 
                              onChange={(value)=>{
                                onLocationTag(value)
                              }}
                              label="Tag Location" 
                              placeholder="Enter location tag (separate comma)"
                              fieldComparator={"_id"}
                              fieldLabel={"name"}
                          />}
                      </Grid>
                  </Grid>
              </Paper>

              <Paper elevation={3} className={classes.paper}>
                <PaperTitle title={"Image & SEO Meta"} desc={"Upload image & Custom SEO Meta"} />
                <Grid container spacing={3} style={{marginTop:10}}>
                    <Grid item sm={4} style={{textAlign:"center"}}>
                      {editImage ? <>
                        <Card style={{marginBottom:20}} onClick={()=>setFeatured(true)}>
                              <CardActionArea>
                                  <CardMedia
                                      style={{height:280}}
                                      image={editImage.url}
                                      title={editImage.title}
                                  />
                              </CardActionArea>
                          </Card>
                          <Button onClick={onRemove} color="primary" variant="outlined">Remove</Button>
                          <Button onClick={()=>setFeatured(true)} style={{marginLeft:10}}  color="primary" variant="contained">Change Photo</Button>
                        </>  : <>{
                        image ? <>
                          
                          <Card style={{marginBottom:20}} onClick={()=>setFeatured(true)}>
                              <CardActionArea>
                                  <CardMedia
                                      style={{height:280}}
                                      image={image.url}
                                      title={image.title}
                                  />
                              </CardActionArea>
                          </Card>
                          <Button onClick={onRemove} color="primary" variant="outlined">Remove</Button>
                          <Button onClick={()=>setFeatured(true)} style={{marginLeft:10}}  color="primary" variant="contained">Change Photo</Button>
                        </> : 
                        <div className={classes.dropzone} onClick={onOpenFeatured}>
                            <p>Click here to Select Image</p>
                        </div>}
                        </>}
                    </Grid>

                    <Grid item sm={8}>
                      <TextField
                        id="seo_title"
                        fullWidth
                        name="seo_title"
                        label="SEO Title"
                        variant="outlined"
                        inputProps={{maxlength:70}}
                        helperText={<div>
                          <p style={{textAlign:"right"}}>{`${fields.seo_title.length} / 70`}</p>
                        </div>}
                        onChange={handleChange}
                        value={fields.seo_title}
                        placeholder="Custom SEO Title"
                      />
                      
                      <TextField
                        id="seo_desc"
                        rows={4}
                        multiline
                        fullWidth
                        inputProps={{maxlength:140}}
                        name="seo_desc"
                        label="SEO Description"
                        variant="outlined"
                        onChange={handleChange}
                        value={fields.seo_desc}
                        helperText={<div>
                          <p style={{textAlign:"right"}}>{`${fields.seo_desc.length} / 140`}</p>
                        </div>}
                        placeholder="Custom SEO Description (max. 140 chars)"
                      />

                      <TextField
                        id="seo_keyword"
                        fullWidth
                        name="seo_keyword"
                        label="SEO Keyword"
                        variant="outlined"
                        margin="normal"
                        onChange={handleChange}
                        value={fields.seo_keyword}
                        placeholder="Keyword separate with comma (,)"
                      />
                    </Grid>
                </Grid>
              </Paper>
          </Grid>
      </Grid>
  );
}


AddEditBlog.propTypes = {
    auth: PropTypes.object.isRequired
};
  
const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, {listCategory, addBlog, updateBlog, uploadImages, listTags })(AddEditBlog)