import React, {  Component, useState, useEffect, useRef  } from 'react';
import clsx from 'clsx';
import { useHistory } from "react-router-dom"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import config from "../../config"
import { makeStyles } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import { EditorState, convertFromRaw, convertToRaw, ContentState, AtomicBlockUtils, Modifier, Entity } from 'draft-js'
import GalleryEditor, { Media } from "../../components/Editor/LauncherGallery"
import PaperTitle from "../../components/PaperTitle"
import { useSnackbar } from "notistack"
import { isEmpty, isNil, kebabCase, has, isString } from "lodash"
import { addBlog, updateBlog, uploadImages } from "../../actions/blog"
import { addNewPage, updatePage } from "../../actions/page"
import SaveIcon from "@material-ui/icons/Save"
import CategoryModal from "../../components/Category"
import MediaGallery from "../../components/MediaGallery"
import Editor from "../../components/Editor" 
import draftToHtml from "draftjs-to-html"
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    marginBottom:20,
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 440,
  },
  fab:{
    position:"absolute", bottom:20, right:20,
    zIndex:1002
  },
  dropzone:{
    border:"4px dashed #ddd",
    display:"flex",
    height:320,
    marginTop:20,
    padding:15,
    cursor:'pointer',
    textAlign:"center",
    alignItems:"center",
    justifyContent:"center",
    color:"#aaa"
  },
  // modal styling
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
  },
  backdrop:{
      backgroundColor:'rgba(0, 0, 0, 0.86)'
  }
}));

const AddEditPages = (props) => {
  const classes = useStyles()
  const history = useHistory()
 
  const { enqueueSnackbar } = useSnackbar()
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [preload, setPreload] = useState(false)
  const [fab, setFab] = useState(false)
  const [editorState, setEditorState] = useState(null)
  const [image, setImage] = useState(null)
  const [editImage, setEditImage] = useState(null)
  const [modal, setModal] = useState(false)
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [heading, setHeading] = useState("Add New Page")
  const [gallery, setGallery] = useState(false)
  const [featured, setFeatured] = useState(false)
  const [fields, setFields] = useState({
    title:'',
    authorName:'',
    slug:'',
    isFeatured:false,
    seo_title:'',
    seo_desc:'',
    seo_keyword:''
  })

  useEffect(() => {
    setPreload(false)
    if( ! isNil(props.location.state) ) {
      let blog = props.location.state
      setData(blog)
      setHeading(`Edit Page`)
      //setContentState()
      //setTags
      setImage(blog.images[0])
      setFields({
        slug:blog?.slug,
        title:blog?.title,
        authorName:blog?.authorName,
        isFeatured:blog?.isFeatured,
        seo_title:blog?.seo_title,
        seo_desc:blog?.seo_desc,
        seo_keyword:blog?.seo_keyword,
      })
      setPreload(true)
    } else {
      setHeading("Add New Page")
      setPreload(true)
    }

  }, []);

  const handleScroll = () => {
    const doc = document
    const main = doc.querySelector("main")
   
    const currentScrollY = main.scrollTop;
    
    if (currentScrollY > 130 ) {
      setFab(true)
    } else {
      setFab(false)
    }
  } 

  useEffect(()=>{
    if( typeof window !== "undefined" ) {
      const doc = document
      const main = doc.querySelector("main")
      main.addEventListener("scroll", handleScroll);
    }
  },[])

  const handleChange = (ev) => {
    let updates = {
      ...fields,
      [ev.target.name]:ev.target.value
    }
    if( ev.target.name === "title" && fields.slug === "") {
      updates.slug = kebabCase(ev.target.value)
    }
    setFields(updates)
  }

  const handleBlur = () => {

    let slugFormat = kebabCase(fields.slug)
    let updates = {
      ...fields,
      slug:slugFormat
    }
    setFields(updates)
  }
  

  const onSave = () => {
    let params2 = fields
    params2.content = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    let stripedHtml =  params2.content.replace(/<[^>]+>/g, '');

    if( isEmpty(fields.title) || fields.title.length < 20 ) {
      enqueueSnackbar("Page title is required and must be greater than 20 chars",{variant:"error"})
      return;
    }

    if( isEmpty(fields.slug)  ) {
      enqueueSnackbar("URL slug is required",{variant:"error"})
      return
    }


    if( isEmpty(fields.authorName)) {
      enqueueSnackbar("Page author is required",{variant:"error"})
      return;
    }

    if( isEmpty(stripedHtml.trim()) || stripedHtml.trim().length < 200 ) {
      enqueueSnackbar("content is required and must be greater than 200 chars",{variant:"error"})
      return
    }
   
    if( isEmpty(image) ) {
      enqueueSnackbar("Image is required, please upload it!",{variant:"error"})
      return;
    }
    
    setLoading(true)
   
    if( data ) {
      params2.images = [image._id]
      props.updatePage(data._id, params2)
        .then(res=>{  
            setLoading(true)
            enqueueSnackbar(res?.message?res.message:"The page is updated",{variant:"success"})
            setTimeout(() => {
              history.push('/pages')
            }, 1000);
        }).catch(err=>{
          enqueueSnackbar(err?.response?err.response.data.message:"Error add new page",{variant:"error"})
        }).finally(_=>{
          setLoading(false)
        })

    } else {
      params2.images = [image._id]
      props.addNewPage(params2)
        .then(res=>{  
          enqueueSnackbar(res?.message?res.message:"New page is published",{variant:"success"})
        }).catch(err=>{
          enqueueSnackbar(err?.response?err.response.data.message:"Error add new page",{variant:"error"})
        }).finally(_=>{
          setLoading(false)
        })
    }
  }

  const onRemove = () => {
    setImage(null)
  }

  const onEditRemove = () => {
    setEditImage(null)
  }

  const onUpdatedEditor = (editState) => {
    setEditorState(editState)
  }

  const onClose = () => {
    setModal(false)
  }


  const mediaBlockRenderer = (block) => {
    if (block.getType() === 'atomic') {
      return {
        component: Media,
        editable: true
      };
    }
  }

  const onOpenFeatured = () => {
    setFeatured(true)
  }

  const onCloseFeatured = () => {
    setFeatured(false)
  }

  return (<Grid container direaction={"row"} justify="space-between" 
          alignItems={"space-between"} spacing={3}>
          {fab && <Fab className={classes.fab} onClick={()=>onSave()} color="primary" aria-label="add">
            <SaveIcon color={"white"} style={{color:"white"}} />
          </Fab>}
          <MediaGallery 
              title={"Pick One"}
              expanded={featured} 
              doCollapse={onCloseFeatured} 
              pushItem={(item)=>{
                  setImage(item)
                  onCloseFeatured()
              }}
          />
         
          <Grid item sm={6}>
              <Typography variant="h5" gutterBottom component="h5">{heading}</Typography>
              <Button onClick={()=>history.push('/pages')} variant="outlined" color="primary">Back</Button>
          </Grid>
          <Grid item sm={6} style={{textAlign:'right'}}>
              <Button color="primary" 
                  disabled={loading} 
                  disableElevation size="large" 
                  variant="contained" onClick={()=>onSave()}> 
                    {loading?'Saving...' : 'Save'} 
              </Button>
          </Grid>
          <Grid item xs={12} md={8} lg={12}>
              <Paper elevation={3} className={classes.paper}>
                  <Grid container spacing={3}>
                      <Grid item sm={12}>
                        <TextField
                          required
                          id="title"
                          fullWidth
                          name="title"
                          label="Title"
                          variant="outlined"
                          onChange={handleChange}
                          value={fields.title}
                          inputProps={{
                            maxlength:70
                          }}
                          placeholder="Enter a page title (Max. 70 Chars)"
                        />
                      </Grid>

                      <Grid item sm={12}>
                        <TextField
                          required
                          id="slug"
                          fullWidth
                          name="slug"
                          label="URL Slug"
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={fields.slug}
                          inputProps={{
                            maxlength:70
                          }}
                        />
                        {fields.slug && <Typography variant="caption" style={{fontSize:13,paddingTop:5,paddingBottom:5,paddingLeft:10,paddingRight:10,background:"#eaeaea"}} gutterBottom>
                          {process.env.REACT_APP_HOST_URL}page/{fields.slug}
                        </Typography>}
                      </Grid>
                      
                      <Grid item sm={12}>
                        <TextField
                          required
                          id="authorName"
                          fullWidth
                          name="authorName"
                          label="Author"
                          variant="outlined"
                          onChange={handleChange}
                          value={fields.authorName}
                          placeholder="Enter author name"
                        />
                      </Grid>

                      <Grid item sm={12}>
                        <FormControlLabel
                          control={<Switch color="primary" checked={fields.isFeatured} onChange={()=>{
                            setFields({
                              ...fields,
                              isFeatured:!fields.isFeatured
                            })
                          }} name="isFeatured" />}
                          label="Featured"
                        />
                      </Grid>
                  </Grid>
              </Paper>

              <Paper elevation={3} className={classes.paper}>
                  <PaperTitle title="Content" desc="Make it content blog, support HTML tag" />
                  {preload && <Editor initialEditorState={data?.content} onFinishChange={onUpdatedEditor} />}
              </Paper>

              <Paper elevation={3} className={classes.paper}>
                <PaperTitle title={"Image & SEO Meta"} desc={"Upload image & Custom SEO Meta"} />
                <Grid container spacing={3} style={{marginTop:10}}>
                    <Grid item sm={4} style={{textAlign:"center"}}>
                      {editImage ? <>
                        <Card style={{marginBottom:20}} onClick={()=>setFeatured(true)}>
                              <CardActionArea>
                                  <CardMedia
                                      style={{height:280}}
                                      image={editImage.url}
                                      title={editImage.title}
                                  />
                              </CardActionArea>
                          </Card>
                          <Button onClick={onRemove} color="primary" variant="outlined">Remove</Button>
                          <Button onClick={()=>setFeatured(true)} style={{marginLeft:10}}  color="primary" variant="contained">Change Photo</Button>
                        </>  : <>{
                        image ? <>
                          
                          <Card style={{marginBottom:20}} onClick={()=>setFeatured(true)}>
                              <CardActionArea>
                                  <CardMedia
                                      style={{height:280}}
                                      image={image.url}
                                      title={image.title}
                                  />
                              </CardActionArea>
                          </Card>
                          <Button onClick={onRemove} color="primary" variant="outlined">Remove</Button>
                          <Button onClick={()=>setFeatured(true)} style={{marginLeft:10}}  color="primary" variant="contained">Change Photo</Button>
                        </> : 
                        <div className={classes.dropzone} onClick={onOpenFeatured}>
                            <p>Click here to Select Image</p>
                        </div>}
                        </>}
                    </Grid>

                    <Grid item sm={8}>
                      <TextField
                        id="seo_title"
                        fullWidth
                        name="seo_title"
                        label="SEO Title"
                        variant="outlined"
                        inputProps={{maxlength:70}}
                        helperText={<div>
                          <p style={{textAlign:"right"}}>{`${fields.seo_title.length} / 70`}</p>
                        </div>}
                        onChange={handleChange}
                        value={fields.seo_title}
                        placeholder="Custom SEO Title"
                      />
                      
                      <TextField
                        id="seo_desc"
                        rows={4}
                        multiline
                        fullWidth
                        inputProps={{maxlength:140}}
                        name="seo_desc"
                        label="SEO Description"
                        variant="outlined"
                        onChange={handleChange}
                        value={fields.seo_desc}
                        helperText={<div>
                          <p style={{textAlign:"right"}}>{`${fields.seo_desc.length} / 140`}</p>
                        </div>}
                        placeholder="Custom SEO Description (max. 140 chars)"
                      />

                      <TextField
                        id="seo_keyword"
                        fullWidth
                        name="seo_keyword"
                        label="SEO Keyword"
                        variant="outlined"
                        margin="normal"
                        onChange={handleChange}
                        value={fields.seo_keyword}
                        placeholder="Keyword separate with comma (,)"
                      />
                    </Grid>
                </Grid>
              </Paper>
          </Grid>
      </Grid>
  );
}


AddEditPages.propTypes = {
    auth: PropTypes.object.isRequired
};
  
const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { addBlog, updateBlog, uploadImages, addNewPage, updatePage })(AddEditPages)