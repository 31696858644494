    import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from "react-router-dom"
import { connect } from "react-redux"
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import StarIcon from '@material-ui/icons/Star'
import CancelIcon from "@material-ui/icons/CancelOutlined"
import EditIcon from "@material-ui/icons/EditOutlined"
import { isEmpty, map, has, reverse } from "lodash"
import { addCategory, updateCategory } from "../actions/blog"

const useStyles = makeStyles((theme) => ({ 
  wrapperList:{
    height:200,
    overflowY:"auto",
  },
  list: {
    width: '100%',
    maxWidth: 360,
    paddingTop:0,
    paddingBottom:0,
    marginBottom:0,
    backgroundColor: theme.palette.background.paper,
  },
  listItem:{
    paddingTop:4,
    paddingBottom:4,
    borderBottom:'1px solid #eee',
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
 },
  // modal styling
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backdrop:{
      backgroundColor:'rgba(0, 0, 0, 0.86)'
  }
}));

const Category = (props) => {
  const classes = useStyles();
  const history = useHistory()
  const [state, setState] = useState({
      loading:false,
      editId:null,
      name:''
  })

  const onClose = (ev) => {
    props.onClose()
  }

  const onPress = (ev, item) => {
    //props.close(ev)
  }

  const onEdit = (item) => {
    setState({...state,editId:item._id, name:item.name})
  }

  const onUpdate = (ev) => {
    setState({loading:true,...state})
    props.updateCategory(state.editId, {name:state.name})
        .then(res=>{
            props.refetch()
        })
        .finally(()=>{
            setState({
                ...state,
                loading:false,
                editId:null,
                name:''
            })
        })
  }

  const addNew = (ev) => {
    setState({loading:true,...state})
    props.addCategory({name:state.name})
        .then(res=>{
            props.refetch()
        })
        .finally(()=>{
            setState({
                ...state,
                loading:false,
                name:''
            })
        })
    }

  return (
    <Paper elevation={3} className={classes.modalPaper} style={{outline:'none', position:"relative", paddingTop:42, width:400}}>
        
        <div style={{position:"absolute", textAlign:"right", top:0, right:0}}>
            <IconButton onClick={props.onClose}>
                <CancelIcon />
            </IconButton>
        </div>
        <Typography gutterBottom style={{marginBottom:30}} variant="h5" component={"h5"}>
            Kategori
        </Typography>
        <Grid container direction={"row"} justify="center" alignItems="center" spacing={2}>
            <Grid item xs={12} lg={12} sm={12}>
                <Grid container direction={"row"} justify="center" alignItems="center" spacing={2}>
                    <Grid item sm={8}>
                        <TextField
                            name="saved"
                            id="saved"
                            label={"Nama"}
                            style={{background:'#fff'}}
                            placeholder="Beri Nama Kategori"
                            variant="outlined"
                            fullWidth={true}
                            value={state.name}
                            onChange={(ev)=>setState({...state,name:ev.target.value})}
                        />
                    </Grid>
                    
                    <Grid item sm={4}>
                        <Button size={'large'} 
                            disableRipple={true} 
                            color="primary" 
                            disableElevation
                            disabled={state.name == '' || state.loading}
                            fullWidth={true}
                            variant="contained"
                            style={{marginTop:20,marginBottom:20,marginLeft:0}} 
                            onClick={(e)=>state.editId?onUpdate(e):addNew(e)} 
                            aria-label="Save">
                                Simpan
                        </Button>
                    </Grid>
                </Grid>
                
            </Grid>
            <Grid  item xs={12} lg={12} sm={12}>
                <div className={classes.wrapperList}>
                    {!isEmpty(props.data) ? props.data.map((item)=><List key={item._id} component="nav" className={classes.list} aria-label="contacts">
                        <ListItem button className={classes.listItem} 
                            onClick={(ev)=>onPress(ev,item)}>
                            <ListItemText primary={item.name} />
                            <ListItemIcon onClick={(ev)=>onEdit(item)}>
                                <EditIcon />
                            </ListItemIcon>
                        </ListItem>
                    </List>) : <Typography style={{textAlign:"center"}} variant="subtitle1">
                        No data
                    </Typography>}
                </div>
            </Grid>
        </Grid>
        
    </Paper>
    
  );
}

const mapStateToProps = (state) => {
    return {
        auth:state.auth,
    }
}

Category.defaultProps = {
    saved: null,
    onCleanSavedSearch:null
};
  
export default connect(
    mapStateToProps,
    { addCategory, updateCategory }
  )(Category)
