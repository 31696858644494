import React, {useState,useEffect} from 'react';
import { connect } from "react-redux";
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { DataGrid } from '@material-ui/data-grid';
import Backdrop from '@material-ui/core/Backdrop'
import Modal from '@material-ui/core/Modal'
import Fade from '@material-ui/core/Fade'
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from "@material-ui/icons/Delete"
import EditIcon from "@material-ui/icons/Edit"
import MarkIcon from "@material-ui/icons/Adjust"
import moment from 'moment';
import { isEmpty, orderBy, forEach } from "lodash"
import CreateCoupon from "./CreateCoupon"
import { listPackages, listCoupons } from "../../actions/package"
import { translate, currency, dateTime } from "../../utils/helpers"
import NoRows from "../../components/NoRows"
import { Chip } from '@material-ui/core';
import { STATUSES } from "../../actions/types"
import { useSnackbar } from "notistack"

const useStyles = makeStyles((theme) => ({
  root:{
    "&>div":{
      height:"300px!important"
    }
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    textAlign:"center"
  },
  divider:{
    margin:"15px 0px"
  },
  fixedHeight: {
    height: 240,
  },
  // modal styling
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  backdrop:{
    backgroundColor:'rgba(0, 0, 0, 0.86)'
  },
  listItem:{
    paddingLeft:0
  },
  listItemIcon:{
    minWidth:36
  },
  textField:{},
  margin:{}
}));

const PackageCoupon = (props) => {
  const classes = useStyles();
  const history = useHistory()
  const [tables, setTables] = useState([])
  const dispatch = useDispatch()
  const [fetched, setFetched] = useState(false)
  const [modal, setModal] = useState(false)
  const [item, setItem] = useState({})
  const { coupons } = useSelector( ({packages}) => packages )
  const { status, message } = useSelector(({notification}) => notification)
  const { enqueueSnackbar } = useSnackbar()
  useEffect(() => {
    if( status == STATUSES.SUCCESS ) {
      fetchData()
    }
    if( status == STATUSES.FAILED ) {
      enqueueSnackbar(message, {variant:"warning"})
    }
  }, [status]);

  const columns = [
    { 
        field: 'edit', 
        headerName: '#', 
        width: 110,
        renderCell:({row}) => {
            return <>
              {row.isDefault ? <MarkIcon title="Default" color="primary" /> : null}
              <IconButton onClick={()=>onModalEdit(row)}>
                  <EditIcon  />
              </IconButton>
            </>
        }
    },
    { 
        field: 'code', 
        headerName: 'Coupon', 
        width: 200,
        renderCell:({row}) => {
            return <div>
              {row.code}
              {row.status===false&& <Chip size="small" label={"Inactive"} />}
          </div>
        }
    },
    { 
        field:"terms",
        headerName: 'Terms', 
        width: 400,
        renderCell:({row}) => {
            if( row.discountType == 'fixed' ) {
                let a = "Disc. " + currency.digit(currency.parse(row.discountAmount))
                if( row.type == 'general' ) {
                    a+=" - ALL"
                } else if( row.type == 'specific') {
                    a+=` - ${row.packageId.length} Packages`
                }
                return a
            } else if ( row.discountType == 'percentage' ) {
                let b = "Disc. " + row.discountAmount + "%Off"
                if( row.type == 'general' ) {
                    b+=" - ALL"
                } else if( row.type == 'specific') {
                    b+=` - ${row.packageId.length} Packages`
                }

                return b
            }
        }
    },
    { 
        field: 'dateValidation',
        headerName: 'Date validation', 
        width: 250,
        renderCell:({row}) => {
          if( row.startDate && row.endDate ) {
            return dateTime.displayHumanDate(row.startDate) + " - " + dateTime.displayHumanDate(row.endDate)
          } else {
              return "-"
          }
        }
    },
    {
        field: 'createdAt', 
        headerName: 'Creation Date', 
        width: 180,
        valueFormatter: ({value}) => moment(value).format("ll"),
    }
  ];

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if( ! isEmpty(coupons) ) {
      let newList = orderBy(coupons, ['updatedAt'],['desc']);
      setTables(newList.map((item)=>({...item,id:item._id})))
      setFetched(true)
    } 
  }, [coupons])
  
 
  const fetchData = () => {
    dispatch(listCoupons({}))
  }

  const iterateConfig = (pcks) => {
    let items = []
    forEach(pcks, (value, field) => {
      items.push({key:field,value:value})
    })
    return items
  }

  const onModalEdit = (item) => {
    history.push(`/coupons/${item._id}`, {item:item})
  }

  const onModalClose = () => {
    setModal(false)
  }

  const onAdd = () => {
    history.push(`/coupons`)
  }

  const onRemove = () => {
    setModal(false)
    fetchData()
  }

  const onPageChange = () => {

  }

  return (<div className={classes.root}>
        <Button color="primary" style={{marginBottom:30}} variant="contained" onClick={()=>onAdd()} size="medium">Add Coupon</Button>
        <DataGrid style={{
            height:'300px!important',
        }} pageSize={40} onPageChange={onPageChange} components={{
          NoRowsOverlay: NoRows,
        }} autoHeight={true} rows={tables} columns={columns} />
    </div>
  )
}

PackageCoupon.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  common: state.common
});

export default connect(mapStateToProps, { })(PackageCoupon)