import {isEmpty, has, isArray, forEach } from "lodash";
import { callGet, callPost, callPut, callDelete, makeRequestDispatch, singleRequestDispatch } from "../api"
import { PACKAGES_LIST, UPDATE_PACKAGE, REMOVE_PACKAGE, ADD_PACKAGE, COUPON_LIST, ADD_COUPON, UPDATE_COUPON, REMOVE_COUPON } from "./types";

// List Package
export const listPackages = (params) => dispatch => {
  return singleRequestDispatch(
    callGet('/admin/package', params ),
    dispatch,
    PACKAGES_LIST
  )
}

// Add new Package
export const addPackage = (bodyParams) => dispatch => {
  return makeRequestDispatch(
    callPost('/admin/package', bodyParams),
    dispatch,
    ADD_PACKAGE
  )
}

// Update Package
export const updatePackage = (id, bodyParams) => dispatch => {
  return makeRequestDispatch(
    callPut('/admin/package/'+id, bodyParams),
    dispatch,
    UPDATE_PACKAGE
  )
}

// Delete package
export const removePackage = (id) => dispatch => {
  return singleRequestDispatch(
    callDelete('/admin/package/'+id),
    dispatch,
    REMOVE_PACKAGE
  )
}

// List Campaign 
export const listCoupons = (params) => dispatch => {
  return singleRequestDispatch(
    callGet('/admin/campaign', params ),
    dispatch,
    COUPON_LIST
  )
}

// Add new Coupon
export const addCoupon = (bodyParams) => dispatch => {
  return makeRequestDispatch(
    callPost('/admin/campaign', bodyParams),
    dispatch,
    ADD_COUPON
  )
}

// Add new Coupon
export const updateCoupon = (id, bodyParams) => dispatch => {
  return makeRequestDispatch(
    callPut('/admin/campaign/'+ id, bodyParams),
    dispatch,
    UPDATE_COUPON
  )
}

// Delete coupon
export const removeCoupon = (id) => dispatch => {
  return singleRequestDispatch(
    callDelete('/admin/campaign/'+id),
    dispatch,
    REMOVE_COUPON
  )
}
