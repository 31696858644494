
import React, {useState,useEffect,useCallback} from 'react'
import { connect } from "react-redux"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from "react-router-dom"
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import { isEmpty, isNumber, isNil, findIndex } from "lodash"
import { callPublicGet, callPost, callPut, callDelete } from "../api"
import Paper from '@material-ui/core/Paper';
import Backdrop from '@material-ui/core/Backdrop'
import Modal from '@material-ui/core/Modal'
import Fade from '@material-ui/core/Fade'
import BackIcon from '@material-ui/icons/ChevronLeft'
import DeleteIcon from '@material-ui/icons/Delete'
import TrashIcon from '@material-ui/icons/Cancel'
import AddIcon from '@material-ui/icons/Add'
import Accordion from '@material-ui/core/Accordion'
import GoIcon from "@material-ui/icons/ChevronRight"
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert'
import { listVarious, addVarious, updateVarious, removeVarious } from "../actions/various"
import { addCollection, updateCollection, removeCollection } from "../actions/collection"
import { STATUSES } from "../actions/types" 
import { Chip } from '@material-ui/core'
import { currency } from "../utils/helpers"
import { useSnackbar } from 'notistack';


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
   
    flexDirection: 'column',
    marginBottom: theme.spacing(3)
  },
  fixedHeight: {
    
  },
  // modal styling
  modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
  },
  modalPaper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
  },
  backdrop:{
      backgroundColor:'rgba(0, 0, 0, 0.86)'
  }
}));


const Collection = (props) => {
    const itemMaster = {
      _id:0,
      title:"",
      slug:"",
      units:[]
    }
    const [items, setItems] = useState([])
    const [error, setError] = useState("")
    const [modalDelete, setModalDelete] = useState(null)
    const [loading, setLoading] = useState(false)
    const { enqueueSnackbar } = useSnackbar()
    const history = useHistory()
    const classes = useStyles()
    const dispatch = useDispatch()
    const { status } = useSelector( ({notification}) => notification )
    const params = {
      limit:100,
      page:0,
      size:"original"
    }

    useEffect(()=>{
      fetchData()
    },[])

    useEffect(()=>{
      if( status == STATUSES.SUCCESS ) {
        //onBack()
      }
    },[status])

    const fetchData = () => {
      callPublicGet("/collections",params)
        .then(res=>{
          setItems(res.data)
        })
        .catch(err=>{

        })
    }

    const onNewCard = () => {
      let newItem = []

      let lastIndex = items.length-1
      if( lastIndex > -1 ) {
        if( isNumber(items[lastIndex]._id) ) {
          setError("Please save last item before add new item!")
          return
        }
      }

      itemMaster._id = (items.length-1) + 1
      itemMaster.sequence = (items.length-1) + 1
      newItem.push(itemMaster)
      setItems(prev=>[...prev, ...newItem])
    }

    const onRemove = () => {
      setLoading(true)
      let title = modalDelete?.title
      callDelete(`admin/collections/${modalDelete._id}`)
        .then(res=>{
          enqueueSnackbar(`Great! collection ${title} has been removed`, {variant:"success"})
          fetchData()
        })
        .catch(err=>{
          enqueueSnackbar("Error deleting collection", {variant:"error"})
        })
        .finally(()=>{
          setModalDelete(null)
          setLoading(false)
        })
      //dispatch(removeVarious(id))
    }

    const onSaveAdd = (id, payload) => {
      let ad = items 
      ad[id] = {...ad[id], ...payload}
      setItems(prev=>[...ad])
    }

    const onModalDelete = (item) => {
      setModalDelete(item)
    }

    const onModalClose = () => {
      setModalDelete(null)   
    }

    let reItems = items

    return(<Grid 
            container 
            direction="row" 
            justify="flex-start" 
            alignItems={"flex-start"}
          >
            
          <Modal 
              className={classes.modal}
              open={modalDelete}
              onClose={onModalClose}
              disableBackdropClick={false}
              BackdropComponent={Backdrop}
              BackdropProps={{
                  timeout: 500,
                  classes:{
                      root:classes.backdrop
                  }
              }}
              disableScrollLock={false}
          >
              <Fade in={modalDelete}>
                  <Paper elevation={3} style={{padding:50}} className={classes.paper}>
                      <Typography style={{marginBottom:10}} variant="h5">Are you sure remove this item?</Typography>
                      {modalDelete && <Typography style={{marginBottom:40}} variant="subtitle1">{modalDelete.title}</Typography>}
                      <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                          <Button disabled={loading} onClick={onRemove} variant="contained" color="primary">
                              {loading ? 'Removing...' : 'Yes, Remove'}
                          </Button>
                          <Button style={{marginLeft:10}} disabled={loading} onClick={onModalClose} variant="outlined" color="primary">
                              Close
                          </Button>
                      </Grid>
                      
                  </Paper>
              </Fade>
          </Modal>
          
        <Grid item xs={12} lg={12}>
          <Grid 
            container 
            direction="row" 
            justify="flex-start" 
            alignItems={"flex-start"}
          >
            <Grid item xs={10} lg={12}>
                <div style={{marginTop:20,display:"flex",flexDirection:"row"}}>
                    <Button size="small" startIcon={<BackIcon />} variant="outlined" onClick={()=>history.goBack()}>
                        Kembali
                    </Button>
                    <Button onClick={onNewCard} style={{marginLeft:20}} startIcon={<AddIcon />} disableElevation variant="contained" size="small" color="primary">
                        New
                    </Button>
                </div>

                {error !== "" && <Alert severity='error' fullWidth={true}>{error}</Alert>}

                <div style={{marginTop:20}}>
                    {!isEmpty(reItems) && <div>
                        {reItems.map((item,index)=><Grid key={index} container 
                          direction="row" 
                          style={{marginBottom:10}}
                          justify="flex-start" 
                          alignItems={"flex-start"}>
                            <Grid item xs={9} lg={9}>
                              <SectionCard 
                                item={item}
                                index={index} 
                                onSave={onSaveAdd} 
                              />
                            </Grid>
                            <Grid item xs={1} lg={1} style={{alignItems:"flex-start", display:"flex"}}>
                              <IconButton onClick={()=>onModalDelete(item)} size="large" color="default" variant="outlined">
                                  <DeleteIcon />
                              </IconButton>
                            </Grid>
                        </Grid>)}
                    </div>}
                </div>
            </Grid>
            
          </Grid>
        </Grid>
    </Grid>)
}


const SectionCard = (props) => {
    const classes = useStyles();
    const baseUrl = process.env.REACT_APP_ENV === "development" ? "http://localhost:3000/collection/": process.env.REACT_APP_ENV === "staging" ? "https://huni.io/collection/" : "https://www.huni.id/collection/"
    const [update, setUpdate] = useState(false)
    const [title, setTitle] = useState("")
    const [collectionId, setCollectionId] = useState()
    const [metaSeo, setMetaSeo] = useState({
      title:"",
      desc:"",
      keywords:""
    })
    const [collectionSlug, setCollectionSlug] = useState("")
    const [expanded, setExpanded] = useState(false)
    const [url, setUrl] = useState("")
    const { enqueueSnackbar } = useSnackbar()
    const [units, setUnits] = useState([])
    const dispatch = useDispatch()
    const { status, message } = useSelector( ({notification}) => notification )
  
    useEffect(()=>{
      if( props.item ) {
        setTitle(props.item?.title)
        setCollectionId(props.item?._id)
        setCollectionSlug(props.item?.slug)
        setUrl(props.item?.slug)
        setUnits(props.item?.units)
        if( props.item?.payloads ) {
          setMetaSeo({
            title:props.item.payloads?.title,
            desc:props.item.payloads?.desc,
            keywords:props.item.payloads?.keywords
          })
        }
        if( isNumber(props.item?._id) ) {
          setExpanded(true)
        }
      }
    },[props.item])
  
    useEffect(()=>{
      if( status == STATUSES.SUCCESS ) {
         if( status && message && message._id && update ) {
           //
         }
      }
      return () => {
        setUpdate(false)
      }
    },[status])
  
    const onSaveCollections = () => {
      if( isNumber(props.item._id) ) {
        callPost(`/admin/collections`, {
          title:title,
          units:units,
          payloads:metaSeo
        }).then(res=>{
          let url = `/collections/${res.data?.slug}`
          setCollectionId(res.data._id)
          enqueueSnackbar("New collections is added succesfully", {variant:"success"})
          props.onSave && props.onSave(props.index, {
            _id:res.data._id,
            title:res.data.title,
            slug:res.data.slug,
            payloads:metaSeo,
            units:units
          })
        }).catch(err=>{
          //setUnits((prev)=>[...items])
        })
        
      } else {
        callPut(`/admin/collections/${collectionId}`, {
          title:title,
          units:units,
          payloads:metaSeo
        }).then(res=>{
          enqueueSnackbar("The collection is updated succesfully", {variant:"success"})
          props.onSave && props.onSave(props.index, {
            title:res.data.title,
            slug:res.data.slug,
            payloads:metaSeo,
            units:units
          })
        }).catch(err=>{
          //setUnits((prev)=>[...items])
        })
      }
    }
  
    
    const onUpdateUnits = (items) => {
      setUnits(items)
    }
  
    const disableSave = () => {
      if( title === "" || isEmpty(units) ) return true
      return false
    }
  
    return (<>
        <Accordion expanded={expanded} onChange={(e,expand)=>setExpanded(expand)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div style={{flexDirection:"column"}}>
              <Typography variant='h6' color={title!==""?"inherit":"textSecondary"}>
                {title!==""?title:"New Card"}
              </Typography>
              {collectionSlug && collectionSlug !== "" && <Typography variant='p' color={"textSecondary"}>{baseUrl}{collectionSlug}/{collectionId} <IconButton size="small" onClick={()=>window.open(`${baseUrl}${collectionSlug}/${collectionId}`, "_blank")}>
                <GoIcon />  
              </IconButton>  </Typography>}
            </div>
          </AccordionSummary>
          <AccordionDetails style={{flexDirection:"column"}}>
              <Grid container direction='row' spacing={2}>
                <Grid item xs={12}>
                    <TextField 
                        fullWidth={true} style={{marginBottom:10}} 
                        id="collection-title" 
                        onChange={(e)=>setTitle(e.target.value)} 
                        label="Collection title"
                        placeholder='Enter Collection title'
                        value={title} 
                    />
                    <TextField 
                        fullWidth={true} style={{marginBottom:10}} 
                        id="collection-slug" 
                        disabled
                        label="URL Slug"
                        value={`${baseUrl}${collectionSlug}/${collectionId}`} 
                    />

                    <TextField 
                        fullWidth={true} style={{marginBottom:10}} 
                        id="seo-title" 
                        onChange={(e)=>setMetaSeo({...metaSeo,title:e.target.value})} 
                        label="SEO title"
                        inputProps={{maxLength:70}}
                        placeholder='Enter SEO title (Max 70 chars)'
                        value={metaSeo.title} 
                    />

                    <TextField 
                        fullWidth={true} style={{marginBottom:10}} 
                        id="seo-desc" 
                        onChange={(e)=>setMetaSeo({...metaSeo,desc:e.target.value})} 
                        label="SEO Desc"
                        inputProps={{maxLength:200}}
                        placeholder='Enter SEO Description'
                        value={metaSeo.desc} 
                    />

                    <TextField 
                        fullWidth={true} style={{marginBottom:10}} 
                        id="seo-keywords" 
                        onChange={(e)=>setMetaSeo({...metaSeo,keywords:e.target.value})} 
                        label="SEO Keywords"
                        inputProps={{maxLength:200}}
                        placeholder='Enter SEO Keyword (separate with comma)'
                        value={metaSeo.keywords} 
                    />

                    <CollectionUnits id={collectionId} onUpdate={onUpdateUnits}/>
                </Grid>
              </Grid>
              <Grid container direction='row' style={{marginTop:20,marginBottom:20}} justify='flex-end' alignItems='flex-end'>
                <Grid item xs={2}>
                  <Button onClick={()=>onSaveCollections()} disableElevation color="primary" disabled={disableSave()} style={{width:200,alignSelf:"flex-end"}} variant="contained">Save</Button>
                </Grid>
              </Grid>
            
          </AccordionDetails>
        </Accordion>
      </>);
  }

  const CollectionUnits = (props) => {
    const [units, setUnits] = useState([])
    const [isAdd, setIsAdd] = useState(false)
    const [init, setInit] = useState(false)

    useEffect(()=>{
      console.log("isNumber(props.id)", props.id)
      if( props.id && ! isNumber(props.id) ) {
        setInit(true)
        callPublicGet(`/collections/${props.id}`)
          .then(res=>{
            let formatUnits = res.data.units.map(it=>({
                id:it,
                data:null,
                required:false,
                notFound:false
              })
            )
            setUnits(formatUnits)
            return formatUnits
          })
          .then(res=>{
            let calls = []
            res.map(data=>{
              if( !data.data ) {
                calls.push(callPublicGet(`/unit/${data.id}`))
              }
            })
            Promise.all(calls).then(values=>{
              let newData = res
              values.map(value=>{
                let idnx = findIndex(newData, (it)=>it.id===value.data?._id )
                if( idnx > -1 ) {
                  newData[idnx].data = value.data
                }
              })
              setUnits(newData)
              setInit(false)
            })
          })
          .catch(err=>{
            setInit(false)
          })
      } else {

      }
    },[props.id])
  
    useEffect(()=>{
      if( isAdd ) {
        setUnits((prev)=>[...prev, {id:"",data:null,required:false,notFound:false}])
      }
      return () => {
        setIsAdd(false)
      }
    },[isAdd])
  
    const onAdd = () => {
      let items = units
      let lastIndex = items.length-1
      if( lastIndex > -1 ) {
        if (items[lastIndex].id === "") {
          items[lastIndex].required = true
          setUnits(()=>[...items])
          return
        } 
  
        if ( !units[lastIndex].data ) {
          items[lastIndex].notFound = true
          setUnits(()=>[...items])
          return
        } 
      } 
      setIsAdd(true)
    }
  
    const handleChange = (e, index) => {
      let items = units
      const value  = e.target.value
      items[index] = {id:e.target.value, data:null, required:value.length===0?true:false}
      setUnits((prev)=>[...items])
    }
  
    const handleBlur = (e, index) => {
      let items = units
      const id = items[index].id
      callPublicGet(`/unit/${id}`)
          .then(res=>{
            items[index].data = res?.data
            items[index]['required'] = false
            items[index]['notFound'] = false
            props.onUpdate && props.onUpdate(items.map(it=>it.id))
            setUnits((prev)=>[...items])
          }).catch(err=>{
            items[index]['required'] = false
            items[index]['notFound'] = true
            setUnits((prev)=>[...items])
          }).finally(()=>{
  
          })
    }

    const onRemoveUnit = (item) => {
      let newItems = units 
      let index = newItems.findIndex(it=>item._id==it._id)
      if( index > -1 ) {
        newItems.splice(index, 1)
        setUnits((prev)=>[...newItems])
        props.onUpdate && props.onUpdate(newItems)
      }
    }
  
    return (<div>
        <Button varian="default" size="small" disabled={init} startIcon={ <AddIcon />} onClick={onAdd} color="inherit">Add Unit</Button>
        <Grid container direction='row' style={{marginTop:10}} spacing={2}>
        {init ? <CircularProgress /> : units.map((item,index)=><Grid item xs={6}>
            <TextField 
              fullWidth={true} 
              style={{marginBottom:10}} 
              id="unit-id" 
              placeholder="Enter object Unit ID here" 
              label="Unit ID" 
              value={item.id} 
              onBlur={(e)=>handleBlur(e, index)}
              onChange={(e)=>handleChange(e, index)}
              error={item?.required?true:(item?.notFound?true:false)}
              helperText={item?.required?"Enter required field":(item?.notFound?"Unit ID not found":"")}
            />

            {item?.data && <div style={{marginBottom:15, position:"relative"}}>
              <div style={{position:"absolute", top:10,right:0,width:30,height:30}}>
                  <IconButton size='small' onClick={()=>onRemoveUnit(item)}>
                    <TrashIcon />
                  </IconButton>
              </div>
              <Grid container direction='row' spacing={2}>
                {item.data?.thumb && <Grid item>
                    <img style={{width:100}} src={item.data.thumb[0]?.url} />
                </Grid>}
                <Grid item lg={9}>
                    {item.data?.price && <Typography variant="subtitle1">Rp {currency.digit(item.data.price)}</Typography>}
                    {item.data?.address ? <Typography variant="subtitle2" style={{lineHeight:'16px',marginBottom:6}} color="textSecondary">{item.data.address?.street1}</Typography> : <Typography variant="subtitle1">Unknown address</Typography>}
                    <Chip label={item.data.type} variant="primary" size='small' /> <Chip label={item.data.saleType} variant="primary" size='small' />
                </Grid>
              </Grid>  
            </div>}
        </Grid>)}
      </Grid>
    </div>)
  }
  
  
Collection.propTypes = {
auth: PropTypes.object.isRequired,
};
  
const mapStateToProps = state => ({
    auth: state.auth,
    common: state.common
});
  
export default connect(mapStateToProps, { 
    listVarious, addVarious, updateVarious, removeVarious, 
    addCollection, updateCollection, removeCollection 
})(Collection)