import {isEmpty, has, isArray, forEach } from "lodash";
import rest, {restUpload} from "../utils/rest";
import config from "../config";
import { 
  GET_ERRORS, SEARCH_REGIONS, LIST_POPULAR_CITY, REMOVE_POPULAR_CITY, ADD_POPULAR_CITY, UPDATE_POPULAR_CITY, clearAction
} from "./types";

export const revertAction = () => dispatch => {
  dispatch({
    type:clearAction()
  })
}

export const searchRegions = (key) => dispatch => {
    return new Promise((resolve, reject) => {
        rest()
            .get('/region/search', {params:{key:key}})
            .then(res=>{
                const { success, data, meta } = res.data;
                if( success) {
                  dispatch({
                    type:SEARCH_REGIONS,
                    payload:data
                  })
                  resolve(res.data)
                }
            })
            .catch(err=>{
                dispatch({
                    type:GET_ERRORS,
                    payload:err.response
                })
                reject(err.response)
            })
    })
    
}

export const listPopularCity = (params) => dispatch => {
    return new Promise((resolve, reject) => {
        rest()
            .get('/admin/popular', {params:params})
            .then(res=>{
                const { success, data, meta } = res.data;
                if( success) {
                  dispatch({
                    type:LIST_POPULAR_CITY,
                    payload:data
                  })
                  resolve(res.data)
                }
            })
            .catch(err=>{
                dispatch({
                    type:GET_ERRORS,
                    payload:err.response
                })
                reject(err.response)
            })
    })
    
}

// Add popular
export const addPopularCity = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        rest()
            .post('/admin/popular', data)
            .then(res=>{
                const { success, data } = res.data;
                if( success) {
                  dispatch({
                    type:ADD_POPULAR_CITY,
                    payload:data
                  })
                  resolve(res.data)
                }
            })
            .catch(err=>{
                dispatch({
                    type:GET_ERRORS,
                    payload:err.response
                })
                reject(err.response)
            })
    })
    
}


// Update popular
export const updatePopularCity = (id, params) => dispatch => {
  return new Promise((resolve, reject) => {
      rest()
          .put(`/admin/popular/${id}`, params)
          .then(res=>{
              const { success, data } = res.data;
              if( success) {
                dispatch({
                  type:UPDATE_POPULAR_CITY,
                  payload:data
                })
                resolve(res.data)
              }
          })
          .catch(err=>{
              dispatch({
                  type:GET_ERRORS,
                  payload:err.response
              })
              reject(err.response)
          })
  })
}

// Remove
export const removePopular = (id) => dispatch => {
    return new Promise((resolve, reject) => {
      rest()
        .delete("/admin/popular/" + id)
        .then(res => {
            const { success } = res.data;
            if( success ) {            
                // Set token to ls
                dispatch({
                    type:REMOVE_POPULAR_CITY,
                    payload:res.data.data
                });
                resolve(res.data.data);
            } 
            resolve(null);
        })
        .catch(err => {
          dispatch({
            type: GET_ERRORS,
            payload: err.response ? err.response : err
          });
          reject(err);
        })
    });
}
    

