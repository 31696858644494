import React, { useState, useEffect, useRef } from "react"
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import config from "../config"
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'; // ES6

const useStyles = makeStyles((theme) => ({
    root:{
        width: '100%', height: '60vh', position: 'relative',
        [theme.breakpoints.down('sm')]:{
            width:'100%'
        }
    }
}))

const GoogleMapPointer = (props) => {
    const classes = useStyles()
    const onMarkerClick = (props, marker, e) => {

    }
    return (
        <Map 
            google={props.google} 
            zoom={15}
            className={classes.root}
            draggable={true}
            onReady={(mapProps, map)=>props.onMapLoaded(mapProps, map)}
            initialCenter={{
                lat: 1.9598436,
                lng: 110.5286641
            }}
            center={{
                lat: props.lat,
                lng: props.lng
            }}
        >
            <Marker 
                onClick={onMarkerClick}
                draggable={true}
                onDragend={(t, map, coord)=>props.onDragEnd(coord, t)}
                position={{lat: props.lat, lng:props.lng}}
                name={'My location'} />
      </Map>
    );
}

GoogleMapPointer.defaultProps = {
    lat:1.9598436,
    lng:110.5286641
}
GoogleMapPointer.propTypes = {
    lat:PropTypes.number,
    lng:PropTypes.number
}
export default GoogleApiWrapper({
  apiKey:config.gmApiKey
})(GoogleMapPointer)