import React, {useState,useEffect,useCallback} from 'react'
import { connect } from "react-redux"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from "react-router-dom"
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select';
import { isEmpty, isNumber, isNil, findIndex } from "lodash"
import { callPublicGet, callPost, callPut } from "../api"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardMedia from "@material-ui/core/CardMedia"
import Paper from '@material-ui/core/Paper';
import Backdrop from '@material-ui/core/Backdrop'
import Modal from '@material-ui/core/Modal'
import Fade from '@material-ui/core/Fade'

import DeleteIcon from '@material-ui/icons/Delete'
import Up from '@material-ui/icons/ArrowUpwardOutlined'
import Down from '@material-ui/icons/ArrowDownwardOutlined'
import AddIcon from '@material-ui/icons/Add'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert'
import TagRegion from "../components/TagRegion"
import { listVarious, addVarious, updateVarious, removeVarious } from "../actions/various"
import { addCollection, updateCollection, removeCollection } from "../actions/collection"
import { STATUSES } from "../actions/types" 
import remarkGfm from 'remark-gfm'
import predefinedJual from "../data/price-dijual.json"
import predefinedDisewakan from "../data/price-disewakan.json"
import MediaGallery from "../components/MediaGallery"
import { setPageStateUpdate } from '@material-ui/data-grid'
import { Chip } from '@material-ui/core'
import { currency } from "../utils/helpers"
import { normalizeUnits } from 'moment'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    textAlign:"center"
  },
  divider:{
    margin:"15px 0px"
  },
  fixedHeight: {
    height: 240,
  },
  // modal styling
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  backdrop:{
    backgroundColor:'rgba(0, 0, 0, 0.86)'
  },
  listItem:{
    paddingLeft:0
  },
  listItemIcon:{
    minWidth:36
  },
  textField:{},
  margin:{},
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  dropzone:{
    border:"4px dashed #ddd",
    display:"flex",
    height:386,
    marginTop:20,
    padding:15,
    cursor:'pointer',
    textAlign:"center",
    alignItems:"center",
    justifyContent:"center",
    color:"#aaa"
  },
  headingCard:{
    position:"absolute",
    bottom:20,left:20,
    width:280,color:"#fff",
    fontSize:"1.6rem",
    fontWeight:"bold",
  },
  subheadingCard:{
    position:"absolute",
    bottom:50,left:20,
    width:280,color:"#fff",
    fontSize:"1rem",
  }
}));

Array.prototype.next = function() {
  return this[++this.current];
};
Array.prototype.prev = function() {
  return this[--this.current];
};
Array.prototype.current = 0;

const References = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const history = useHistory()
  const [currentIndex, setCurrentIndex] = useState(0)
  const [tables, setTables] = useState([])
  const [fetched, setFetched] = useState(false)
  const { list } = useSelector( ({various}) => various )

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if( list ) {
      setTables(list)
      setFetched(true)
    } 
  }, [list])

  const fetchData = () => {
    dispatch(listVarious({}))
  }

  const onSave = (items,title) => {
    //let ad = tables 
    //ad[currentIndex] = {...item,items:items,name:title,slug:kebabCase(title)}
    //setTables(prev=>[...ad])
    fetchData()
  }

  return (<div>
      <Typography margin={"dense"} variant='h4'>Manage Serba-Serbi</Typography>
       <Grid container style={{paddingTop:0}} direction="row" justify="flex-start" alignItems={"flex-start"}>
            <Grid item sm={12} md={8} lg={12}>
                <Section list={list} current={currentIndex} onSaveSection={onSave} />
            </Grid> 
        </Grid>
    </div>
  )
}

const Section = ({ _id, list, onSaveSection, current, ...rest}) => {
    const itemMaster = {
      _id:0,
      sequence:0,
      type:"region",
      title:"",
      subTitle:"",
      image:[],
      url:"",
      payload:{
        saleType:"all",
        propertyType:"properti"
      }
    }
    const [items, setItems] = useState([])
    const [sectionId, setSectionId] = useState(0)
    const [openGallery, setOpenGallery] = useState(false)
    const [modalDelete, setModalDelete] = useState(null)
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    const classes = useStyles()
    const dispatch = useDispatch()
    const { status } = useSelector( ({notification}) => notification )
  
    useEffect(()=>{
      setItems(list)
    },[list])

    useEffect(()=>{
      if( status == STATUSES.SUCCESS ) {
        //onBack()
      }
    },[status])

    const onToggleGallery = () => {
      setOpenGallery(!openGallery)
    }

    const onNewCard = () => {
      let newItem = []
      itemMaster._id = (items.length-1) + 1
      itemMaster.sequence = (items.length-1) + 1
      newItem.push(itemMaster)
      setItems(prev=>[...prev, ...newItem])
    }

    const onRemove = () => {
      if( !modalDelete ) {
        return
      }
      let id = modalDelete._id
      let heatItems = items
      heatItems.splice(id, 1)
      setItems((prev)=>[...heatItems])
      dispatch(removeVarious(id))
      setTimeout(()=>{
        setModalDelete(null)
      },400)
    }

    const onSaveAdd = (id, payload) => {
      let ad = items 
      ad[id] = payload
      setItems(prev=>[...ad])
    }

    const onUp = (item, index) => {
      if( item.sequence < 1 ) {
        return
      }
      let ad = items 
      ad[index].sequence = item.sequence - 1
      ad[index-1].sequence = item.sequence + 1
      setItems(prev=>[...ad])
    }

    const onDown = (item, index) => {
      let ad = items 
      if( item.sequence >= ad.length-1 ) {
        return
      }
      ad[index].sequence = item.sequence + 1
      ad[index+1].sequence = item.sequence - 1
    
      setItems(prev=>[...ad])
    }

    const onSaveAll = useCallback(() => {
      const reSorts = items.sort((a,b)=>a.sequence-b.sequence)
      Promise.all(reSorts.map(per => dispatch(updateVarious(per._id, {
        ...per,
        images:per?.image?per.image.map(it=>it._id):per.images.map(it=>it._id)
      })))).then(values=>{
        //onBack()
      })
    },[items])

    const onModalDelete = (item) => {
      setModalDelete(item)
    }
  
    const onModalClose = () => {
      setModalDelete(null)   
    }
  
    let reItems = items.sort((a,b)=>a.sequence-b.sequence)

    return(<Grid 
            container 
            direction="row" 
            justify="flex-start" 
            alignItems={"flex-start"}
          >
            
        <Modal 
            className={classes.modal}
            open={modalDelete}
            onClose={onModalClose}
            disableBackdropClick={false}
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
                classes:{
                    root:classes.backdrop
                }
            }}
            disableScrollLock={false}
        >
            <Fade in={modalDelete}>
                <Paper elevation={3} style={{padding:50}} className={classes.paper}>
                    <Typography style={{marginBottom:10}} variant="h5">Are you sure remove this item?</Typography>
                    {modalDelete && <Typography style={{marginBottom:40}} variant="subtitle1">{modalDelete.title}</Typography>}
                    <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                        <Button disabled={loading} onClick={onRemove} variant="contained" color="primary">
                            {loading ? 'Removing...' : 'Yes, Remove'}
                        </Button>
                        <Button style={{marginLeft:10}} disabled={loading} onClick={onModalClose} variant="outlined" color="primary">
                            Close
                        </Button>
                    </Grid>
                    
                </Paper>
            </Fade>
        </Modal>

        <Grid item xs={12} lg={12}>
          <Grid 
            container 
            direction="row" 
            justify="flex-start" 
            alignItems={"flex-start"}
          >
            <Grid item xs={10} lg={12}>
                {sectionId != -1 && <div style={{marginTop:20,display:"flex",flexDirection:"row", justifyContent:"space-between"}}>
                    <div>
                      <IconButton onClick={onNewCard} size="large" color="default">
                          <AddIcon />
                      </IconButton>
                      <Button variant="outlined" size="small" onClick={()=>history.push("/collections")}>
                          Manage Collection
                      </Button>
                    </div>
                    <Button onClick={onSaveAll} disableElevation color="secondary" variant="contained">Save Sequence</Button>
                </div>}

                <div style={{marginTop:20}}>
                    {!isEmpty(reItems) && <div>
                        {reItems.map((item,index)=><Grid key={index} container 
                          direction="row" 
                          style={{marginBottom:10}}
                          justify="flex-start" 
                          alignItems={"flex-start"}>
                            <Grid item xs={11} lg={11}>
                              <SectionCard 
                                item={item}
                                index={index} 
                                onSave={onSaveAdd} 
                              />
                            </Grid>
                            <Grid item xs={1} lg={1} style={{alignItems:"flex-start", display:"flex"}}>
                              <IconButton onClick={()=>onModalDelete(item)} size="large" color="default" variant="outlined">
                                  <DeleteIcon />
                              </IconButton>
                              {index > 0 && <IconButton onClick={()=>onUp(item, index)} size="large" color="default" variant="outlined">
                                  <Up />
                              </IconButton>}
                              {(index < items.length-1) && <IconButton onClick={()=>onDown(item, index)} size="large" color="default" variant="outlined">
                                  <Down />
                              </IconButton>}
                            </Grid>
                        </Grid>)}
                    </div>}
                </div>
            </Grid>
            
          </Grid>
        </Grid>
    </Grid>)
}

const SectionCard = (props) => {
  const prefixUrl = "/nearby/properti-all/"
  const classes = useStyles();
  const [type, setType] = useState("region")
  const [update, setUpdate] = useState(false)
  const [price, setPrice] = useState("")
  const [selectedPrice, setSelectedPrice] = useState()
  const [saleType, setSaleType] = useState("all")
  const [propertyType, setPropertyType] = useState("all")
  const [title, setTitle] = useState("")
  const [subtitle, setSubtitle] = useState("")
  const [collectionId, setCollectionId] = useState()
  const [collectionSlug, setCollectionSlug] = useState("")
  const [collections, setCollections] = useState([])
  const [collection, setCollection] = useState()
  const [expanded, setExpanded] = useState(true)
  const [url, setUrl] = useState("")
  const [urlRegion, setUrlRegion] = useState(prefixUrl)
  const [selectedRegions, setSelectedRegions] = useState([])
  const [units, setUnits] = useState([])
  const [toggleGallery, setToggleGallery] = useState(false)
  const [limit, setLimit] = useState(20)
  const [image, setImage] = useState()
  const dispatch = useDispatch()
  const { status, message } = useSelector( ({notification}) => notification )

  useEffect(()=>{
    const item = props.item
    if( props.item ) {
      const typeCard = item.type??item.payload?.type??""
      let data = {}
      setType(typeCard)
      setTitle(item.title)
      setSubtitle(item?.subTitle)
      setUrl(item.url)
      setImage(item.images??item.image)
      if( typeCard === "region" && ! isEmpty(item.payload) ) {
        setSaleType(item.payload?.saleType)
        setSelectedPrice(item.payload?.price)
        setPropertyType(item.payload?.propertyType)
        setSelectedRegions(item.payload?.regions)
        setUrlRegion(item?.url)
      } else if(typeCard==="url") {
        setUrl(item?.url)
      } else if(typeCard==="collections") {
        setCollectionId(item.payload?.collectionId)
        onFetchCollection(item.payload?.collectionId)
      } else if(typeCard==="latestUnits") {
        setLimit(item.payload?.limit)
        setSaleType(item.payload?.saleType)
        setPropertyType(item.payload?.propertyType)
      }

    }
  },[props.item])

  useEffect(()=>{
    if( status == STATUSES.SUCCESS ) {
       if( status && message && message._id && update ) {
        props?.onSave && props.onSave(props.index, {
          ...message,
          _id:message._id,
          sequence:message.sequence,
          image:message.images
        })
       }
    }
    return () => {
      setUpdate(false)
    }
  },[status])

  useEffect(() => {
    let data = []
    if( saleType !== "all" ) {
      if( saleType === "disewakan" ) {
        data = predefinedDisewakan
      } else if( saleType === "dijual" ) {
        data = predefinedJual
      }
      setPrice(data)
    }
  }, [saleType])
  

  const onToggleGallery = () => {
    setToggleGallery(!toggleGallery)
  }

  const onRemoveImage = () => {
    setImage()
  }

  const handleTitle = (e) => {
    setTitle(e.target.value)
  }

  const handleSubTitle = (e) => {
    setSubtitle(e.target.value)
  }

  const handleTypeChange = (e) => {
    setType(e.target.value)
    if( e.target.value === "collections") {
      onFetchCollection()
    }
  }

  const handleSaleTypeChange = (e) => {
    setSaleType(e.target.value)
    let data = []
    setSelectedPrice()
    if( e.target.value === "disewakan" ) {
      setUrlRegion((prev)=>`${prev.replace(/(all|dijual)/g, "disewakan")}`)
    } else if( e.target.value === "dijual") {
      setUrlRegion((prev)=>`${prev.replace(/(all|disewakan)/g, "dijual")}`)
    } else {
      setUrlRegion((prev)=>`${prev.replace(/(dijual|disewakan)/g, "all")}`)
    }
  }

  const handlePropertyTypeChange = (e) => {
    setPropertyType(e.target.value)
    setUrlRegion((prev)=>`${prev.replace(/(properti|rumah|ruko-komersial|apartemen|villa|tanah|kost)/g, e.target.value)}`)
  }

  const handleChangePrice = (e) => {
    const nowPrice = e.target.value
    setSelectedPrice(nowPrice)
    if( !selectedPrice ) {
      setUrlRegion((prev)=>`${prev}${nowPrice}`)
    } else {
      setUrlRegion((prev)=>`${prev.replace(selectedPrice, nowPrice)}`)
    }
  }

  const handleUrlChange = (e) => {
    setUrl(e.target.value)
  }

  const onLocationTag = (values) => {
    setSelectedRegions(values)
    
  }

  const onFetchCollection = (id=null) => {
    callPublicGet("/collections", {
      limit:100,
      page:0,
      size:"original"
    })
      .then(res=>{
        setCollections(res.data)
        if( id && res.data ) {
          let data2 = res.data.find(it=>it._id===id)
          if( data2 ) {
            setCollection(data2)
          }
        }
      })
      .catch(err=>{
        //
      })
  }

  /*const onSaveCollections = () => {
    if( isNumber(props.item._id) ) {
      callPost(`/admin/collections`, {
        title:collectionTitle,
        units:units
      }).then(res=>{
        let url = `/collections/${res.data?.slug}`
        onSave(res.data?._id, url)
      }).catch(err=>{
        //setUnits((prev)=>[...items])
      })
      
    } else {
      callPut(`/admin/collections/${collectionId}`, {
        title:collectionTitle,
        units:units
      }).then(res=>{
        let url = `/collections/${res.data?.slug}`
        onSave(res.data?._id, url)
      }).catch(err=>{
        //setUnits((prev)=>[...items])
      })
    }
  }*/

  const onSave = (urlSlug=null) => {
    let payload = {}
    let newUrl = url
    if( type === "region") {
      newUrl = "/nearby"
      if( propertyType ) {
        newUrl += `/${propertyType}`
      }
      if( saleType ) {
        newUrl += `-${saleType}`
      }
      if( selectedRegions ) {
        newUrl += `/${selectedRegions.slug}`
      }
      if( selectedPrice && selectedPrice !== "all" ) {
        newUrl += `/${selectedPrice}`
      }
      payload = {
        regions:selectedRegions,
        type,
        saleType,
        propertyType,
        price:selectedPrice
      }
     
    } else if( type === "url" ) {
      payload = { type }
    } else if( type === "latestUnits" ) {
      payload = { 
        type, 
        limit,
        saleType,
        propertyType,
      }
      newUrl = `/latest`
      if( propertyType ) {
        newUrl += `/${propertyType}`
      }
      if( saleType ) {
        newUrl += `/${saleType}`
      }
    } else if( type === "collections" ) {
      newUrl = collectionSlug
      payload = {
        type,
        collectionId,
        collectionSlug
      }
    }

    const params = {
      _id:props.item._id,
      sequence:props.item.sequence,
      title,
      subTitle:subtitle,
      type,
      url:newUrl,
      image:image,
      payload
    }

    if( isNumber(props.item._id) ) {
      dispatch(addVarious({
        ...params,
        images:image.map(it=>it._id)
      }))
      setUpdate(true)
    } else {
      dispatch(updateVarious(props.item._id, {
        ...params,
        images:image.map(it=>it._id)
      }))
      setUpdate(true)
    }
    
  }

  const disableSave = () => {
    if( type==="region" && (title === "" || subtitle === ""  || isEmpty(selectedRegions) || isEmpty(image) ) ) return true
    if( type==="url" && (title === "" || subtitle === "" || isEmpty(url) || isEmpty(image)) ) return true
    if( type==="collections" && (title === "" || subtitle === "" || isEmpty(collectionId) || isEmpty(image) ) ) return true
    if( type==="latestUnits" && (title === "" || subtitle === "") ) return true
    return false
  }

  return (<>
      {toggleGallery && <MediaGallery 
        title={"Pick One"}
        expanded={toggleGallery} 
        doCollapse={onToggleGallery} 
        pushItem={(item)=>{
          setImage([item])
          onToggleGallery()
        }}
      />}
      <Accordion expanded={expanded} onChange={(e,expand)=>setExpanded(expand)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div style={{flexDirection:"column"}}>
            <Typography variant='h6' color={title!==""?"inherit":"textSecondary"}>
              {title!==""?title:"New Card"} {title!=="" && <Chip style={{fontSize:11}} label={type.toUpperCase()} variant="primary" size="small" />}
            </Typography>
            {subtitle && subtitle !== "" && <Typography variant='p' color={"textSecondary"}>{subtitle}</Typography>}
          </div>
        </AccordionSummary>
        <AccordionDetails style={{flexDirection:"column"}}>
            <Grid container direction='row' spacing={2}>
              <Grid item>
                {isEmpty(image) ? <div className={classes.dropzone} style={{width:308}} onClick={onToggleGallery}>
                    <p>Click here to Select Image</p>
                </div> : <>
                  <Card style={{marginBottom:20,width:308}} onClick={onToggleGallery}>
                        <CardActionArea style={{position:"relative"}}>
                            <CardMedia
                                style={{height:386,width:308}}
                                image={image[0].url}
                            />
                            {title !== "" && <div className={classes.headingCard}>{title}</div>}
                            {subtitle !== "" && <div className={classes.subheadingCard}>{subtitle}</div>}
                            
                        </CardActionArea>
                    </Card>
                    <Button onClick={onRemoveImage} disableElevation color="primary" variant="outlined">Remove</Button>
                    <Button onClick={onToggleGallery} disableElevation style={{marginLeft:10}}  color="primary" variant="contained">Change Photo</Button>
                  </>}
              </Grid>
              <Grid item xs={8}>
                <TextField 
                  fullWidth={true} style={{marginBottom:30}} 
                  id="title-basic" 
                  onChange={(e)=>handleTitle(e)} 
                  inputProps={{maxLength:20}}
                  placeholder="Max. 20 chars" 
                  label="Judul Card" 
                  value={title} 
                />
                <TextField 
                  fullWidth={true} style={{marginBottom:30}} 
                  id="subtitle-basic" 
                  onChange={(e)=>handleSubTitle(e)} 
                  inputProps={{maxLength:30}}
                  placeholder="Max. 30 chars" 
                  label="Sub judul" 
                  value={subtitle} 
                />
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={type??"Select type"}
                  placeholder={"Select Type"}
                  label="Select Type"
                  fullWidth={true}
                  style={{marginBottom:30}}
                  onChange={handleTypeChange}
                >
                  <MenuItem value={"region"}>Search</MenuItem>
                  <MenuItem value={"url"}>URL</MenuItem>
                  <MenuItem value={"collections"}>Collection</MenuItem>
                  <MenuItem value={"latestUnits"}>Latest Upload</MenuItem>
                </Select>

                {type==="region" && <>{selectedRegions ? <TagRegion 
                    id="searchRegions" 
                    onChange={(value)=>{
                      onLocationTag(value)
                    }}
                    value={selectedRegions}
                    label="Region" 
                    multiple={false}
                    style={{marginBottom:20}}
                    placeholder="Enter Region" 
                    variant={"standard"}
                    fieldComparator={"_id"}
                    fieldLabel={"name"}
                  /> : <TagRegion 
                        id="searchRegions" 
                        onChange={(value)=>{
                          onLocationTag(value)
                        }}
                        multiple={false}
                        label="Region" 
                        variant={"standard"}
                        style={{marginBottom:20}}
                        placeholder="Enter Region"
                        fieldComparator={"_id"}
                        fieldLabel={"name"}
                      />}
                </>}
                {(type === "region" || type==="latestUnits") && <Grid container direction="row" style={{marginBottom:0}}
                    justify="flex-start" spacing={2}
                    alignItems={"flex-start"}>
                      <Grid item xs={6}>
                        <Select
                          id="sale-type-select"
                          value={saleType??"Sale Type"}
                          placeholder={"Select Type"}
                          label="Select Type"
                          fullWidth={true}
                          style={{marginBottom:20}}
                          onChange={handleSaleTypeChange}
                        >
                          <MenuItem value={"all"}>All</MenuItem>
                          <MenuItem value={"dijual"}>Dijual</MenuItem>
                          <MenuItem value={"disewakan"}>Disewakan</MenuItem>
                        </Select>
                      </Grid>

                      <Grid item xs={6}>
                        <Select
                          id="property-type-select"
                          value={propertyType??"Property Type"}
                          placeholder={"Select Type"}
                          label="Select Type"
                          fullWidth={true}
                          style={{marginBottom:20}}
                          onChange={handlePropertyTypeChange}
                        >
                          <MenuItem value={"properti"}>All Property</MenuItem>
                          <MenuItem value={"rumah"}>Rumah</MenuItem>
                          <MenuItem value={"tanah"}>Tanah</MenuItem>
                          <MenuItem value={"apartemen"}>Apartement</MenuItem>
                          <MenuItem value={"ruko-komersial"}>Ruko/Komersial</MenuItem>
                          <MenuItem value={"villa"}>Villa</MenuItem>
                          <MenuItem value={"kost"}>Kost</MenuItem>
                        </Select>
                      </Grid>
                </Grid>}

                {(price && !isEmpty(selectedRegions) && type === "region") && <Select
                  labelId="select-prices"
                  id="select-prices"
                  value={selectedPrice??"all"}
                  placeholder={"Harga Slug"}
                  label="Harga Slug"
                  fullWidth={true}
                  style={{marginBottom:20}}
                  onChange={handleChangePrice}
                >
                  <MenuItem value={"all"}>--Pilih Harga--</MenuItem>
                  {price.map(item=><MenuItem value={item.slug}>{item.slugTitle}</MenuItem>)}
                </Select>}

                {type === "region" ? <TextField 
                  fullWidth={true} 
                  style={{marginBottom:30,display:"none"}} 
                  id="url-link" 
                  type={"url"}
                  multiline={true}
                  
                  disabled={true}
                  rows={3}
                  placeholder="URL Link" 
                  label="URL Link" 
                  value={urlRegion} 
                /> : type==="url" ? <TextField 
                  fullWidth={true} 
                  style={{marginBottom:30}} 
                  id="url-link" 
                  type={"url"}
                  multiline={true}
                  rows={3}
                  onChange={(e)=>handleUrlChange(e)} 
                  placeholder="URL Link" 
                  label="URL Link" 
                  value={url} 
                /> : null}

                {type==="latestUnits" && <TextField 
                  fullWidth={true} 
                  style={{marginBottom:30}} 
                  id="limit-number" 
                  placeholder="How many numbers" 
                  label="Limit Number" 
                  value={limit} 
                  onChange={(e)=>setLimit(e.target.value)}
                />}

                {type==="collections" && <>
                    <Select
                      id="collection-id-select"
                      value={collectionId}
                      placeholder={"Select Collection"}
                      label="Select Collection"
                      fullWidth={true}
                      style={{marginBottom:20}}
                      onChange={(e)=>{
                        let collect = collections.find(it=>it._id===e.target.value)
                        if( collect ) {
                          setCollection(collect)
                          setCollectionSlug(collect.slug)
                        }
                        setCollectionId(e.target.value)
                      }}
                    >
                      {collections.map(item=><MenuItem value={item._id}>
                        {item.title}
                      </MenuItem>)}
                    </Select>
                </>}
              </Grid>
            </Grid>
            
            <Button onClick={()=>onSave()} color="primary" disabled={disableSave()} style={{width:200,alignSelf:"flex-end"}} variant="contained">Save</Button>

        </AccordionDetails>
      </Accordion>
    </>);
}
const CollectionUnits = (props) => {
  const [units, setUnits] = useState([])
  const [isAdd, setIsAdd] = useState(false)
  const [init, setInit] = useState(false)
  useEffect(()=>{
    if( props.id ) {
      setInit(true)
      callPublicGet(`/collections/${props.id}`)
        .then(res=>{
          let formatUnits = res.data.units.map(it=>(
            {
              id:it,
              data:null,
              required:false,
              notFound:false
            })
          )
          setUnits(formatUnits)
          return formatUnits
        })
        .then(res=>{
          let calls = []
          res.map(data=>{
            if( !data.data ) {
              calls.push(callPublicGet(`/unit/${data.id}`))
            }
          })
          Promise.all(calls).then(values=>{
            let newData = res
            values.map(value=>{
              let idnx = findIndex(newData, (it)=>it.id===value.data?._id )
              if( idnx > -1 ) {
                newData[idnx].data = value.data
              }
            })
            setUnits(newData)
            setInit(false)
          })
        })
    }
  },[props.id])

  useEffect(()=>{
    if( isAdd ) {
      setUnits((prev)=>[...prev, {id:"",data:null,required:false,notFound:false}])
    }
    return () => {
      setIsAdd(false)
    }
  },[isAdd])

  const onAdd = () => {
    let items = units
    let lastIndex = items.length-1
    if( lastIndex > -1 ) {
      if (items[lastIndex].id === "") {
        items[lastIndex].required = true
        setUnits(()=>[...items])
        return
      } 

      if ( !units[lastIndex].data ) {
        items[lastIndex].notFound = true
        setUnits(()=>[...items])
        return
      } 
    } 
    setIsAdd(true)
  }

  const handleChange = (e, index) => {
    let items = units
    const value  = e.target.value
    items[index] = {id:e.target.value, data:null, required:value.length===0?true:false}
    setUnits((prev)=>[...items])
  }

  const handleBlur = (e, index) => {
    let items = units
    const id = items[index].id
    callPublicGet(`/unit/${id}`)
        .then(res=>{
          items[index].data = res?.data
          items[index].required = false
          items[index].notFound = false
          props.onUpdate && props.onUpdate(items.map(it=>it.id))
          setUnits((prev)=>[...items])
        }).catch(err=>{
          items[index].notFound = true
          items[index].required = false
          setUnits((prev)=>[...items])
        }).finally(()=>{

        })

  }

  return (<div>
      <Button varian="default" size="small" disabled={init} startIcon={ <AddIcon />} onClick={onAdd} color="inherit">Add Unit</Button>
      <Grid container direction='row' style={{marginTop:10}} spacing={2}>
      {init ? <CircularProgress /> : units.map((item,index)=><Grid item xs={6}>
          <TextField 
            fullWidth={true} 
            style={{marginBottom:10}} 
            id="unit-id" 
            placeholder="Enter object Unit ID here" 
            label="Unit ID" 
            value={item.id} 
            onBlur={(e)=>handleBlur(e, index)}
            onChange={(e)=>handleChange(e, index)}
            error={item?.required?true:(item?.notFound?true:false)}
            helperText={item?.required?"Enter required field":(item?.notFound?"Unit ID not found":"")}
          />
          {item?.data && <div style={{marginBottom:15}}>
            <Grid container direction='row' spacing={2}>
              {item.data?.thumb && <Grid item>
                  <img style={{width:100}} src={item.data.thumb[0]?.url} />
              </Grid>}
              <Grid item lg={8}>
                  {item.data?.price && <Typography variant="subtitle1">Rp {currency.digit(item.data.price)}</Typography>}
                  {item.data?.address ? <Typography variant="subtitle2" style={{lineHeight:'16px',marginBottom:6}} color="textSecondary">{item.data.address?.street1}</Typography> : <Typography variant="subtitle1">Unknown address</Typography>}
                  <Chip label={item.data.type} variant="primary" size='small' /> <Chip label={item.data.saleType} variant="primary" size='small' />
              </Grid>
            </Grid>  
          </div>}
      </Grid>)}
    </Grid>
  </div>)
}


References.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  common: state.common
});

export default connect(mapStateToProps, { 
  listVarious, addVarious, updateVarious, removeVarious, 
  addCollection, updateCollection, removeCollection 
})(References)