import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useHistory } from "react-router-dom"
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import MapIcon from "@material-ui/icons/MapOutlined"
import { listCustomers, updateStatus, setUserDeveloper, setUserLevelProject } from "../actions/customer"
import { isEmpty, filter, map, kebabCase, lowerCase, orderBy } from "lodash"
import moment from "moment"
import Badge from "@material-ui/core/Badge"
import TextField from '@material-ui/core/TextField'
import { useSnackbar } from "notistack"
import ModalSearchCity from "../components/ModalSearchCity"
import { addPopularValidate } from "../validations/common"
import Alert from '@material-ui/lab/Alert';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(3)
    },
    fixedHeight: {
      
    },
    // modal styling
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalPaper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    backdrop:{
        backgroundColor:'rgba(0, 0, 0, 0.86)'
    }
}));

const UserDeveloper = (props) => {
    const classes = useStyles();
    const history = useHistory()
    const { enqueueSnackbar } = useSnackbar()
    const [tab, setTab] = useState("user");
    const [level, setLevel] = useState("city");
    const [result, setResult] = useState("")
    const [error, setError] = useState(false)
    const [email, setEmail] = useState("")
    const [emailLevel, setEmailLevel] = useState("")

    useEffect(() => {
       
    }, []);

    const onTab = (event, newValue) => {
        setTab(newValue);
    }

    const onSearch = (e) => {
        if(e.key === "Enter"){
            setResult(null)
            setError(false)
            fetchData()
        }
    }

    const onSubmit = (e) => {
        setResult(null)
        setError(false)
        fetchData()
    }

    const onBindSet = (e) => {
        if(e.key === "Enter"){
            setResult(null)
            setError(false)
            setLevel()
        }
    }

    const onSetlevel = (e) => {
        setResult(null)
        setError(false)
        setLevelProject()
    }

    const fetchData = () => {
        props.setUserDeveloper({email})
            .then(res=>{
                setResult(res)
                console.log("res", res)
                setError(false)
            }).catch(err=>{
                setError(true)
                setResult(null)
            })
    }

    const setLevelProject = () => {
        props.setUserLevelProject({email:emailLevel,projectLevel:level})
            .then(res=>{
                setResult(res)
                console.log("res", res)
                setError(false)
            }).catch(err=>{
                setError(true)
                setResult(null)
            })
    }

    const handleChange = (e) => {
        if( result ) {
            setResult(null)
        }
        if( error ) {
            setError(false)
        }
        setEmail(e.target.value)
    }

    const handleChangeLevel = (e) => {
        if( result ) {
            setResult(null)
        }
        if( error ) {
            setError(false)
        }
        setEmailLevel(e.target.value)
    }

    return (
        <Grid container spacing={3}>
            <Grid item sm md lg xl>
            
                <Tabs
                    value={tab}
                    onChange={onTab}
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab value={"user"} label="Assign User" />
                    <Tab value={"project"} label="Set Level Project" />
                </Tabs>
               
                
                {tab==="user" && <Paper elevation={3} className={classes.paper}>
                    <Grid container direction="row" justify="flex-start" style={{marginBottom:30}} spacing={2}>
                        <Grid item>
                            <Typography variant="h5" component="h5">Assign User Developer</Typography>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" justify="flex-start" style={{marginBottom:20}} spacing={2}>
                        <Grid item xs={10}>
                        <Typography color="textSecondary" paragraph>Input email address and hit "Enter" to verify</Typography>
                            <TextField
                                required
                                id="email"
                                fullWidth
                                name="email"
                                label="Email Address"
                                variant="outlined"
                                onChange={handleChange}
                                onKeyPress={onSearch}
                                value={email}
                                placeholder="Input email user and hit Enter to submit"
                            />
                        </Grid>
                       
                    </Grid>

                    <Grid container direction="row" justify="flex-start" style={{marginBottom:20}} spacing={2}>
                        <Grid item xs={3}>
                            <Button variant="contained" color="primary" disabled={email.length<1}  disableElevation size="large" onClick={onSubmit}>Submit</Button>
                        </Grid>
                    </Grid>

                </Paper>}

                {tab==="project" && <Paper elevation={3} className={classes.paper}>
                    <Grid container direction="row" justify="flex-start" style={{marginBottom:30}} spacing={2}>
                        <Grid item>
                            <Typography variant="h5" component="h5">Set Level Project</Typography>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" justify="flex-start" style={{marginBottom:20}} spacing={2}>
                        <Grid item xs={10}>
                            <Typography color="textSecondary" paragraph>Input email address and hit "Enter" to verify</Typography>
                            <TextField
                                required
                                id="emailLevel"
                                fullWidth
                                name="emailLevel"
                                label="Email Address"
                                variant="outlined"
                                onChange={handleChangeLevel}
                                onKeyPress={onBindSet}
                                value={emailLevel}
                                placeholder="Input email user and hit Enter to submit"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography color="textSecondary" paragraph>Level type</Typography>
                            <Select
                                id="type-select"
                                value={level}
                                placeholder={"Select level"}
                                label="Select level"
                                fullWidth={true}
                                variant={"outlined"}
                                style={{marginBottom:20}}
                                onChange={(e)=>setLevel(e.target.value)}
                                >
                                <MenuItem value={"province"}>Province</MenuItem>
                                <MenuItem value={"city"}>City</MenuItem>
                                <MenuItem value={"district"}>District</MenuItem>
                                <MenuItem value={"village"}>Village</MenuItem>
                            </Select>
                     
                        </Grid>
                       
                    </Grid>

                    <Grid container direction="row" justify="flex-start" style={{marginBottom:20}} spacing={2}>
                        <Grid item xs={3}>
                            <Button variant="contained" color="primary" disabled={emailLevel.length<1}  disableElevation size="large" onClick={onSetlevel}>Submit</Button>
                        </Grid>
                    </Grid>

                </Paper>}

                {tab === "user" && <>
                    {email && error && <Alert severity="error">User <strong>{email}</strong> is not found</Alert>}

                    {email && result && result?.data && result?.data?.success && result.data.success.map(email2=><Alert severity="success"><strong>{email2}</strong>{" is successfully assigned"}</Alert>)}

                    {email && result && result?.data && result?.data?.failed && result.data.failed.map(email2=><Alert severity="error"><strong>{email2}</strong>{" is not found or already assigned"}</Alert>)}
                </>}

                {tab === "project" && <>
                    {emailLevel && error && <Alert severity="error">User <strong>{emailLevel}</strong> is not found</Alert>}

                    {emailLevel && result && result?.data && result?.data?.success && result.data.success.map(email2=><Alert severity="success"><strong>{email2}</strong>{" is successfully set up"}</Alert>)}

                    {emailLevel && result && result?.data && result?.data?.failed && result.data.failed.map(email2=><Alert severity="error"><strong>{email2}</strong>{" is not found or already set up"}</Alert>)}
                </>}
                
            </Grid>
        </Grid>
        
    );
}

UserDeveloper.propTypes = {
    auth: PropTypes.object.isRequired,
    blog: PropTypes.object.isRequired
};
  
const mapStateToProps = state => ({
    auth: state.auth,
    blog: state.blog.list
});

export default connect(mapStateToProps, {listCustomers, updateStatus, setUserDeveloper, setUserLevelProject})(UserDeveloper)