import {isEmpty} from "lodash";
import { FAQ_LIST, ADD_FAQ, ADD_FAQ_SECTION, UPDATE_FAQ, UPDATE_FAQ_SECTION, REMOVE_FAQ, REMOVE_FAQ_SECTION } from "../actions/types";

const initialState = {
  list: [],
  section:null,
  single: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_FAQ_SECTION:
        return {
            ...state,
            section:action.payload
        }
    case FAQ_LIST:
        return {
            ...state,
            list: action.payload
        };
    default:
      return state;
  }
}
