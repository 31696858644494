/* eslint-disable no-use-before-define */
import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
}));

export default function Tags({id, label, placeholder, options, onChange, fieldLabel, fieldComparator, ...props}) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        id={id}
        options={options}
        onChange={onChange}
        getOptionLabel={(option) => option[fieldLabel]}
        filterSelectedOptions
        getOptionSelected={(option, value) => option[fieldComparator] === value[fieldComparator]}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            placeholder={placeholder}
          />
        )}
        {...props}
      />
    </div>
  );
}
