import React, {useState} from "react"
import { Route, Redirect } from "react-router-dom"
import { useHistory } from "react-router-dom"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import clsx from 'clsx'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Menu from "../layout/Menu"
import HeaderInside from "../layout/HeaderInside"
import FooterInside from "../layout/FooterInside"
import { logoutUser } from "../actions/auth"


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    toolbar: {
        paddingRight: 24// keep right padding when drawer closed
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto'
    },
    appBarSpacer: theme.mixins.toolbar,
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
}))

const PrivateRoute = ({ component: Component,  auth, ...rest }) => {
    const classes = useStyles()
    const history = useHistory()
    const [open, setOpen] = useState(true);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };  

    const onLogout = () => {
        rest.logoutUser()
        history.push('/')
    }

    return (<Route {...rest}
        render={props =>
        auth.isAuthenticated === true ? (
            <div className={classes.root}>
                <CssBaseline />
                <HeaderInside title={rest.name} open={open} 
                    handleDrawerOpen={handleDrawerOpen} 
                    handleDrawerClose={handleDrawerClose} 
                />
                <Menu open={open} 
                    onLogout={onLogout}
                    handleDrawerOpen={handleDrawerOpen} 
                    handleDrawerClose={handleDrawerClose} 
                />
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth="xl" className={classes.container}>
                        <Component {...props} />
                    </Container>
                   
                </main>
                
            </div>
        ) : (
            <Redirect to="/" />
        )
        }
    />)
}

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,

};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, {logoutUser})(PrivateRoute);
