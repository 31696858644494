import React, {useState,useEffect} from 'react';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom"
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { isEmpty, has, find, indexOf, findIndex, unset } from "lodash"
import { Add as AddIcon, ModeComment, Folder, Edit, SaveAlt, CheckOutlined } from "@material-ui/icons"
import PackagePage from "../components/Packages/PackageAgent"
import PackageCoupon from "../components/Packages/PackageCoupon"
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import { listPackages } from "../actions/package"

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    textAlign:"center"
  },
  divider:{
    margin:"15px 0px"
  },
  fixedHeight: {
    height: 540,
  },
  // modal styling
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
  },
  backdrop:{
      backgroundColor:'rgba(0, 0, 0, 0.86)'
  },
  listItem:{
    paddingLeft:0
  },
  listItemIcon:{
    minWidth:36
  },
  textField:{},
  margin:{}
}));

function a11yProps(index) {
    return {
      id: `nav-tab-${index}`,
      'aria-controls': `nav-tabpanel-${index}`,
    }; 
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`nav-tabpanel-${index}`}
        aria-labelledby={`nav-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            {children}
          </Box>
        )}
      </div>
    );
}
  
function LinkTab(props) {
    return (
      <Tab
        component="a"
        onClick={(event) => {
          event.preventDefault();
        }}
        {...props}
      />
    );
  }

  
const Packages = (props) => {
  const classes = useStyles();
  const [total, setTotal] = useState(1)
  const [value, setValue] = useState("profesional");
  const [mode, setMode] = useState("mo")
  const [tables, setTables] = useState([{
    title:"Package 1",
    currency:"$",
    desc:"",
    status:false,
    startDate:"",
    endDate:"",
    priceMo:"12.22",
    priceYr:"90.22"
  }])
  const history = useHistory()

  useEffect(() => {
    let a = props.location.search
    if( ! isEmpty(a) ) {
      let urlparams = new URLSearchParams(a)
      if( urlparams.get("tab") && urlparams.get("tab") == 'coupon' ) {
        setValue("addon");
      }
    }
  }, []);

  useEffect(() => {
    let a = props.location.search
    if( ! isEmpty(a) ) {
      let urlparams = new URLSearchParams(a)
      if( urlparams.get("refetch") ) {
        setValue("addon");
      }
    }
  }, [props.location.search]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if( newValue == 'addon' ) {
      history.replace("/packages?tab=coupon")
    } else {
      history.replace("/packages")
    }
  };

  return (<Grid container spacing={3}>
            <Grid item xs={12} md={8} lg={12}>
                <div className={classes.fixedHeight}>
                  <Card className={classes.root}>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            Manage Package & Coupon
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary" component="p">
                          Create, edit and delete package
                        </Typography>

                        <Paper color="default" elevation={0}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                aria-label="nav tabs example"
                            >
                                <LinkTab value={"profesional"} label="Packages" href="/agent" {...a11yProps('profesional')} />

                                <LinkTab value={"addon"} label="Coupons" href="/addOn" {...a11yProps('addon')} />
                            </Tabs>
                        </Paper>

                        <TabPanel value={value} index={'profesional'}>
                            <PackagePage type={value} />
                        </TabPanel>

                        <TabPanel value={value} index={'addon'}>
                            <PackageCoupon type={value} />
                        </TabPanel>
                       
                      </CardContent>
                     
                  </Card>
                </div>
            </Grid>
        </Grid>
  )
}

Packages.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  common: state.common
});

export default connect(mapStateToProps, { listPackages })(Packages)