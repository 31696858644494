import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useHistory } from "react-router-dom"
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import MapIcon from "@material-ui/icons/MapOutlined"
import CheckIcon from "@material-ui/icons/Check"
import UserIcon from "@material-ui/icons/Person"
import { listCustomers, updateStatus, setUserBadge, searchAgent } from "../actions/customer"
import { isEmpty, filter, map, kebabCase, lowerCase, orderBy, cloneDeep } from "lodash"
import moment from "moment"
import Badge from "@material-ui/core/Badge"
import TextField from '@material-ui/core/TextField'
import { useSnackbar } from "notistack"
import ModalSearchCity from "../components/ModalSearchCity"
import { addPopularValidate } from "../validations/common"
import Alert from '@material-ui/lab/Alert';
import { Label } from '@material-ui/icons';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MenuItem from '@material-ui/core/MenuItem'
import { AGENT_BAGDES } from "../constant"
import { Chip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(3)
    },
    fixedHeight: {
      
    },
    // modal styling
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalPaper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    backdrop:{
        backgroundColor:'rgba(0, 0, 0, 0.86)'
    }
}));

const UserAgent = (props) => {
    const classes = useStyles();
    const history = useHistory()
    const { enqueueSnackbar } = useSnackbar()
    const [tab, setTab] = useState("verify");
    const [result, setResult] = useState("")
    const [notFound, setNotFound] = useState("")
    const [type, setType] = useState("")
    const [badges, setBadges] = useState([])
    const [removeBadges, setRemoveBadges] = useState([])
    const [error, setError] = useState(false)
    const [user, setUser] = useState()
    const [email, setEmail] = useState("")
    const [emailBadge, setEmailBadge] = useState("")
    const [submit, setSubmit] = useState(false)

    useEffect(() => {
       
    }, []);

    const onTab = (event, newValue) => {
        setTab(newValue);
        setNotFound("")
    }

    const onSearch = (e) => {
        if(e.key === "Enter"){
            if(!type) {
                enqueueSnackbar("Select one of verify type email or phone number", {variant:"error"})
                return
            }
            setResult(null)
            setError(false)
            fetchData()
        }
    }

    const onSubmit = (e) => {
        if(!type) {
            enqueueSnackbar("Select one of verify type email or phone number", {variant:"error"})
            return
        }
        setResult(null)
        setError(false)
        fetchData()
    }

    const onSearchOfBadge = (e) => {
        if(e.key === "Enter"){
            setResult(null)
            setError(false)
            searchUser()
        }
    }

    const fetchData = () => {
        setSubmit(true)
        props.updateStatus({email,type:type,status:"verify"})
            .then(res=>{
                setResult(res)
            }).catch(err=>{
                setResult(null)
            }).finally(()=>{
                setSubmit(false)
            })
    }

    const searchUser = () => {
        setNotFound("")
        setBadges([])
        setUser()
        props.searchAgent({email:emailBadge})
            .then(res=>{
                setUser(res.data)
                if( res.data.badge) {
                    setBadges(res.data.badge)
                }
                setError(false)
            }).catch(err=>{
                setError(true)
                setUser()
                setNotFound("The user is not found")
            }).finally(()=>{
                setSubmit(false)
                setEmailBadge("")
            })
    }

    const setUserBagde = () => {
        if(isEmpty(badges)&&isEmpty(removeBadges)) {
            enqueueSnackbar("Select one of badges to add or remove", {variant:"error"})
            return
        }

        setSubmit(true)
        props.setUserBadge({emails:[user?.email],badge:badges,removeBadge:removeBadges})
            .then(res=>{
                setResult(res)
                setError(false)
                props.searchAgent({email:user?.email})
                    .then(res=> setUser(res.data))
                    .catch(err=> console.log("err", err) )
            }).catch(err=>{
                setError(true)
                setResult(null)
            }).finally(()=>{
                setSubmit(false)
            })
    }

    const handleChange = (e) => {
        if( result ) {
            setResult(null)
        }
        if( error ) {
            setError(false)
        }
        setEmail(e.target.value)
    }

    const onChangeEmailBadge = (e) => {
        if( result ) {
            setResult(null)
        }
        if( error ) {
            setError(false)
        }
        setEmailBadge(e.target.value)
    }

    const onSetType = (value) => {
        setResult(null)
        setType(value)
    }

    const onSetBadge = (type) => {
        let badges2 = cloneDeep(badges) 
        let removeBadges2 = cloneDeep(removeBadges)
        if( badges2.includes(type) ) {
            let idx = badges.findIndex(v=>v===type)
            badges2.splice(idx, 1)
            setBadges(()=>[...badges2])
            if( !removeBadges2.includes(type) ) {
                removeBadges2.push(type)
                setRemoveBadges((prev)=>removeBadges2)
            }
        } else {
            badges2.push(type)
            setBadges((prev)=>[...badges2])
            if( removeBadges2.includes(type) ) {
                let idx = removeBadges2.findIndex(v=>v===type)
                removeBadges2.splice(idx, 1)
                setRemoveBadges((prev)=>[...removeBadges2])
            }
        }
        
    }

    const clear = () => {
        setUser(null)
        setEmailBadge("")
        setBadges([])
        setRemoveBadges([])
    }

    return (
        <Grid container spacing={3}>
            <Grid item sm md lg xl>

                <Tabs
                    value={tab}
                    onChange={onTab}
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab value={"verify"} label="Verification" />
                    <Tab value={"badges"} label="Manage Badges" />
                </Tabs>

                {tab==="verify" && <Paper elevation={3} className={classes.paper}>
                    <Grid container direction="row" justify="flex-start" style={{marginBottom:30}} spacing={2}>
                        <Grid item>
                            <Typography variant="h5" component="h5">User Agent Verification</Typography>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" justify="flex-start" style={{marginBottom:20}} spacing={2}>
                        <Grid item xs={9}>
                            <Typography color="textSecondary" paragraph>Input email address and hit "Enter" to verify</Typography>
                            <TextField
                                required
                                id="email"
                                fullWidth
                                name="email"
                                label="Email Address"
                                variant="outlined"
                                onChange={handleChange}
                                onKeyPress={onSearch}
                                value={email}
                                placeholder="Input email user and hit Enter to verify"
                            />
                            
                        </Grid>
                        <Grid item xs={2}>
                            <Typography color="textSecondary" paragraph>Verify type</Typography>
                            <ButtonGroup disableElevation color="primary" size={"large"}>
                                <Button size={"large"} style={{paddingTop:13,paddingBottom:13}} variant={type=="email"?"contained":"outlined"} onClick={()=>onSetType('email')}>Email</Button>
                                <Button variant={type=="phoneNumber"?"contained":"outlined"} onClick={()=>onSetType('phoneNumber')}>No. hp</Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" justify="flex-start" style={{marginBottom:20}} spacing={2}>
                        <Grid item xs={3}>
                            <Button variant="contained" color="primary" disabled={email.length<1}  disableElevation size="large"  onClick={onSubmit}>Submit</Button>
                        </Grid>
                    </Grid>

                </Paper>}

                {tab==="badges" && <Paper elevation={3} className={classes.paper}>
                    <Grid container direction="row" justify="flex-start" style={{marginBottom:30}} spacing={2}>
                        <Grid item>
                            <Typography variant="h5" component="h5">Manage Badges</Typography>
                        </Grid>
                    </Grid>

                    {tab === "badges" && <>
                        {user && error && !notFound && <Alert severity="error">User <strong>{emailBadge}</strong> is not found</Alert>}
                        {user && result && result?.success && <Alert severity="success" style={{marginBottom:20}}><strong>{user.email}</strong>{" is successfully updated Badges"}</Alert>}
                    </>}

                    <Grid container direction="row" justify="flex-start" style={{marginBottom:20}} spacing={2}>
                        <Grid item xs={9}>
                            <Typography color="textSecondary" paragraph>Input email address and hit "Enter" to verify</Typography>
                            <TextField
                                required
                                id="email_badge"
                                fullWidth
                                name="email_badge"
                                label="Email Address"
                                variant="outlined"
                                onChange={onChangeEmailBadge}
                                onKeyPress={onSearchOfBadge}
                                value={emailBadge}
                                placeholder="Input email user and hit Enter to search"
                            />
                            {notFound !== "" && <Alert severity='error' fullWidth={true}>{notFound}</Alert>}
                        </Grid>
                        <Grid item xs={3}>
                            <Button size={"large"} color="primary" style={{paddingTop:13,paddingBottom:13,marginTop:40,width:200}} disabled={emailBadge===""}  disableElevation variant={"contained"} onClick={()=>searchUser()}>Find</Button>
                            <Button size={"large"} color="default" style={{paddingTop:13,paddingBottom:13,marginTop:40,marginLeft:20}} variant={"default"} onClick={()=>clear()}>Clear</Button>
                        </Grid>
                    </Grid>

                    {user && <Grid container direction="row" justify="flex-start" style={{marginBottom:20}} spacing={2}>
                        {badges && badges.includes(AGENT_BAGDES.PREMIUM) && <Grid item>
                            <div>
                                <Badge color="secondary" variant='circular' badgeContent={<CheckIcon style={{fontSize:12}} />} anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}>
                                    <UserIcon style={{fontSize:50}} />
                                </Badge>
                            </div>
                        </Grid>}
                        <Grid item xs={6} style={{marginLeft:10}}>
                            <div style={{marginBottom:10}}>
                                <Typography color="textPrimary" style={{fontWeight:"bold",paddingBottom:15}} component={"span"}>{props.user.name} - </Typography>
                                <Typography color="textSecondary" component={"span"} style={{paddingBottom:15}}>{`(${props.user.email})`}</Typography>
                            </div>
                            {user && user.accountVerification.phoneNumber=="notVerify" && user.accountVerification.email=="notVerify" && <>
                                <Chip label="Email not verified" size="small" color="secondary" variant='outlined' />
                                <Chip label="Phone number not verified" size="small" color="secondary" variant='outlined' />
                            </>}
                            {user && user.accountVerification.email=="verify" && user.accountVerification.phoneNumber=="notVerify" && <>
                                <Chip label="Email verified" size="small" color="primary" variant='outlined' />
                                <Chip label="Phone Number not verified" size="small" color="secondary" variant='outlined' />
                            </>}
                            {user && user.accountVerification.email=="verify" && user.accountVerification.phoneNumber=="verify" && <>
                                <Chip label="Email verified" size="small" color="primary" variant='outlined' />
                                <Chip label="Phone Number verified" size="small" color="primary" variant='outlined' />
                            </>}
                            {user && user.badge.includes(AGENT_BAGDES.PREMIUM) && <Chip label="Added badge" style={{color:"#fff"}} size="small" color="primary" />}
                        </Grid>
                    </Grid>}

                    {user && user.accountVerification.email=="verify" && user.accountVerification.phoneNumber=="verify" && <Grid container direction="row" justify="flex-start" style={{marginBottom:20}} spacing={2}>
                        <Grid item xs={12}>
                            <Typography color="textSecondary" paragraph>Badge type</Typography>
                            <ButtonGroup disableElevation color="secondary" size={"large"}>
                                <Button size={"large"} style={{paddingTop:13,paddingBottom:13}} variant={badges.includes(AGENT_BAGDES.PREMIUM) ? "contained":"outlined"} onClick={()=>onSetBadge(AGENT_BAGDES.PREMIUM)}>VERIFIED MEMBER</Button>
                            </ButtonGroup>
                        </Grid>

                        {user.accountVerification.email=="verify" && user.accountVerification.phoneNumber=="verify" && <Grid item xs={12}>
                            <Typography color="textSecondary" paragraph>Action</Typography>
                            <Button variant="contained" color="primary" disabled={isEmpty(badges)&&isEmpty(removeBadges)?true:false} disableElevation size="large"  onClick={setUserBagde}>Submit</Button>
                        </Grid>}
                        
                    </Grid>}
                </Paper>}

                {tab === "verify" && <>
                    {email && error && <Alert severity="error">User <strong>{email}</strong> is not found</Alert>}

                    {email && result && result?.data && result?.data?.success && result.data.success.map(email2=><Alert severity="success"><strong>{email2}</strong>{` is successfully verified by ${type}`}</Alert>)}

                    {email && result && result?.data && result?.data?.failed && result.data.failed.map(email2=><Alert severity="error"><strong>{email2}</strong>{" is not found or already assigned"}</Alert>)}
                </>}

               
            </Grid>
        </Grid>
        
    );
}

UserAgent.propTypes = {
    auth: PropTypes.object.isRequired,
    blog: PropTypes.object.isRequired
};
  
const mapStateToProps = state => ({
    user: state.common.user,
    auth: state.auth,
    blog: state.blog.list
});

export default connect(mapStateToProps, {listCustomers, updateStatus, searchAgent, setUserBadge })(UserAgent)