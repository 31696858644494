import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom"
import { Provider } from "react-redux"
import Store from "./store"
import jwtDecode from "jwt-decode";
import config from "./config"
import Container from '@material-ui/core/Container'
import { LoadingBar } from "react-redux-loading-bar";
import PrivateRoute from "./layout/PrivateRoute"
import { cacheStore, setAuthToken } from "./utils/helpers"
import { setCurrentUser } from "./actions/auth"
import { SnackbarProvider } from 'notistack';
import Notifier from "./components/Notifier"
// pages
import Login from "./pages/Login"
import Dashboard from "./pages/Dashboard"
import AddEditBlog from "./pages/Blog/AddEditBlog"
import AddEditPages from "./pages/Pages/AddEditPages"
import IndexBlog from "./pages/Blog/Index"
import IndexPages from "./pages/Pages/IndexPages"
import Popular from "./pages/Popular"
import Packages from "./pages/Packages"
import FAQ from "./pages/Faq"
import References from "./pages/ReferenceIndex"
import Collection from "./pages/Collection"
import CouponAddEdit from "./components/Packages/CreateCoupon"
import Banners from "./pages/BannerTop"

// Customer & Developer & User Management
import UserDeveloper from "./pages/UserDeveloper"
import UserAgent from "./pages/UserAgent"

 // Check for token
if (cacheStore().getItem(config.tokenKey)) {
  // Set auth token header auth
  setAuthToken(cacheStore().getItem(config.tokenKey));
  // Decode token and get user info and exp
  const payload = jwtDecode(cacheStore().getItem(config.tokenKey));
  // Set user and isAuthenticated
  Store.dispatch(setCurrentUser(payload));
}

function App() {
  return (
    <Provider store={Store}>
      <Router basename="/app" forceRefresh={false}>
        <SnackbarProvider
          autoHideDuration={3000} maxSnack={3} 
          anchorOrigin={{horizontal:"center", vertical:"top"}}>
            <LoadingBar scope="sectionBar" />
            <Notifier />
            <Route exact path="/" component={Login} />
            <Switch>
              <PrivateRoute
                name="Dashboard"
                path="/dashboard"
                component={Dashboard}
              />
              <PrivateRoute
                name="Popular City"
                path="/popular"
                component={Popular}
              />
              <PrivateRoute
                name="Manage Package"
                path="/packages"
                component={Packages}
              />
              <PrivateRoute
                name="Add Coupon"
                path="/coupons/:campaignId?"
                component={CouponAddEdit}
              />
              <PrivateRoute
                name="FAQ"
                path="/faq"
                component={FAQ}
              />
              <PrivateRoute
                name="References"
                path="/references"
                component={References}
              />

              <PrivateRoute
                name="Collections"
                path="/collections"
                component={Collection}
              />
              <PrivateRoute
                name="Banners"
                path="/banners"
                component={Banners}
              />
            </Switch>

            <Switch>
              <PrivateRoute name="Blog" path="/blog/edit/:id" component={AddEditBlog} />
              <PrivateRoute name="New Blog" path="/blog/new" component={AddEditBlog} />
              <PrivateRoute name="Blog" path="/blog" component={IndexBlog} />
            </Switch>

            <Switch>
              <PrivateRoute name="Edit Page" path="/pages/edit/:id" component={AddEditPages} />
              <PrivateRoute name="New Page" path="/pages/new" component={AddEditPages} />
              <PrivateRoute name="Pages" path="/pages" component={IndexPages} />
            </Switch>

            <Switch>
              <PrivateRoute name="User Developer" path="/user/developer" component={UserDeveloper} />
              <PrivateRoute name="User Agent" path="/user/agent" component={UserAgent} />
            </Switch>

        </SnackbarProvider>
      </Router>
    </Provider> 
  )
}

export default App;
