import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useHistory } from "react-router-dom"
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Backdrop from '@material-ui/core/Backdrop'
import Modal from '@material-ui/core/Modal'
import Fade from '@material-ui/core/Fade'
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from "@material-ui/icons/Edit"
import DeleteIcon from "@material-ui/icons/DeleteForeverOutlined"
import StarIcon from "@material-ui/icons/Star"
import MapIcon from "@material-ui/icons/MapOutlined"
import { listPopularCity, removePopular, addPopularCity, updatePopularCity } from "../actions/common"
import { isEmpty, filter, map, kebabCase, lowerCase, orderBy } from "lodash"
import moment from "moment"
import Badge from "@material-ui/core/Badge"
import TextField from '@material-ui/core/TextField'
import { useSnackbar } from "notistack"
import ModalSearchCity from "../components/ModalSearchCity"
import { addPopularValidate } from "../validations/common"

const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(3)
    },
    fixedHeight: {
      
    },
    // modal styling
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalPaper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    backdrop:{
        backgroundColor:'rgba(0, 0, 0, 0.86)'
    }
}));

const PopularCity = (props) => {
    const classes = useStyles();
    const history = useHistory()
    const { enqueueSnackbar } = useSnackbar()
    const [rows, setRows] = useState(null)
    const [loading, setLoading] = useState(false)
    const [modalDelete, setModalDelete] = useState(null)
    const [modalCreate, setModalCreate] = useState(false)
    const [error, setError] = useState()
    const [fields, setFields] = useState({
        name:'',
        city:'',
        radius:'',
        edit:false,
        lng:'',
        lat:''
    })
    const [params, setParams] = useState({
        limit:40,
        page:0
    })

    const handleChange = (ev) => {
        setFields({
          ...fields,
          [ev.target.name]:ev.target.value
        })
    }

    const columns = [
        { 
            field: 'edit', 
            headerName: '#', 
            width: 60,
            renderCell:({row}) => {
                return <IconButton onClick={()=>onModalEdit(row)}>
                    <EditIcon  />
                </IconButton>
            }
        },
        { 
            field: 'name', 
            headerName: 'Name', 
            width: 350,
            renderCell:({row}) => {
                return row.name
            }
        },
        { 
            field: 'radius', 
            headerName: 'Radius', 
            width: 100,
            renderCell:({row}) => {
                return <span>{row?.radius?row.radius: <span style={{color:"#777"}}>5000</span>}</span>
            }
        },
        { 
            field: 'city', 
            headerName: 'City', 
            width:200,
            renderCell:({row}) => {
                return row?.address?.city
            }
        },
        { 
            field: 'latitude', 
            headerName: 'Latitude', 
            width: 100,
            valueFormatter: ({value}) => value.latitude,
        },
        { 
            field: 'longitude', 
            headerName: 'Longitude', 
            width: 100,
            valueFormatter: ({value}) => value.longitude,
        },
        { 
            field: 'createdAt', 
            headerName: 'Published Date', 
            width: 140,
            valueFormatter: ({value}) => moment(value).format("ll"),
        },
        { 
            field: 'updatedAt', 
            headerName: 'Last Updated', 
            width: 140,
            valueFormatter: ({value}) => moment(value).format("ll"),
        },
        { 
            field: 'view', 
            headerName: 'View Map', 
            width: 100,
            renderCell:({row}) => {
                return <IconButton onClick={()=>onView(row)}>
                    <MapIcon />
                </IconButton>
            }
        },
       
        { 
            field: 'remove', 
            headerName: 'Action', 
            width: 100,
            renderCell:({row}) => {
                return <IconButton onClick={()=>onModalDelete(row)}>
                    <DeleteIcon color={'error'} />
                </IconButton>
            }
        },
    ];

    useEffect(() => {
        fetchData()
    }, []);

    const onSave = async(data) => {
        setLoading(true)
        setError(null)
        props.addPopularCity(data)
            .then(res=>{
                setTimeout(()=>fetchData(),500)
                enqueueSnackbar('You just added new Popular city', {variant:'success'})
            })
            .catch(err=>{
                console.log(err)
                enqueueSnackbar('Failed adding new Popular city', {variant:'error'})
            })
            .finally(_=>{
                setTimeout(()=>{
                    setModalCreate(false)
                    setLoading(false)
                },200)
            })
    }

    const onUpdate = async(data) => {
        setLoading(true)
        props.updatePopularCity(fields.edit, data)
            .then(res=>{
                fetchData()
                enqueueSnackbar('You just updated city', {variant:'success'})
            })
            .catch(err=>{
                console.log(err)
                enqueueSnackbar('Failed add a city', {variant:'error'})
            })
            .finally(_=>{
                setFields({
                    ...fields,
                    name:'',
                    edit:'',
                    lat:'',
                    lng:'',
                    radius:''
                })
                setTimeout(()=>{
                    setModalCreate(false)
                    setLoading(false)
                },200)
            })
    }

    const fetchData = () => {
        props.listPopularCity(params)
            .then(res=>{
                let d = res.data.map((item)=>({...item,id:item._id}))
                let a = orderBy(d, ['createdAt'], ['desc'])
                setRows(a)
            })
    }

    const onView = (item) => {
        let hostUrl = process.env.REACT_APP_HOST_URL
        window.open(
            `${hostUrl}nearby/${kebabCase(lowerCase(item.name))}/dijual/${item.longitude}/${item.latitude}/popular`, 
            '_blank')
    }

    const onModalEdit = (item) => {
        setModalCreate(true)
        setFields({
            ...fields,
            edit:item._id,
            lat:item.latitude,
            lng:item.longitude,
            name:item.name,
            radius:item?.radius
        })
    }


    const onModalDelete = (item) => {
        setModalDelete(item)
    }

    const onModalClose = () => {
        setModalDelete(null) 
        setModalCreate(false)  
        setFields({
            ...fields,
            name:'',
            edit:'',
            lat:'',
            lng:'',
            radius:''
        })
    }

    const onDelete = () => {
        setLoading(true)
        props.removePopular(modalDelete._id)
            .then(res=>{
                setModalDelete(null)   
                fetchData()
            })
            .finally(_=>{
                setLoading(false)
            })
    }

    const onPageChange = (param) => {
        
    }

    const onModalCreate = () => {
        setModalCreate(true)
    }

    return (
        <Grid container spacing={3}>
            <Modal 
                className={classes.modal}
                open={modalDelete}
                onClose={onModalClose}
                disableBackdropClick={false}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                    classes:{
                        root:classes.backdrop
                    }
                }}
                disableScrollLock={false}
            >
               <Fade in={modalDelete}>
                    <Paper elevation={3} style={{outlineOffset:0,padding:50}} className={classes.paper}>
                        <Typography style={{marginBottom:10}} variant="h5">Are you sure remove this item?</Typography>
                        {modalDelete && <Typography style={{marginBottom:40}} variant="subtitle1">{modalDelete.title}</Typography>}
                        <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                            <Button disabled={loading} onClick={onDelete} variant="contained" color="primary">
                                {loading ? 'Removing...' : 'Yes, Remove'}
                            </Button>
                            <Button style={{marginLeft:10}} disabled={loading} onClick={onModalClose} variant="outlined" color="primary">
                                Close
                            </Button>
                        </Grid>
                        
                    </Paper>
                </Fade>
            </Modal>

            <Modal 
                className={classes.modal}
                open={modalCreate}
              
                onClose={onModalClose}
                disableBackdropClick={false}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                    classes:{
                        root:classes.backdrop
                    }
                }}
                disableScrollLock={false}
            >
               <Fade in={modalCreate}>
                    <ModalSearchCity lng={fields.lng.toString()} lat={fields.lat.toString()} name={fields.name} edit={fields.edit} radius={fields.radius} onUpdate={onUpdate} onSave={onSave} onClose={onModalClose} />
                </Fade>
            </Modal>
            

            <Grid item sm md lg xl>
                <Paper elevation={3} className={classes.paper}>
                    <Grid container direction="row" justify="flex-start" spacing={2}>
                        <Grid item  >
                            <Typography variant="h5" component="h5">Popular City List</Typography>
                        </Grid>
                        <Grid item sm={6} lg={6}>
                            <Button variant="contained" size="medium" color="primary" onClick={onModalCreate}>
                                Create
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>

                    {rows && !isEmpty(rows) ? <div style={{ width: '100%' }}>
                        <DataGrid style={{
                            height:'auto!important',
                        }} pageSize={40} onPageChange={onPageChange} autoHeight={true} rowHeight={40} rows={rows} columns={columns} />
                    </div> : <div style={{textAlign:"center"}}>
                        <Typography style={{marginTop:30}}>--No Data--</Typography>
                    </div>}
            </Grid>
        </Grid>
        
    );
}

PopularCity.propTypes = {
    auth: PropTypes.object.isRequired,
    blog: PropTypes.object.isRequired
};
  
const mapStateToProps = state => ({
    auth: state.auth,
    blog: state.blog.list
});

export default connect(mapStateToProps, {listPopularCity, removePopular, addPopularCity, updatePopularCity})(PopularCity)