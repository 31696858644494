/* eslint-disable no-use-before-define */
import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
}));

export default function Tagging({id, label, placeholder, options, onChange, fieldLabel, fieldComparator, ...props}) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        freeSolo
        id={id}
        limitTags={10}
        options={options}
        onChange={onChange}
        getOptionLabel={(option) => option}
        renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip variant="default" label={option} {...getTagProps({ index })} />
            ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            placeholder={placeholder}
          />
        )}
        {...props}
      />
    </div>
  );
}
