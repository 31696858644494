import {isEmpty} from "lodash";
import { PACKAGES_LIST, COUPON_LIST } from "../actions/types";

const initialState = {
  list: null,
  coupons: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case PACKAGES_LIST:
      return {
        ...state,
        list: action.payload
      };
    
    case COUPON_LIST:
      return {
        ...state,
        coupons: action.payload
      };
  
  
    default:
      return state;
  }
}
