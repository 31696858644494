import { isEmpty, isNaN } from "lodash"

export const addPopularValidate = (userData) => {
    return new Promise((resolve, reject) => {
        let output = {}
        console.log(parseFloat(userData.lat))
        if( isEmpty(userData.name) ) {
            output.name = "Name is required"
        }

        if( isEmpty(userData.lat) || isNaN(userData.lat) ) {
            output.lat = "latitude is required"
        }

        if( isEmpty(userData.lng) ) {
            output.lng = "longitude is required"
        }

        if( isNaN(parseFloat(userData.lat)) ) {
            output.lat = "latitude must be decimal point"
        }

        if( isNaN(parseFloat(userData.lng)) ) {
            output.lng = "longitude must be decimal point"
        }

        if( parseFloat(userData.lat) < -11 || parseFloat(userData.lat) > 7) {
            output.lat = "Latitude of Indonesia Country is not valid, it must between -10 to 7"
        }

        if( parseFloat(userData.lng) < 95 || parseFloat(userData.lng) > 141 ) {
            output.lng = "Longitude of Indonesia Country is not valid, it must between 96 to 141"
        }

        if ( isEmpty(output) ) {
            resolve(userData)
        } else {
            reject(output)
        }
    })
}