import React, { useState, useEffect } from "react"
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux"
import Paper from "@material-ui/core/Paper"
import Grid from "@material-ui/core/Grid"
import FormLabel from "@material-ui/core/FormLabel"
import IconButton from "@material-ui/core/IconButton"
import MenuItem from "@material-ui/core/MenuItem"
import Chip from "@material-ui/core/Chip"
import Switch from '@material-ui/core/Switch';
import { Add as AddIcon, DeleteOutline as DeleteIcon, Save as SaveIcon, LocalOffer as DiscIcon } from "@material-ui/icons"
import PackageItem from "./PackageItem"
import { isEmpty, has, isBoolean, indexOf, forEach, find, isString, isInteger } from "lodash"
import TextField from '@material-ui/core/TextField';
import AlertDialog from "../AlertDialog"
import { dateTime, currency, translate } from "../../utils/helpers"
import { addPackage, updatePackage, removePackage } from "../../actions/package"


const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(3),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
      textAlign:"center"
    },
    divider:{
      margin:"15px 0px"
    },
    fixedHeight: {
      height: 240,
    },
    // modal styling
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalPaper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    backdrop:{
        backgroundColor:'rgba(0, 0, 0, 0.86)'
    }
}));

const PackageCard = (props) => {
    const classes = useStyles();
    const [items, setItems] = useState([])
    const [alert, setAlert] = useState(false)
    const [id, setId] = useState(props.id)
    const [status, setStatus] = useState(props.status)
    const [title, setTitle] = useState(props.title)
    const [desc, setDesc] = useState(props.desc)
    const [price, setPrice] = useState(props.price)
    const [fields, setFields] = useState([])
    const [period, setPeriod] = useState(props.period)
    const [field, setField] = useState(null)
    const [priceDisc, setPriceDisc] = useState(props.priceDiscount)
    const [disc, setDisc] = useState(props.discount)
    const [totalPrice, setTotalPrice] = useState(props.totalPrice)
    const [startDate, setStartDate] = useState(props.startDate)
    const [endDate, setEndDate] = useState(props.endDate)
    const [showDisc, setShowDisc] = useState(false)
    const dispatch = useDispatch()
    
    useEffect(() => {
        let d = []
        forEach(props.configs, (val,field)=>{
            if(field!="featuredActive") {
                let a = val==="true"?true:(val==="false"?false:val)
                d.push({key:field, value:a})
            }
           
        })

        if( !find(d,(i)=>i.key==="allowSearch") ) {
            d.push({key:"allowSearch", value:false})
        }

        if( !find(d,(i)=>i.key==="viewProfile") ) {
            d.push({key:"viewProfile", value:false})
        }
        setFields(d)
        if( ! isEmpty(props.discount) || props.discount > 0) {
            setShowDisc(true)
        }
    }, []);

    const fieldChange = (e,key) => {
        let ef = fields
        if( isBoolean(e) ) {
           console.log("e", e)
            let idx = ef.findIndex((f)=>f.key==key)
            if( idx > -1 ) {
                ef.splice(idx,1, {key:key, value:e})
            }
        } else {
            if( e.target.value < 0 ) {
                return
            }
            let idx = ef.findIndex((f)=>f.key==key)
            if( idx > -1 ) {
                ef.splice(idx,1, {key:key, value:parseInt(e.target.value)})
            }
        }
       
        setFields((prev)=>[...ef])
    }

    const calculateDisc = (ev) => {
        if( ev.target.value == 0 || isEmpty(ev.target.value) ) {
            return
        }
        let ds = parseInt(ev.target.value)
        setDisc(ds)
        let discountP = (price * ds) / 100
        setPriceDisc(parseInt(discountP))
        setTotalPrice(price-parseInt(discountP))
    }

    const onSave = () => {
        let config = {}
        fields.map(f=>{
            config[f.key] = f.value
        })
        let bodyParams = {
            status:status,
            name:title,
            description:isEmpty(desc)?" ":desc,
            type:"package",
            packagePeriod:period,
            price:parseInt(price),
            configPackage:{
                ...config,
                featuredActive:config.unitActive
            }
        }

        if( period == 'temporary') {
            bodyParams.startDate = startDate
            bodyParams.endDate = endDate
        }

        if( showDisc && disc > 0 ) {
            bodyParams.discount = disc
            bodyParams.totalPrice = totalPrice
            bodyParams.priceDiscount = priceDisc
        }
                
        if( id === 0 ) {
            // create package
            dispatch(addPackage(bodyParams))
        } else {
            // update package
            dispatch(updatePackage(id, bodyParams))
        }

        props.onRemove()
    }

    const onRemoveItem = (field, id) => {
        let field2 = items
        field2.splice(id, 1)
        setItems((prev)=>[...field2])
    }

    const onRemove = () => {
        setAlert(true)
    }

    const okRemove = () => {
        return new Promise((resolve, reject) => {
            dispatch(removePackage(id))
            setAlert(false)
            props.onRemove()
        })
    }

    const handleShowDiscount = () => {
        setShowDisc(!showDisc)
    }

    const alertClose = () => {
        setAlert(false)
    }

    console.log("fields", fields)

    return (
        <Paper elevation={status?2:0} style={{background:status?'#fff':"#f7f7f7"}} className={classes.paper}>
            <AlertDialog 
                title={"Are you sure want to remove?"} 
                desc="This package will be removed permanently" 
                open={alert} 
                handleClose={alertClose} 
                handleOk={okRemove} 
            />

            <Switch
                checked={status}
                onChange={()=>setStatus(!status)}
                name="status"
                color={"primary"}
                inputProps={{ 'aria-label': 'primary checkbox' }}
            />

            <TextField
                name={`title_${props.index}`}
                id={`title_${props.index}`}
                style={{background:'#fff',textAlign:"center"}}
                variant="standard"
                fullWidth={true}
                value={title}
                placeholder="Enter a name"
                inputProps={{
                    style:{textAlign:"left",fontSize:24}
                }}
                onChange={(ev)=>setTitle(ev.target.value)}
            />

            <TextField
                name={`price_${props.index}`}
                id={`price_${props.index}`}
                style={{background:'#fff',textAlign:"left"}}
                variant="standard"
                fullWidth={true}
                margin="normal"
                type="number"
                placeholder="Enter a price"
                value={price}
                inputProps={{
                    style:{textAlign:"left", marginLeft:14, fontSize:34}
                }}
                onChange={(ev)=>setPrice(parseInt(ev.target.value)) }
                InputProps={{
                    startAdornment:"IDR"
                }}
            />

            <TextField
                id="standard-select-period"
                select
                label="Period"
                value={period}
                onChange={(ev)=>{
                    setPeriod(ev.target.value)
                }}
                >
                <MenuItem key={"permanent"} value={"permanent"}>
                    Permanent
                </MenuItem>
                <MenuItem key={"temporary"} value={"temporary"}>
                    Temporary
                </MenuItem>
            </TextField>

            <TextField
                name={`desc_${props.index}`}
                id={`desc_${props.index}`}
                style={{background:'#fff',textAlign:"center",height:60}}
                variant="standard"
                fullWidth={true}
                margin="normal"
                multiline={true}
                rows={3}
                placeholder="Description"
                value={desc.trim()}
                inputProps={{
                    style:{textAlign:"center"}
                }}
                onChange={(ev)=>{
                    setDesc(ev.target.value)
                }}
            />

            {period=='temporary'&&<TextField
                name={`startDate_${props.index}`}
                id={`startDate_${props.index}`}
                style={{background:'#fff',textAlign:"center"}}
                variant="standard"
                fullWidth={true}
                margin="normal"
                type="date"
                defaultValue={dateTime.getDateNow()}
                placeholder="Start date"
                value={startDate}
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    style:{textAlign:"center"}
                }}
                onChange={(ev)=>{
                    setStartDate(ev.target.value)
                }}
                InputProps={{
                    startAdornment:"Start"
                }}
            />}

            {period=='temporary'&&<TextField
                name={`endDate_${props.index}`}
                id={`endDate_${props.index}`}
                style={{background:'#fff',textAlign:"center"}}
                variant="standard"
                fullWidth={true}
                type="date"
                defaultValue={dateTime.getDateNow()}
                margin="normal"
                placeholder="End date"
                value={endDate}
                lebel={"End date"}
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    style:{textAlign:"center"}
                }}
                onChange={(ev)=>{
                    setEndDate(ev.target.value)
                }}
                InputProps={{
                    startAdornment:"End"
                }}
            />}

            <Chip label="Config" size="small" component={"span"} style={{margin:"15px auto", width:80}} />

            {/*<IconButton style={{width:50,margin:'0 auto'}} onClick={(ev)=>addItem()}>
                <AddIcon />
            </IconButton>*/}

            {!isEmpty(fields) && <div style={{textAlign:"center"}}>
            
            {fields.map((field,index)=><Grid key={index} style={{marginTop:10}} spacing={2} container direction="row" justify="center">
                <Grid item lg={5}>
                    <FormLabel style={{lineHeight:'38px'}}>{translate.packages(field.key)}</FormLabel>
                </Grid>
                <Grid item lg={7}>
                    {isBoolean(field.value) ? <Switch
                        checked={field.value}
                        onChange={()=>fieldChange(!field.value,field.key)}
                        name={field.key}
                        color={"primary"}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    /> : 
                    <TextField type={isInteger(field.value) ? 'number':'string'} 
                        onChange={(e)=>fieldChange(e,field.key)} 
                        inputProps={{
                            style:{textAlign:"center"}
                        }} 
                        name={field.key} 
                        value={field.value} 
                    />}
                </Grid>
            </Grid>)}</div>}
           
           <Grid container direction="row" style={{marginTop:14}} spacing={0} justify={"space-between"}>
                <Grid item>
                    <IconButton onClick={onRemove} aria-label="Delete package">
                        <DeleteIcon />
                    </IconButton>
                </Grid>

                <Grid item>
                    <IconButton color="primary" onClick={onSave} aria-label="Save package">
                        <SaveIcon />
                    </IconButton>
                </Grid>
           </Grid>

        </Paper>
    )
}

export default PackageCard