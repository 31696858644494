export default {
    authBasic: {
        usr:"Padar-Backend",
        pwd:"$2b$10$PYVB07qecOup.EszlQsLsO/yf1mxLyP4IqBx/GzibyZnUuEDuYDmq"
    },
    gmApiKey:'AIzaSyBlrTVfLJz4bSRiElc_AU0wxsDDXJJlgD4',
    tokenKey: "huni0admin_jwt",
    lang_id: "en",
    cipher_crypt: "0120xuaqhnk",
    prefixes: {
        merchant: "_q5mct_",
        user: "_q5usr_",
        category: "_q5ctgr_"
    },
    uploadImage:{
        minSize:5000,
        maxSize:6291456,
    }
}