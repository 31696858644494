// Auth
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_AUTH_2FA = "SET_AUTH_2FA"
export const GET_PROFILE = "GET_PROFILE";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const PROFILE_LOADING = "PROFILE_LOADING";
export const PROFILE_NOT_FOUND = "PROFILE_NOT_FOUND";
export const CLEAR_CURRENT_PROFILE = "CLEAR_CURRENT_PROFILE";

// Unit
export const GET_UNIT = "GET_UNIT";

// Blog
export const BLOG_LIST = "BLOG_LIST";
export const BLOG_RELOAD_LIST = "BLOG_RELOAD_LIST";
export const BLOG_RELOAD_PAGINATE = "BLOG_RELOAD_PAGINATE";
export const GET_BLOG = "GET_BLOG";
export const ADD_NEW_BLOG = "ADD_NEW_BLOG";
export const UPDATE_BLOG = "UPDATE_BLOG";
export const REMOVE_BLOG = "REMOVE_BLOG";
export const UPLOAD_BLOG_PHOTO = "UPLOAD_BLOG_PHOTO";

// Blog Category
export const BLOG_CATEGORY_LIST = "BLOG_CATEGORY_LIST";
export const BLOG_CATEGORY_RELOAD_LIST = "BLOG_CATEGORY_RELOAD_LIST";
export const BLOG_CATEGORY_RELOAD_PAGINATE = "BLOG_CATEGORY_RELOAD_PAGINATE";
export const ADD_BLOG_CATEGORY = "ADD_BLOG_CATEGORY";
export const UPDATE_BLOG_CATEGORY = "UPDATE_BLOG_CATEGORY";

// Blog Tags
export const BLOG_TAGS_LIST = "BLOG_TAGS_LIST";
export const BLOG_TAGS_RELOAD_LIST = "BLOG_TAGS_RELOAD_LIST";
export const BLOG_TAGS_RELOAD_PAGINATE = "BLOG_TAGS_RELOAD_PAGINATE";
export const ADD_BLOG_TAGS = "ADD_BLOG_TAGS";

// Pages
export const PAGES_LIST = "PAGES_LIST";
export const PAGE_RELOAD_LIST = "PAGE_RELOAD_LIST";
export const PAGE_RELOAD_PAGINATE = "PAGE_RELOAD_PAGINATE";
export const GET_PAGE = "GET_PAGE";
export const ADD_NEW_PAGE = "ADD_NEW_PAGE";
export const UPDATE_PAGE = "UPDATE_BLOG";
export const REMOVE_PAGE = "REMOVE_PAGE";
export const UPLOAD_PAGE_PHOTO = "UPLOAD_PAGE_PHOTO";

// Gallery
export const UPLOAD_IMAGE_GALLERY = "UPLOAD_GALLERY";
export const LIST_IMAGE_GALLERY = "LIST_IMAGE_GALLERY";
export const UPDATE_IMAGE_GALLERY = "UPDATE_IMAGE_GALLERY";

// Loader
export const LOADER_START = "LOADING_SPINNER_START";
export const LOADER_COMPLETE = "LOADING_SPINNER_COMPLETE";
export const LOADER_ERROR = "LOADING_SPINNER_ERROR";
export const FORM_LOADING_START = "FORM_LOADING_START";
export const FORM_LOADING_FINISH = "FORM_LOADING_FINISH";

// Modal
export const MODAL_SHOW = "Modal/SHOW";
export const MODAL_HIDE = "Modal/HIDE";
export const MODAL_BUTTON_LOADING_START = "Modal/BUTTON_LOADING";
export const MODAL_BUTTON_LOADING_END = "Modal/BUTTON_FINISH";

// Users
export const UPDATE_USER = "UPDATE_USER";
export const COMPLETE_UPDATE_USER = "COMPLETE_UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const COMPLETE_DELETE_USER = "COMPLETE_DELETE_USER";

export const SEARCH_REGIONS = "SEARCH_REGIONS";

// Common
export const ADD_POPULAR_CITY = 'ADD_POPULAR_CITY'
export const UPDATE_POPULAR_CITY = 'UPDATE_POPULAR_CITY'
export const LIST_POPULAR_CITY = 'LIST_POPULAR_CITY'
export const REMOVE_POPULAR_CITY = 'REMOVE_POPULAR_CITY'

// Package
export const PACKAGES_LIST = 'PACKAGES_LIST'
export const ADD_PACKAGE = 'ADD_PACKAGE'
export const UPDATE_PACKAGE = 'UPDATE_PACKAGE'
export const REMOVE_PACKAGE = 'REMOVE_PACKAGE'

// Coupon
export const COUPON_LIST = 'COUPON_LIST'
export const ADD_COUPON = 'ADD_COUPON'
export const UPDATE_COUPON = 'UPDATE_COUPON'
export const REMOVE_COUPON = 'REMOVE_COUPON'

// FAQ
export const FAQ_LIST = 'FAQ_LIST'
export const FAQ_SECTION = 'FAQ_SECTION'
export const ADD_FAQ_SECTION = 'ADD_FAQ_SECTION'
export const REMOVE_FAQ_SECTION = 'REMOVE_FAQ_SECTION'
export const UPDATE_FAQ_SECTION = 'UPDATE_FAQ_SECTION'
export const ADD_FAQ = 'ADD_FAQ'
export const REMOVE_FAQ = 'REMOVE_FAQ'
export const UPDATE_FAQ = 'UPDATE_FAQ'

// Various
export const VARIOUS_LIST = 'VARIOUS_LIST'
export const ADD_VARIOUS = 'ADD_VARIOUS'
export const REMOVE_VARIOUS = 'REMOVE_VARIOUS'
export const UPDATE_VARIOUS = 'UPDATE_VARIOUS'

// Collections
export const COLLECTION_LIST = 'COLLECTION_LIST'
export const ADD_COLLECTION = 'ADD_COLLECTION'
export const GET_COLLECTION = 'GET_COLLECTION'
export const REMOVE_COLLECTION = 'REMOVE_COLLECTION'
export const UPDATE_COLLECTION = 'UPDATE_COLLECTION'

// Customer 
export const CUSTOMER_LIST = 'CUSTOMER_LIST'
export const CUSTOMER_FILTER_LIST = 'CUSTOMER_FILTER_LIST'
export const CUSTOMER_SORT_LIST = 'CUSTOMER_SORT_LIST'
export const CUSTOMER_UPDATE = 'CUSTOMER_UPDATE'
export const CUSTOMER_SET_DEVELOPER = 'CUSTOMER_SET_DEVELOPER'
export const CUSTOMER_SET_LEVEL = 'CUSTOMER_SET_LEVEL'
export const CUSTOMER_SET_BADGES = 'CUSTOMER_SET_BADGES'
export const CUSTOMER_SEARCH_EMAIL = 'CUSTOMER_SEARCH_EMAIL'


export const ACTIONS = {
    REQUEST:'FETCH/REQUEST',
    LOADING:'FETCH/LOADING',
    DONE:'FETCH/SUCCESS',
    FAILED:'FETCH/FAILED',
    CLEAR:'FETCH/CLEAR'
}

export const STATUSES = {
    IDLE:'idle',
    LOADING:'loading',
    SUCCESS:'succeeded',
    FAILED:'failed'
}

export const defineAction = (type, action) => type + action
export const requestAction = () => ACTIONS.REQUEST
export const loadingAction = () => ACTIONS.LOADING
export const doneAction = () => ACTIONS.DONE
export const clearAction = () => ACTIONS.CLEAR
export const failedAction = () => ACTIONS.FAILED

export const reduceAction = {
    status:STATUSES.IDLE,
    message:""
}

// Exceptions
export const GET_ERRORS = "GET_ERRORS";