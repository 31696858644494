
import React, {useState,useEffect,useCallback} from 'react'
import { connect } from "react-redux"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from "react-router-dom"
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select';
import { isEmpty, isNumber, isNil, findIndex } from "lodash"
import { callPublicGet, callPost, callPut, callDelete } from "../api"
import Paper from '@material-ui/core/Paper';
import Backdrop from '@material-ui/core/Backdrop'
import Modal from '@material-ui/core/Modal'
import Fade from '@material-ui/core/Fade'
import AddIcon from '@material-ui/icons/Add'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert'
import { listVarious, addVarious, updateVarious, removeVarious } from "../actions/various"
import { addCollection, updateCollection, removeCollection } from "../actions/collection"
import { STATUSES } from "../actions/types" 
import { Chip } from '@material-ui/core'
import { currency } from "../utils/helpers"
import { useSnackbar } from 'notistack';

const PAGE_ITEMS = [
    {key:'topBanner',label:"Top Banner (All Page)"}
]

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
   
    flexDirection: 'column',
    marginBottom: theme.spacing(3)
  },
  fixedHeight: {
    
  },
  // modal styling
  modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
  },
  modalPaper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
  },
  backdrop:{
      backgroundColor:'rgba(0, 0, 0, 0.86)'
  }
}));


const Banners = (props) => {
    const itemMaster = {
      _id:0,
      title:"",
      slug:"",
      units:[]
    }
    const [items, setItems] = useState([])
    const [error, setError] = useState("")
    const [modalDelete, setModalDelete] = useState(null)
    const [loading, setLoading] = useState(false)
    const { enqueueSnackbar } = useSnackbar()
    const history = useHistory()
    const classes = useStyles()
    const dispatch = useDispatch()
    const { status } = useSelector( ({notification}) => notification )
    const params = {
      limit:100,
      page:0,
      size:"original"
    }

    useEffect(()=>{
      fetchData()
    },[])

    useEffect(()=>{
      if( status == STATUSES.SUCCESS ) {
        //onBack()
      }
    },[status])

    const fetchData = () => {
      callPublicGet("/banners",params)
        .then(res=>{
          setItems(res.data)
        })
        .catch(err=>{
            // console.log(err)
        })
    }

    const onNewCard = () => {
      let newItem = []

      let lastIndex = items.length-1
      if( lastIndex > -1 ) {
        if( isNumber(items[lastIndex]._id) ) {
          setError("Please save last item before add new item!")
          return
        }
      }

      itemMaster._id = (items.length-1) + 1
      itemMaster.sequence = (items.length-1) + 1
      newItem.push(itemMaster)
      setItems(prev=>[...prev, ...newItem])
    }

    const onRemove = () => {
      setLoading(true)
      let title = modalDelete?.title
      callDelete(`admin/collections/${modalDelete._id}`)
        .then(res=>{
          enqueueSnackbar(`Great! collection ${title} has been removed`, {variant:"success"})
          fetchData()
        })
        .catch(err=>{
          enqueueSnackbar("Error deleting collection", {variant:"error"})
        })
        .finally(()=>{
          setModalDelete(null)
          setLoading(false)
        })
      //dispatch(removeVarious(id))
    }

    const onSaveAdd = (id, payload) => {
      let ad = items 
      if( items.length >= PAGE_ITEMS.length ) return false
      ad[id] = {...ad[id], ...payload}
      setItems(prev=>[...ad])
    }

    const onModalDelete = (item) => {
      setModalDelete(item)
    }

    const onModalClose = () => {
      setModalDelete(null)   
    }

    let reItems = items

    return(<Grid 
            container 
            direction="row" 
            justify="flex-start" 
            alignItems={"flex-start"}
          >
            
          <Modal 
              className={classes.modal}
              open={modalDelete}
              onClose={onModalClose}
              disableBackdropClick={false}
              BackdropComponent={Backdrop}
              BackdropProps={{
                  timeout: 500,
                  classes:{
                      root:classes.backdrop
                  }
              }}
              disableScrollLock={false}
          >
              <Fade in={modalDelete}>
                  <Paper elevation={3} style={{padding:50}} className={classes.paper}>
                      <Typography style={{marginBottom:10}} variant="h5">Are you sure remove this item?</Typography>
                      {modalDelete && <Typography style={{marginBottom:40}} variant="subtitle1">{modalDelete.title}</Typography>}
                      <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                          <Button disabled={loading} onClick={onRemove} variant="contained" color="primary">
                              {loading ? 'Removing...' : 'Yes, Remove'}
                          </Button>
                          <Button style={{marginLeft:10}} disabled={loading} onClick={onModalClose} variant="outlined" color="primary">
                              Close
                          </Button>
                      </Grid>
                      
                  </Paper>
              </Fade>
          </Modal>
          
        <Grid item xs={8} lg={8}>
          <Grid 
            container 
            direction="row" 
            justify="flex-start" 
            alignItems={"flex-start"}
          >
            <Grid item xs={12} lg={12}>
                <div style={{marginTop:20,display:"flex",flexDirection:"row"}}>
                    
                    <Button onClick={onNewCard} disabled={items.length >= PAGE_ITEMS.length} style={{marginLeft:20}} startIcon={<AddIcon />} disableElevation variant="contained" size="small" color="primary">
                        New
                    </Button>
                </div>

                {error !== "" && <Alert severity='error' fullWidth={true}>{error}</Alert>}

                <div style={{marginTop:20}}>
                    {!isEmpty(reItems) && <div>
                        {reItems.map((item,index)=><Grid key={index} container 
                          direction="row" 
                          style={{marginBottom:10}}
                          justify="flex-start" 
                          alignItems={"flex-start"}>
                            <Grid item xs={12} lg={12}>
                              <SectionCard 
                                item={item}
                                index={index} 
                                onSave={onSaveAdd} 
                              />
                            </Grid>
                            {/*<Grid item xs={1} lg={1} style={{alignItems:"flex-start", display:"flex"}}>
                              <IconButton onClick={()=>onModalDelete(item)} size="large" color="default" variant="outlined">
                                  <DeleteIcon />
                              </IconButton>
                            </Grid>*/}
                        </Grid>)}
                    </div>}
                </div>
            </Grid>
            
          </Grid>
        </Grid>
    </Grid>)
}


const SectionCard = (props) => {
    const classes = useStyles();
    const baseUrl = process.env.REACT_APP_ENV === "development" ? "http://localhost:3000/collection/": process.env.REACT_APP_ENV === "staging" ? "https://huni.io/collection/" : "https://www.huni.id/collection/"
    const [update, setUpdate] = useState(false)
    const [title, setTitle] = useState("")
    const [subtitle, setSubtitle] = useState("")
    const [page, setPage] = useState("")
    const [buttontext, setButtontext] = useState("")
    const [buttonurl, setButtonurl] = useState("")
    const [collectionId, setCollectionId] = useState()
    const [expanded, setExpanded] = useState(false)
    const { enqueueSnackbar } = useSnackbar()
    const [units, setUnits] = useState([])
    const dispatch = useDispatch()
    const { status, message } = useSelector( ({notification}) => notification )
  
    useEffect(()=>{
      if( props.item ) {
        setTitle(props.item?.text)
        setPage(props.item?.page)
        setButtonurl(props.item?.buttonUrl)
        setButtontext(props.item?.buttonText)
        setSubtitle(props.item?.subtext)

        if( isNumber(props.item?._id) ) {
          setExpanded(true)
        }
      }
    },[props.item])
  
    useEffect(()=>{
      if( status == STATUSES.SUCCESS ) {
         if( status && message && message._id && update ) {
           //
         }
      }
      return () => {
        setUpdate(false)
      }
    },[status])
  
    const onSaveCollections = () => {
      if( isNumber(props.item._id) ) {
        
        callPost(`/admin/banners`, {
          text:title,
          subtext:subtitle,
          buttonText:buttontext,
          buttonUrl:buttonurl,
          page:page
        }).then(res=>{
          setCollectionId(res.data._id)
          enqueueSnackbar("New banner is added succesfully", {variant:"success"})
          props.onSave && props.onSave(props.index, {
            text:title,
            subtext:subtitle,
            buttonText:buttontext,
            buttonUrl:buttonurl,
            page:page
          })
        }).catch(err=>{
          //setUnits((prev)=>[...items])
        })
        
      } else {
        callPut(`/admin/banners/${props.item?._id}`, {
            text:title,
            subtext:subtitle,
            buttonText:buttontext,
            buttonUrl:buttonurl,
            page:page
        }).then(res=>{
          enqueueSnackbar("Banner is updated succesfully", {variant:"success"})
          props.onSave && props.onSave(props.index, {
            text:title,
            subtext:subtitle,
            buttonText:buttontext,
            buttonUrl:buttonurl,
            page:page
          })
        }).catch(err=>{
          //setUnits((prev)=>[...items])
        })
      }
    }
  
  
    const disableSave = () => {
      return !!(title === "" || isEmpty(subtitle) || isEmpty(page) || isEmpty(buttontext) || isEmpty(buttonurl));
    }
  
    return (<>
        <Accordion expanded={expanded} onChange={(e,expand)=>setExpanded(expand)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div style={{flexDirection:"column"}}>
              <Typography variant='h6' color={title!==""?"inherit":"textSecondary"}>
                {title!==""?title:"New Card"}
              </Typography>
              {subtitle && subtitle !== "" && <Typography variant='p' color={"textSecondary"}>{subtitle}</Typography>}
            </div>
          </AccordionSummary>
          <AccordionDetails style={{flexDirection:"column"}}>
              <Grid container direction='row' spacing={2}>
                <Grid item xs={10}>
                    <TextField 
                        fullWidth={true} 
                        style={{marginBottom:10}} 
                        id="title" 
                        inputProps={{maxLength:12}}
                        onChange={(e)=>setTitle(e.target.value)} 
                        label="Heading"
                        placeholder='Enter heading'
                        value={title} 
                    />

                    <TextField 
                        fullWidth={true} 
                        style={{marginBottom:20}} 
                        id="subtitle" 
                        inputProps={{maxLength:20}}
                        onChange={(e)=>setSubtitle(e.target.value)} 
                        label="Sub heading"
                        placeholder='Enter subtitle max 10 chars'
                        value={subtitle} 
                    />

                    <FormControl fullWidth>
                        <InputLabel id="page-select-label">Banner Position</InputLabel>
                        <Select
                            labelId="page-select-label"
                            id="page-select"
                            value={page}
                            placeholder={"Select Banner Position"}
                            label="Banner Position"
                            fullWidth={true}
                            style={{marginBottom:10}}
                            onChange={(e)=>setPage(e.target.value)}
                        >
                            <MenuItem value={""}>-Select Position-</MenuItem>
                            {PAGE_ITEMS.map(item=><MenuItem value={item.key}>{item.label}</MenuItem>)}
                        </Select>
                    </FormControl>
                   
                    <TextField 
                        fullWidth={true} 
                        style={{marginBottom:10}} 
                        id="button-text" 
                        inputProps={{maxLength:12}}
                        onChange={(e)=>setButtontext(e.target.value)} 
                        label="Button Text"
                        placeholder='Enter button max 12 chars'
                        value={buttontext} 
                    />

                    <TextField 
                        fullWidth={true} 
                        style={{marginBottom:10}} 
                        id="button-url" 
                        onChange={(e)=>setButtonurl(e.target.value)} 
                        label="Button URL"
                        helperText={"Please do not include domain https://huni.io or https//huni.id and don't forget to prepend char /"}
                        placeholder='Enter URL'
                        value={buttonurl} 
                    />
                   
                </Grid>
              </Grid>
              <Grid container direction='row' style={{marginTop:20,marginBottom:20}} justify='flex-start' alignItems='flex-start'>
                <Grid item xs={4}>
                  <Button onClick={()=>onSaveCollections()} disableElevation color="primary" disabled={disableSave()} style={{width:120,alignSelf:"flex-start"}} variant="contained">Save</Button>
                </Grid>
              </Grid>
            
          </AccordionDetails>
        </Accordion>
      </>);
  }

  
  
Banners.propTypes = {
    auth: PropTypes.object.isRequired,
};
  
const mapStateToProps = state => ({
    auth: state.auth,
    common: state.common
});
  
export default connect(mapStateToProps, { 
    listVarious, addVarious, updateVarious, removeVarious, 
    addCollection, updateCollection, removeCollection 
})(Banners)