import { combineReducers } from "redux";
import authReducer from "./auth";
import blogReducer from "./blog";
import commonReducer from "./common"
import packageReducer from "./package"
import notificationReducer from "./notification"
import variousReducer from "./various"
import faqReducer from "./faq"
import pageReducer from "./page"
import { loadingBarReducer } from "react-redux-loading-bar";

export default combineReducers({
  auth: authReducer,
  faq:faqReducer,
  blog: blogReducer,
  page:pageReducer,
  common: commonReducer,
  notification:notificationReducer,
  various:variousReducer,
  packages:packageReducer,
  loadingBar: loadingBarReducer
});
