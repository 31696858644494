import _ from "lodash";
import jwtDecode from "jwt-decode";
import rest from "../utils/rest";
import config from "../config";
import { cacheStore } from "../utils/helpers";
import { has } from "lodash"
import { setAuthToken } from "../utils/helpers";
import { GET_ERRORS, SET_CURRENT_USER, CLEAR_CURRENT_PROFILE } from "./types";

// Login - Get User Token
export const loginUser = userData => dispatch => {
  return new Promise((resolve, reject) => {
    let decoded;
    rest()
      .post("/login", userData)
      .then(res => {
          const { data, success } = res.data;
          if( success ) {            
              // Set token to ls
              cacheStore().setItem(config.tokenKey, data.jwt);
              // Set token to Auth header
              setAuthToken(data.jwt);
              // Decode token to get user data
              decoded = jwtDecode(data.jwt);
              // Set current user
              dispatch(setCurrentUser(decoded));

              resolve(res.data);
          } 
         
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response ? err.response : err
        });
        reject(err);
      })
  });
};

// Log user out
export const logoutUser = () => dispatch => {
  // Remove token from sessionStorage
  cacheStore().removeItem(config.tokenKey);
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser());
};

// Set logged in user
export const setCurrentUser = (decoded = {}) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

// Clear profile
export const clearCurrentProfile = () => {
  return {
    type: CLEAR_CURRENT_PROFILE
  };
};
