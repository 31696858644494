import React, { useState, useEffect } from "react"
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import Paper from "@material-ui/core/Paper"
import Grid from "@material-ui/core/Grid"
import { DataGrid } from '@material-ui/data-grid';
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import MenuItem from "@material-ui/core/MenuItem"
import Chip from "@material-ui/core/Chip"
import Switch from '@material-ui/core/Switch';
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import NoRows from "../../components/NoRows"
import { Add as AddIcon, DeleteOutline as DeleteIcon, Save as SaveIcon, LocalOffer as DiscIcon } from "@material-ui/icons"
import { isEmpty, has, find, findIndex, isNil, forEach, startCase, isString, orderBy } from "lodash"
import TextField from '@material-ui/core/TextField';
import AlertDialog from "../AlertDialog"
import Alert from "@material-ui/lab/Alert"
import { dateTime, currency, translate } from "../../utils/helpers"
import { addCoupon, updateCoupon, removeCoupon, listPackages } from "../../actions/package"
import { Divider, Typography } from "@material-ui/core";
import { STATUSES } from "../../actions/types" 
import moment from "moment"
import { useSnackbar } from "notistack"

const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(4),
      display: 'flex',
      overflow: 'auto',
      marginBottom:20,
      flexDirection: 'column',
      textAlign:"center"
    },
    divider:{
      margin:"15px 0px"
    },
    fixedHeight: {
      height: 240,
    },
    // modal styling
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalPaper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    backdrop:{
        backgroundColor:'rgba(0, 0, 0, 0.86)'
    }
}));

const CreateCoupon = (props) => {
    const classes = useStyles();
    const history = useHistory()
    const [items, setItems] = useState([])
    const [alert, setAlert] = useState(false)
    const [tables, setTables] = useState([])
    const [id, setId] = useState(0)
    const [status, setStatus] = useState(true)
    const [code, setCode] = useState()
    const [type, setType] = useState("general")
    const [discType, setDiscType] = useState("fixed")
    const [disc, setDisc] = useState(0)
    const [dateRange, setDateRange] = useState(false)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [showDisc, setShowDisc] = useState(false)
    const [packageIds, setPackageIds] = useState([])
    const [isDefault, setIsDefault] = useState(false)
    const [edit, setEdit] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [errors, setErrors] = useState([])
    const { list } = useSelector( ({packages}) => packages )
    const responseStatus = useSelector( ({notification}) => notification )
    
    const dispatch = useDispatch()
    
    useEffect(() => {
        let d = props.location.state
        if( has(d,'item') ) {
            let item = d.item
            console.log("item", item)
            setId(item._id)
            setStatus(item.status)
            setCode(item.code)
            setType(item.type)
            setDiscType(item.discountType)
            setDisc(item.discountAmount)
            setIsDefault(item?.isDefault)
            setStartDate(item?.startDate?dateTime.convertToDate(item.startDate):null)
            setEndDate(item?.endDate?dateTime.convertToDate(item.endDate):null)
            setEdit(true)
            setPackageIds((prev)=>item.packageId.map(it=>it._id))
        
        }
        fetchPackages()
    }, []);

    useEffect(() => {
        if( ! isEmpty(list) ) {
          let newList = orderBy(list, ['updatedAt'],['desc']);
          setTables(newList.map((item)=>({...item,id:item._id})))
        } 
      }, [list])

    const onSave = () => {
        setErrors([])
        let error  = []
        if( isEmpty(code) ) {
            error.push("Code coupon is required field")
            setErrors((prev)=>[...error])
            return
        }

        if( disc === "" || isNaN(disc) || isNil(disc) ) {
            error.push("Discount amount is required field ")
            setErrors((prev)=>[...error])
            return
        }

        if( disc < 1 && discType == 'percentage' ) {
            error.push("Discount amount is not allowed less than 1%")
            setErrors((prev)=>[...error])
            return
        }

        if( disc < 100 && discType == 'fixed' ) {
            error.push("Discount amount is not allowed less than 100")
            setErrors((prev)=>[...error])
            return
        }

        if( type == "specific" && isEmpty(packageIds) ) {
            error.push("You choose specific package you must select min 1 package")
            setErrors((prev)=>[...error])
            return
        }

        if( !startDate && !endDate ) {
            error.push("Start date and end date is required field")
            setErrors((prev)=>[...error])
            return
        }

        let bodyParams = {
            status:status,
            code:code,
            type:type,
            discountType:discType,
            discountAmount:disc,
            startDate:startDate,
            endDate:endDate,
            packageId:packageIds,
            isDefault:isDefault?1:0
        }
        
        setSubmitting(true)
        if( id === 0 ) {
            // create package
            dispatch(addCoupon(bodyParams))
        } else {
            // update package
            dispatch(updateCoupon(id, bodyParams))
        }

        history.push("/packages?tab=coupon", {
            refetch:true
        })

    }

    const onRemove = () => {
        setAlert(true)
    }

    const okRemove = () => {
        return new Promise((resolve, reject) => {
            dispatch(removeCoupon(id))
            setAlert(false)
            setTimeout(()=>{
                history.push("/packages?tab=coupon", {
                    refetch:true
                })
            },500)
          
        })
    }

    const handleShowDiscount = () => {
        setShowDisc(!showDisc)
    }

    const alertClose = () => {
        setAlert(false)
    }

    const onCancel = () => {
        history.push("/packages?tab=coupon")
    }

    const fetchPackages = () => {
        dispatch(listPackages({}))
    }

    const iterateConfig = (pcks) => {
        let items = []
        forEach(pcks, (value, field) => {
          items.push({key:field,value:value})
        })
        return items
    }
    
    const onSelection = (params) => {
        console.log(params)
        let data = packageIds
        if( params.isSelected ) {
            let b = findIndex(packageIds,(o)=>o==params.data.id)
            if( b === -1 ) {
                data.push(params.data.id)
            } 
        } else {
            let b = findIndex(packageIds,(o)=>o==params.data.id)
            console.log("b", b);
            if( b > -1 ) {
                data.splice(b,1)
            }
        }

        setPackageIds((prev)=>[...data])
    }

    const packagesColumn = () => {
        return [
           
            { 
                field: 'name', 
                headerName: 'Name', 
                width: 120,
                renderCell:({row}) => {
                    return <div>
                      {row.name}
                  </div>
                }
            },
            { 
                field: 'price', 
                headerName: 'Price', 
                width: 120,
                renderCell:({row}) => {
                  return currency.digit(currency.parse(row.price))
                }
            },
            { 
                field: 'packagePeriod', 
                headerName: 'Type', 
                width: 100,
                renderCell:({row}) => {
                  return row.packagePeriod
                }
            },
            {
                field: 'description', 
                headerName: 'Description', 
                width: 200,
                valueFormatter: ({value}) => value.description,
            },
            { 
              field: 'config', 
              headerName: 'Config', 
              width: 375,
              renderCell:({row}) => {
                  return <div>
                    {iterateConfig(row.configPackage).map(item=><span>
                        {translate.packages(item.key)} : {item.value}&nbsp;|&nbsp;
                    </span>)}
                </div>
              }
            },
            {
                field: 'updatedAt', 
                headerName: 'Last Updated', 
                width: 140,
                valueFormatter: ({value}) => moment(value).format("ll"),
            }
        ];
    }

    return (
        <div>
            <AlertDialog 
                title={"Are you sure want to remove?"} 
                desc="This coupon will be removed permanently" 
                open={alert} 
                handleClose={alertClose} 
                handleOk={okRemove} 
            />
            <Paper elevation={2} className={classes.paper}>
                <Grid container direction="row" spacing={2} justify="space-between">
                    <Grid item>
                        <Typography variant="h6" component="span">Coupon Code : </Typography>
                        {code && <Chip variant="outlined" color="primary" label={code} size="medium" />}
                        {status===false && <Chip color="default" label="Inactive" size="medium" />}
                    </Grid>
                    <Grid item>
                        <FormControlLabel control={<Checkbox checked={isDefault} name="isDefault" onChange={()=>setIsDefault(!isDefault)} />} label="Make Default" />
                        {edit && <FormControlLabel control={<Switch
                            checked={status}
                            onChange={()=>setStatus(!status)}
                            name="status"
                            color={"primary"}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />}label={status?"Active":"Inactive"} />}
                    </Grid>
                </Grid>
               
                <Divider style={{marginTop:20,marginBottom:20}} />

                <TextField
                    name={`code_${props.index}`}
                    id={`code_${props.index}`}
                    style={{background:'#fff',textAlign:"center"}}
                    variant="standard"
                    fullWidth={true}
                    value={code}
                    label="Coupon Code"
                    placeholder="HUNI20OFF"
                    inputProps={{
                        style:{textAlign:"left",fontSize:24}
                    }}
                    onChange={(ev)=>setCode(ev.target.value)}
                />

                <Grid container direction="row" spacing={2} justify="space-between">
                    <Grid item sm={4}>
                        <TextField
                            id="select-disc-type"
                            select
                            fullWidth={true}
                            label="Discount Type"
                            value={discType}
                            variant="standard"
                            style={{
                                marginTop:15,
                                alignItems:"left"    
                            }}
                            onChange={(ev)=>{
                                setDiscType(ev.target.value)
                            }}
                            SelectProps={{
                                style:{alignItems:"left",textAlign:"left"}
                            }}
                           
                            >
                            <MenuItem key={"percentage"} value={"percentage"}>
                                Percentage
                            </MenuItem>
                            <MenuItem key={"fixed"} value={"fixed"}>
                                Nominal
                            </MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item sm={8}>
                        <TextField
                            name={`disc_${props.index}`}
                            id={`disc_${props.index}`}
                            style={{background:'#fff',textAlign:"left"}}
                            variant="standard"
                            fullWidth={true}
                            margin="normal"
                            type="number"
                            label="Disc. Amount"
                            placeholder={discType=="fixed"?"10000":"10"}
                            value={disc}
                            inputProps={{
                                style:{textAlign:"left", marginLeft:14}
                            }}
                            onChange={(ev)=>setDisc(parseInt(ev.target.value)) }
                            InputProps={{
                                startAdornment:discType=="fixed"?"IDR":"",
                                endAdornment:discType=="percentage"?"%":"",
                            }}
                        />

                    </Grid>
                </Grid>
            </Paper>

            <Paper elevation={2} className={classes.paper}>
                <Grid container direction="row" style={{}} spacing={2} justify="space-between">
                    <Grid item>
                        <Typography variant="h6">Packages</Typography>
                    </Grid>
                    <Grid item>
                      
                    </Grid>
                </Grid>
                <Divider style={{marginTop:20,marginBottom:20}} />

                <Grid container direction="row" spacing={2} justify="space-between">
                    <Grid item sm={3}>
                        <TextField
                            id="type-package"
                            select
                            label="Type"
                            value={type}
                            fullWidth={true}
                            onChange={(ev)=>{
                                setType(ev.target.value)
                            }}
                            SelectProps={{
                                style:{alignItems:"left",textAlign:"left"}
                            }}
                            >
                            <MenuItem key={"general"} value={"general"}>
                                General (Apply All)
                            </MenuItem>
                            <MenuItem key={"specific"} value={"specific"}>
                                Specific Package
                            </MenuItem>
                        </TextField>
                    </Grid>

                    {type=="specific" && <Grid item sm={12}>
                        <DataGrid checkboxSelection  selectionModel={packageIds} onRowSelected={onSelection} style={{
                            height:'60px!important',
                        }} pageSize={40} components={{
                        NoRowsOverlay: NoRows,
                        }} autoHeight={true} rows={tables} columns={packagesColumn()} />
                    </Grid>}
                </Grid>
            </Paper>
                
            <Paper elevation={2} className={classes.paper}>
                <Grid container direction="row" spacing={2} alignItems="flex-start" justify="flex-start">
                    <Grid item>
                        <Typography variant="h6">Date Validation</Typography>
                    </Grid>
                    <Grid item>
                        <Switch
                            checked={dateRange}
                            onChange={()=>setDateRange(!dateRange)}
                            name="status"
                            color={"secondary"}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </Grid>
                </Grid>

                <Divider style={{marginTop:20,marginBottom:20}} />

               <Grid container direction="row" style={{}} spacing={2} justify="flex-start">
                    <Grid item sm={3}>
                        <TextField
                            name={`startDate_${props.index}`}
                            id={`startDate_${props.index}`}
                            style={{background:'#fff',textAlign:"center"}}
                            variant="standard"
                            fullWidth={true}
                            margin="normal"
                            type="date"
                            placeholder="Start date"
                            value={startDate}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                style:{textAlign:"center"}
                            }}
                            onChange={(ev)=>{
                                setStartDate(ev.target.value)
                            }}
                            InputProps={{
                                startAdornment:"Start"
                            }}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <TextField
                            name={`endDate_${props.index}`}
                            id={`endDate_${props.index}`}
                            style={{background:'#fff',textAlign:"center"}}
                            variant="standard"
                            fullWidth={true}
                            type="date"
                            defaultValue={dateTime.getDateNow()}
                            margin="normal"
                            placeholder="End date"
                            value={endDate}
                            lebel={"End date"}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                style:{textAlign:"center"}
                            }}
                            onChange={(ev)=>{
                                setEndDate(ev.target.value)
                            }}
                            InputProps={{
                                startAdornment:"End"
                            }}
                        />
                    </Grid>
                </Grid>

                <Divider style={{marginTop:20,marginBottom:20}} />

                {!isEmpty(errors) && errors.map(error=><Alert severity="error">
                    {error}
                </Alert>)}
            
                <Grid container direction="row" style={{marginTop:34}} spacing={0} justify={"space-between"}>

                    <Grid item>
                        <Button startIcon={<SaveIcon />} disableElevation variant="contained" color="primary" onClick={onSave} aria-label="Save Coupon">
                           Save
                        </Button>
                        <Button disableElevation variant="contained" style={{marginLeft:10}} color="default" onClick={onCancel} aria-label="Save Coupon">
                            Cancel
                        </Button>
                    </Grid>

                    <Grid item>
                        <IconButton onClick={onRemove} color="error" aria-label="Delete Coupon">
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                    
                </Grid>
            </Paper>
        </div>
    )
}

export default CreateCoupon