import {isEmpty} from "lodash";
import { ADD_VARIOUS, VARIOUS_LIST, UPDATE_VARIOUS, REMOVE_VARIOUS } from "../actions/types";

const initialState = {
  list: [],
  section:null,
  single: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case VARIOUS_LIST:
        return {
            ...state,
            list: action.payload
        };
    default:
      return state;
  }
}
