import {isEmpty, has, isArray, forEach } from "lodash";
import { callGet, callPost, callPut, callDelete, makeRequestDispatch, forkRequestDispatch, singleRequestDispatch } from "../api"
import { COLLECTION_LIST, ADD_COLLECTION, UPDATE_COLLECTION, GET_COLLECTION, REMOVE_COLLECTION } from "../actions/types";

// List collection
export const listVarious = (params) => dispatch => {
  return singleRequestDispatch(
    callGet('/collections', params),
    dispatch,
    COLLECTION_LIST
  )
}

// Add new collection
export const addCollection = (bodyParams) => dispatch => {
  return forkRequestDispatch(
    callPost('/admin/collections', bodyParams),
    dispatch,
    ADD_COLLECTION
  )
}

// Update collection
export const updateCollection = (id, bodyParams) => dispatch => {
  return forkRequestDispatch(
    callPut('/admin/collections/'+id, bodyParams),
    dispatch,
    UPDATE_COLLECTION
  )
}

// Delete collection
export const removeCollection = (id) => dispatch => {
  return singleRequestDispatch(
    callDelete('/admin/collections/'+id),
    dispatch,
    REMOVE_COLLECTION
  )
}
