import {isEmpty} from "lodash";
import { SEARCH_REGIONS, CUSTOMER_SEARCH_EMAIL } from "../actions/types";

const initialState = {
  user:null,
  regions: [],
  single: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
   
    case SEARCH_REGIONS:
      return {
        ...state,
        regions: action.payload
      };
    case CUSTOMER_SEARCH_EMAIL:
      return {
        ...state,
        user: action.payload
      };
    default:
      return state;
  }
}
