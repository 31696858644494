import React, { useState, useEffect } from "react"
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from "@material-ui/icons/DeleteForever"

const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
      textAlign:"center"
    },
    divider:{
      margin:"15px 0px"
    },
    fixedHeight: {
      height: 240,
    },
    // modal styling
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalPaper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    backdrop:{
        backgroundColor:'rgba(0, 0, 0, 0.86)'
    }
}));

const PackageItem = (props) => {
    const classes = useStyles();
    const [field, setField] = useState(props.item)

    const onChangeField = (ev) => {
        setField(ev.target.value)
    }
    
    const onBlurItem = (ev) => {
        props.updateItem(field, props.id)
    }

    const onRemoveItem = (ev) => {
        props.removeItem(field, props.id)
    }
    
    return (
        <TextField
            name={`item_${props.index}${props.id}`}
            id={`item_${props.index}${props.id}`}
            style={{background:'#fff',textAlign:"center"}}
            placeholder="Item name"
            variant="standard"
            fullWidth={true}
            value={field}
            inputProps={{
                style:{textAlign:"center"}
            }}
            InputProps={{
                endAdornment:<DeleteIcon title="Remove item" style={{zIndex:199, color:"#ccc",cursor:"pointer"}} onClick={onRemoveItem} />
            }}
            margin={"dense"}
            onChange={(ev)=>onChangeField(ev)}
            onBlur={(ev)=>onBlurItem(ev)}
        />
    )
}

export default PackageItem