import React, {useState,useEffect} from 'react';
import { connect } from "react-redux";
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { DataGrid } from '@material-ui/data-grid';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Backdrop from '@material-ui/core/Backdrop'
import Modal from '@material-ui/core/Modal'
import Fade from '@material-ui/core/Fade'
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CheckButton from '@material-ui/icons/Check';
import CloseButton from '@material-ui/icons/Close';
import DeleteIcon from "@material-ui/icons/Delete"
import EditIcon from "@material-ui/icons/Edit"
import moment from 'moment';
import { isEmpty, isBoolean, find, merge, findIndex, unset, orderBy, forEach } from "lodash"
import PackageCard from "./PackageCard"
import NoRows from "../../components/NoRows"
import { listPackages } from "../../actions/package"
import { translate, currency } from "../../utils/helpers"
import { STATUSES } from "../../actions/types"

const useStyles = makeStyles((theme) => ({
  root:{
    "&>div":{
      height:"300px!important"
    }
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    textAlign:"center"
  },
  divider:{
    margin:"15px 0px"
  },
  fixedHeight: {
    height: 240,
  },
  // modal styling
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
  },
  backdrop:{
      backgroundColor:'rgba(0, 0, 0, 0.86)'
  },
  listItem:{
    paddingLeft:0
  },
  listItemIcon:{
    minWidth:36
  },
  textField:{},
  margin:{}
}));

const PackageAgent = (props) => {
  const classes = useStyles();
  const itemMaster = {
    _id:0,
    status:true,
    name:"Package 1",
    description:"Bronze 1",
    type:"package",
    packagePeriod:"permanent",
    price:0,
    configPackage:{
      totalUnit: 1,
      unitActive: 1,
      totalFeatured:1,
      featuredActive:30
    },
    priceDiscount: 0,
    totalPrice: 0,
    discount: 0
  }

  const [tables, setTables] = useState([])
  const dispatch = useDispatch()
  const [fetched, setFetched] = useState(false)
  const [modal, setModal] = useState({})
  const { list } = useSelector( ({packages}) => packages )
  const { status, message } = useSelector(({notification}) => notification)

  const columns = [
    { 
        field: 'edit', 
        headerName: '#', 
        width: 70,
        renderCell:({row}) => {
            return <IconButton onClick={()=>onModalEdit(row)}>
                <EditIcon  />
            </IconButton>
        }
    },
    { 
        field: 'name', 
        headerName: 'Name', 
        width: 120,
        renderCell:({row}) => {
            return <div>
              {row.name}
          </div>
        }
    },
    { 
        field: 'price', 
        headerName: 'Price', 
        width: 120,
        renderCell:({row}) => {
          return currency.digit(currency.parse(row.price))
        }
    },
    { 
        field: 'packagePeriod', 
        headerName: 'Type', 
        width: 100,
        renderCell:({row}) => {
          return row.packagePeriod
        }
    },
   
    { 
      field: 'config', 
      headerName: 'Config', 
      width: 575,
      renderCell:({row}) => {
          return <div>
            {iterateConfig(row.configPackage).map(item=><span>
                {translate.packages(item.key)} : {isBoolean(item.value) ? item.value ? <CheckButton style={{marginTop:5}} />: <CloseButton style={{marginTop:5}} /> : item.value} &nbsp;|&nbsp;
            </span>)}
        </div>
      }
    },
    {
        field: 'updatedAt', 
        headerName: 'Last Updated', 
        width: 140,
        valueFormatter: ({value}) => moment(value).format("ll"),
    }
  ];

  useEffect(() => {
    if( status == STATUSES.SUCCESS ) {
      fetchData()
    }
  }, [status]);

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if( ! isEmpty(list) ) {
      let newList = orderBy(list, ['price'],['asc']);
      setTables(newList.map((item)=>({...item,id:item._id})))
      setFetched(true)
    } 
  }, [list])

  const fetchData = () => {
    dispatch(listPackages({}))
  }

  const iterateConfig = (pcks) => {
    let items = []
    forEach(pcks, (value, field) => {
      items.push({key:field,value:value})
    })
    return items
  }

  const onModalEdit = (item) => {
    let em = item
    em = merge(itemMaster.configPackage, item.configPackage)
    item.configPackage = em
    setModal(item)
  }

  const onModalClose = () => {
    setModal({})
  }

  const onAdd = () => setModal(itemMaster)

  const onRemove = () => {
    setModal({})
    fetchData()
  }

  const onPageChange = () => {

  }

  return (<div className={classes.root}>
        <Button color="primary" 
          style={{marginBottom:30}} 
          variant="contained" 
          onClick={()=>onAdd()} 
          size="medium">Add Package</Button>

        <Modal 
            className={classes.modal}
            open={!isEmpty(modal)?true:false}
            onClose={onModalClose}
            disableBackdropClick={false}
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
                classes:{
                    root:classes.backdrop
                }
            }}
            disableScrollLock={false}
        >
            <Fade in={!isEmpty(modal)?true:false}>
                <PackageCard 
                    configs={modal?.configPackage} 
                    id={modal?._id}
                    status={modal?.status}
                    title={modal?.name} 
                    desc={modal?.description} 
                    type={modal?.type} 
                    period={modal.packagePeriod}
                    price={modal?.price} 
                    priceDiscount={modal?.priceDiscount}
                    discount={modal?.discount}
                    totalPrice={modal?.totalPrice}
                    startDate={modal?.startDate}
                    endDate={modal?.endDate}
                    onRemove={onRemove}
                />
            </Fade>
        </Modal>

        <DataGrid style={{
            height:'270px!important',
        }} pageSize={40} onPageChange={onPageChange} components={{
          NoRowsOverlay: NoRows,
        }} autoHeight={false} disableVirtualizatio={true} rows={tables} columns={columns} />
  </div>
  )
}

PackageAgent.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  common: state.common
});

export default connect(mapStateToProps, { })(PackageAgent)