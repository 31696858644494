import {isEmpty, has, isArray, forEach } from "lodash";
import rest, {restUpload} from "../utils/rest";
import { GET_ERRORS, PAGES_LIST, ADD_NEW_PAGE, GET_PAGE, UPDATE_PAGE, REMOVE_PAGE } from "./types";

// Page list
export const listPages = userData => dispatch => {
    return new Promise((resolve, reject) => {
      rest()
        .get("/pages", {params:userData})
        .then(res => {
            const { success } = res.data;
            if( success ) {            
                // Set token to ls
                dispatch({
                    type:PAGES_LIST,
                    payload:res.data.data
                });
                resolve(res.data.data);
            } 
            resolve(null);
        })
        .catch(err => {
          dispatch({
            type: GET_ERRORS,
            payload: err.response ? err.response : err
          });
          reject(err);
        })
    });
}


// Get pages by slug
export const getPageBySlug = slug => dispatch => {
  return new Promise((resolve, reject) => {
    rest()
      .get("/pages/slug" + slug)
      .then(res => {
          const { success } = res.data;
          if( success ) {            
              // Set token to ls
              dispatch({
                  type:GET_PAGE,
                  payload:res.data.data
              });
              resolve(res.data.data);
          } 
          resolve(null);
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response ? err.response : err
        });
        reject(err);
      })
  });
}

// Category list
export const addNewPage = userData => dispatch => {
  return new Promise((resolve, reject) => {
    rest()
      .post("/admin/page", userData)
      .then(res => {
          const { success } = res.data;
          if( success ) {            
              // Set token to ls
              dispatch({
                  type:ADD_NEW_PAGE,
                  payload:res.data.data
              });
              resolve(res.data.data);
          } 
          resolve(null);
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response ? err.response : err
        });
        reject(err);
      })
  });
}


// Category list
export const updatePage = (id, userData) => dispatch => {
  return new Promise((resolve, reject) => {
    rest()
      .put("/admin/page/" + id, userData)
      .then(res => {
          const { success } = res.data;
          if( success ) {            
              // Set token to ls
              dispatch({
                  type:UPDATE_PAGE,
                  payload:res.data.data
              });
              resolve(res.data.data);
          } 
          resolve(null);
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response ? err.response : err
        });
        reject(err);
      })
  });
}


// Remove
export const removePage = (id) => dispatch => {
  return new Promise((resolve, reject) => {
    rest()
      .delete("/admin/page/" + id)
      .then(res => {
          const { success } = res.data;
          if( success ) {            
              // Set token to ls
              dispatch({
                  type:REMOVE_PAGE,
                  payload:res.data.data
              });
              resolve(res.data.data);
          } 
          resolve(null);
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response ? err.response : err
        });
        reject(err);
      })
  });
}

  