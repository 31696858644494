import {isEmpty, has, isArray, forEach } from "lodash";
import rest, {restUpload} from "../utils/rest";
import config from "../config";
import { cacheStore } from "../utils/helpers";
import { setAuthToken } from "../utils/helpers";
import { GET_ERRORS, BLOG_LIST, BLOG_CATEGORY_LIST, ADD_NEW_BLOG, ADD_BLOG_CATEGORY, UPDATE_BLOG, REMOVE_BLOG, UPLOAD_BLOG_PHOTO, GET_BLOG, BLOG_TAGS_LIST, ADD_BLOG_TAGS, UPDATE_BLOG_CATEGORY } from "./types";

// Blog list
export const listBlog = userData => dispatch => {
    return new Promise((resolve, reject) => {
      rest()
        .get("/article", {params:userData})
        .then(res => {
            const { success } = res.data;
            if( success ) {            
                // Set token to ls
                dispatch({
                    type:BLOG_LIST,
                    payload:res.data.data
                });
                resolve(res.data.data);
            } 
            resolve(null);
        })
        .catch(err => {
          dispatch({
            type: GET_ERRORS,
            payload: err.response ? err.response : err
          });
          reject(err);
        })
    });
}


// Category list
export const getBlogBySlug = slug => dispatch => {
  return new Promise((resolve, reject) => {
    rest()
      .get("/article/" + slug)
      .then(res => {
          const { success } = res.data;
          if( success ) {            
              // Set token to ls
              dispatch({
                  type:GET_BLOG,
                  payload:res.data.data
              });
              resolve(res.data.data);
          } 
          resolve(null);
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response ? err.response : err
        });
        reject(err);
      })
  });
}

// Category list
export const addBlog = userData => dispatch => {
  return new Promise((resolve, reject) => {
    rest()
      .post("/admin/article", userData)
      .then(res => {
          const { success } = res.data;
          if( success ) {            
              // Set token to ls
              dispatch({
                  type:ADD_NEW_BLOG,
                  payload:res.data.data
              });
              resolve(res.data.data);
          } 
          resolve(null);
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response ? err.response : err
        });
        reject(err);
      })
  });
}


// Category list
export const updateBlog = (id, userData) => dispatch => {
  return new Promise((resolve, reject) => {
    rest()
      .put("/admin/article/" + id, userData)
      .then(res => {
          const { success } = res.data;
          if( success ) {            
              // Set token to ls
              dispatch({
                  type:UPDATE_BLOG,
                  payload:res.data.data
              });
              resolve(res.data.data);
          } 
          resolve(null);
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response ? err.response : err
        });
        reject(err);
      })
  });
}


// Remove
export const removeBlog = (id) => dispatch => {
  return new Promise((resolve, reject) => {
    rest()
      .delete("/admin/article/" + id)
      .then(res => {
          const { success } = res.data;
          if( success ) {            
              // Set token to ls
              dispatch({
                  type:REMOVE_BLOG,
                  payload:res.data.data
              });
              resolve(res.data.data);
          } 
          resolve(null);
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response ? err.response : err
        });
        reject(err);
      })
  });
}
  
  

// Category list
export const listCategory = userData => dispatch => {
  return new Promise((resolve, reject) => {
    rest()
      .get("/category", {params:userData})
      .then(res => {
          const { success } = res.data;
          if( success ) {            
              // Set token to ls
              dispatch({
                  type:BLOG_CATEGORY_LIST,
                  payload:res.data.data
              });
              resolve(res.data.data);
          } 
          resolve(null);
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response ? err.response : err
        });
        reject(err);
      })
  });
}


// Tags list
export const listTags = userData => dispatch => {
  return new Promise((resolve, reject) => {
    rest()
      .get("/tag", {params:userData})
      .then(res => {
          const { success } = res.data;
          if( success ) {            
              // Set token to ls
              dispatch({
                  type:BLOG_TAGS_LIST,
                  payload:res.data.data
              });
              resolve(res.data.data);
          } 
          resolve(null);
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response ? err.response : err
        });
        reject(err);
      })
  });
}


// Category list
export const addCategory = userData => dispatch => {
  return new Promise((resolve, reject) => {
    rest()
      .post("/admin/category", userData)
      .then(res => {
          const { success } = res.data;
          if( success ) {            
              // Set token to ls
              dispatch({
                  type:ADD_BLOG_CATEGORY,
                  payload:res.data.data
              });
              resolve(res.data.data);
          } 
          resolve(null);
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response ? err.response : err
        });
        reject(err);
      })
  });
}


// Update
export const updateCategory = (id, userData) => dispatch => {
  return new Promise((resolve, reject) => {
    rest()
      .put("/admin/category/" + id, userData)
      .then(res => {
          const { success } = res.data;
          if( success ) {            
              // Set token to ls
              dispatch({
                  type:UPDATE_BLOG_CATEGORY,
                  payload:res.data.data
              });
              resolve(res.data.data);
          } 
          resolve(null);
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response ? err.response : err
        });
        reject(err);
      })
  });
}


export const uploadImages = (id, images) => dispatch => {
  return new Promise((resolve, reject) => {
      let form = new FormData()
      form.append('id', id)
      if( isArray(images) ) {
        forEach(images, (file) => form.append('image', file) )
      } else {
        form.append('image', images)
      }
    
      restUpload()
          .post('/admin/article/photo', form)
          .then(res=>{
              const { success } = res.data;
              if( success) {
                dispatch({
                  type:UPLOAD_BLOG_PHOTO
                })
                resolve(success)
              }
          })
          .catch(err=>{
              dispatch({
                  type:GET_ERRORS,
                  payload:err.response
              })
              reject(err.response)
          })
  })
  
}
  
  