/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import { searchRegions } from "../actions/common"
import { useDispatch, useSelector } from "react-redux"
import { capitalize } from "lodash"
import { Place } from "@material-ui/icons"

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
}));

const TagRegion = ({
    id, label, placeholder, onChange, fieldLabel, fieldComparator, multiple=true, variant="outlined", ...props
}) => {
  const classes = useStyles()
  const regions = useSelector(state => state.common.regions)
  const dispatch = useDispatch()
  const [key, setKey] = useState('')

  const onSearch = (ev) => {
    if( ev ) {
      setKey(ev.target.value)
      dispatch(searchRegions(ev.target.value))
    }
  }

  return(
    <Autocomplete
        multiple={multiple}
        fullWidth={true}
        id={id}
        options={regions}
        onChange={(e,opt,reason) => { 
          onChange(opt)
        }}
        renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip variant="default" color="secondary" label={option.name} {...getTagProps({ index })} />
            ))
        }
        filterSelectedOptions
        onInputChange={onSearch}
        getOptionLabel={(option) => option.name}
        loadingText={"Searching..."}
        renderOption={(option) => {
            return(<div>
                <div>
                    <Place color="disabled" style={{ fontSize: 17, marginTop:3 }} /> {option.name} <br />
                </div>
                <div style={{marginLeft:22,display:"block",fontSize:14,color:"#777"}}>                   {capitalize(option.type)}
                </div>
            </div>)
        }}
        renderInput={(params) => (
            <TextField
                {...params}
                variant={variant}
                value={key}
                label={label}
                placeholder={placeholder}
            />
        )}
        {...props}
    />
  )
}

export default TagRegion