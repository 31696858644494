import {isEmpty, has, isArray, forEach } from "lodash";
import { callGet, callPost, callPut, callDelete, makeRequestDispatch, singleRequestDispatch } from "../api"
import { FAQ_LIST, ADD_FAQ, ADD_FAQ_SECTION, UPDATE_FAQ, UPDATE_FAQ_SECTION, REMOVE_FAQ, REMOVE_FAQ_SECTION } from "../actions/types";

// List Package
export const listFaq = () => dispatch => {
  return singleRequestDispatch(
    callGet('/faq-section'),
    dispatch,
    FAQ_LIST
  )
}

// Add new Package
export const addFaqSection = (bodyParams) => dispatch => {
  return makeRequestDispatch(
    callPost('/admin/faq-section', bodyParams),
    dispatch,
    ADD_FAQ_SECTION
  )
}

// Update Package
export const updateFaqSection = (id, bodyParams) => dispatch => {
  return makeRequestDispatch(
    callPut('/admin/faq-section/'+id, bodyParams),
    dispatch,
    UPDATE_FAQ_SECTION
  )
}

// Add FAQ
export const addFaq = (bodyParams) => dispatch => {
  return makeRequestDispatch(
    callPost('/admin/faq', bodyParams),
    dispatch,
    ADD_FAQ
  )
}

// Update FAQ
export const updateFaq = (id, bodyParams) => dispatch => {
  return makeRequestDispatch(
    callPut('/admin/faq/'+ id, bodyParams),
    dispatch,
    UPDATE_FAQ
  )
}

// Delete FAQ
export const removeFaq = (id) => dispatch => {
  return singleRequestDispatch(
    callDelete('/admin/faq/'+id),
    dispatch,
    REMOVE_FAQ
  )
}
