import React, {useState,useEffect} from 'react'
import { connect } from "react-redux"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import { makeStyles } from '@material-ui/core/styles'
import ReactMarkdown from "react-markdown"
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import { isEmpty, isNumber } from "lodash"
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import Up from '@material-ui/icons/ArrowUpwardOutlined'
import Down from '@material-ui/icons/ArrowDownwardOutlined'
import AddIcon from '@material-ui/icons/Add'
import ImageIcon from '@material-ui/icons/PermMedia'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import { CircularProgress } from "@material-ui/core"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Alert from '@material-ui/lab/Alert'
import { listFaq, addFaqSection, updateFaqSection, updateFaq, addFaq, removeFaq } from "../actions/faq"
import { STATUSES } from "../actions/types" 
import remarkGfm from 'remark-gfm'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@material-ui/core"
import MediaGallery from "../components/MediaGallery"

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    textAlign:"center"
  },
  divider:{
    margin:"15px 0px"
  },
  fixedHeight: {
    height: 240,
  },
  // modal styling
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  backdrop:{
    backgroundColor:'rgba(0, 0, 0, 0.86)'
  },
  listItem:{
    paddingLeft:0
  },
  listItemIcon:{
    minWidth:36
  },
  textField:{},
  margin:{},
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

Array.prototype.next = function() {
  return this[++this.current];
};
Array.prototype.prev = function() {
  return this[--this.current];
};
Array.prototype.current = 0;

const FAQ = (props) => {
  const classes = useStyles();
  const itemMaster = {
    _id:-1,
    status:true,
    sequence:0,
    title:"New section",
    faq:[],
    slug:""
  }
  const dispatch = useDispatch()
  const [edit, setEdit] = useState(false)
  const [add, setAdd] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [item, setItem] = useState({})
  const [tables, setTables] = useState([])
  const [fetched, setFetched] = useState(false)
  const { list } = useSelector( ({faq}) => faq )

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if( list ) {
      //let newList = orderBy(list, ['price'],['asc']);
      setTables(list)
    }  else {
      setTables([itemMaster])
    }
  }, [list])

  useEffect(() => {
    fetchData()
    return () => {
      setTimeout(()=> setFetched(false),1000)
     
    }
  }, [fetched])
  

  const fetchData = () => {
    dispatch(listFaq({}))
  }
  const onBack = () => {
    setEdit(false)
    setAdd(false)
    fetchData()
  }

  const onSave = (items,title) => {
    //let ad = tables 
    //ad[currentIndex] = {...item,items:items,name:title,slug:kebabCase(title)}
    //setTables(prev=>[...ad])
    fetchData()
    setEdit(false)
    setAdd(false)
  }

  const onAdd = () => {
    let newItem = []
    newItem.push(itemMaster)
    setAdd(true)
    setItem(itemMaster)
    setEdit(true)
    
    setCurrentIndex(isEmpty(tables)?0:tables.length)
    setTables(prev=>[...prev, ...newItem])
  }

  const onEdit = (table, index) => {
    setEdit(true)
    setItem(table)
    setCurrentIndex(index)
  }

  const onRemove = (id) => {
    let heatItems = tables
    heatItems.splice(id, 1)
    setTables((prev)=>[...heatItems])
  }

  const onUp = (table, index) => {
    if( table.sequence < 0 ) {
      return
    }
    let ad = tables 
    let prevTable = ad[index-1]

    dispatch(updateFaqSection(table._id,{
      ...table,
      sequence:table.sequence>0?table.sequence-1:index-1
    }))

    dispatch(updateFaqSection(prevTable._id,{
      ...prevTable,
      sequence:table.sequence>0?table.sequence:index
    }))
    setFetched(true)
  }

  const onDown = (table, index) => {
    let ad = tables 
    if( table.sequence >= ad.length-1 ) {
      return
    }
    let prevTable = ad[index+1]

    dispatch(updateFaqSection(table._id,{
      ...table,
      sequence:table.sequence>0?table.sequence+1:index+1
    }))

    dispatch(updateFaqSection(prevTable._id,{
      ...prevTable,
      sequence:table.sequence>0?table.sequence:index
    }))
    setFetched(true)
  }

  let reItems = tables.sort((a,b)=> a.sequence - b.sequence)
  //let reItems = tables
  return (<div>
        {edit == false && <Button color="primary" disableElevation style={{marginBottom:30}} variant="contained" onClick={onAdd} size="medium">Add Section</Button>}
        {!isEmpty(reItems) && <div style={{textAlign:"left"}}>
            {edit == false && <Grid container style={{paddingTop:0}} direction="row" justify={fetched?"center":"flex-start"} alignItems={"flex-start"}>
              
            {fetched ? <CircularProgress /> : reItems.map((table,index)=> {
                const faqs = table.faq.filter((it)=>it.statusActive==true)
                return <Grid key={index} spacing={3} item sm={12} md={8} lg={8}>
                    <Accordion style={{marginBottom:20}}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id={`section${table._id}-header`}
                        >
                          <div style={{flexBasis:'87%'}}>
                            <Typography component={"h5"} variant="h5">{table.title}</Typography>
                            <Typography component={"p"} color="default" variant="body2">( {faqs.length} Item QA )</Typography>
                          </div>
                        
                          <div style={{flexBasis:'13%'}}>
                              <IconButton onClick={()=>onEdit(table, index)} size="large" color="default" variant="outlined">
                                  <EditIcon />
                              </IconButton>
                              <IconButton onClick={()=>onRemove(index)} size="large" color="default" variant="outlined">
                                  <DeleteIcon />
                              </IconButton>

                              {index > 0 && <IconButton onClick={()=>onUp(table, index)} size="large" color="default" variant="outlined">
                                    <Up />
                                </IconButton>}
                              {(index < reItems.length-1) && <IconButton onClick={()=>onDown(table, index)} size="large" color="default" variant="outlined">
                                    <Down />
                                </IconButton>}
                              
                              
                          </div>
                        </AccordionSummary>
                        {!isEmpty(faqs) && <AccordionDetails style={{borderTop:'1px solid #ccc', display:"block"}}>
                            {faqs.sort((a,b)=>a.sequence-b.sequence).map((q,index)=><div style={{display:"block"}}>
                                <div style={{display:"block"}}>
                                  <Typography component={"h6"} variant="h6" style={{fontWeight:"500"}}>{q.title}</Typography>
                                  <Typography color={"textSecondary"} style={{paddingLeft:12,borderLeft:"3px solid #eee"}}>
                                    <ReactMarkdown children={q.content} plugins={[remarkGfm]} />
                                  </Typography>
                              </div>
                            </div>
                            )}
                        </AccordionDetails>}
                    </Accordion>
                </Grid>})}
            </Grid>}

            {edit && <Grid container style={{paddingTop:0}} direction="row" justify="flex-start" alignItems={"flex-start"}>
              <Grid item sm={12} md={8} lg={8}>
                <Section add={add} name={item.title} {...item} onBack={onBack} current={currentIndex} onSaveSection={onSave} />
              </Grid> 
              <Grid item sm={12} md={4} lg={4}>
                <Alert variant="outlined" severity="info" style={{marginTop:150}}>
                  Berikut daftar format untuk styling               
                </Alert>
                <TableContainer component={Paper} style={{marginTop:20}}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead style={{background:"#eee"}}>
                        <TableRow>
                          <TableCell style={{fontWeight:"bold"}}>Element</TableCell>
                          <TableCell style={{fontWeight:"bold"}}>Syntax</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                          <TableRow>
                            <TableCell component="th" style={{fontWeight:"bold"}} scope="row">
                                Heading
                            </TableCell>
                            <TableCell>
                                <p><code># H1</code></p>
                                <p><code>## H2</code></p>
                                <p><code>### H3</code></p>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell component="th" style={{fontWeight:"bold"}} scope="row">
                                Image from Google Drive
                            </TableCell>
                            <TableCell>
                              <code>{`![alt text](https://storage.googleapis.com/padar-gallery/x6h189l1j0338y_gallery.png "image Title")`}</code>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell component="th" style={{fontWeight:"bold"}} scope="row">
                                Bold
                            </TableCell>
                            <TableCell>
                                <code>**bold text**</code>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell component="th" style={{fontWeight:"bold"}} scope="row">
                                Italic
                            </TableCell>
                            <TableCell>
                              <code>*italic text*</code>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell component="th" style={{fontWeight:"bold"}} scope="row">
                                Blockquote
                            </TableCell>
                            <TableCell>
                              <code>{`> blockquote`}</code>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell component="th" style={{fontWeight:"bold"}} scope="row">
                                Ordered List
                            </TableCell>
                            <TableCell>
                              <ol style={{padding:10}}>
                                <li><code>First item</code></li>
                                <li><code>Second item</code></li>
                                <li><code>Third item</code></li>
                              </ol>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell component="th" style={{fontWeight:"bold"}} scope="row">
                                Unordered List
                            </TableCell>
                            <TableCell>
                                <p><code>- First item</code></p>
                                <p><code>- Second item</code></p>
                                <p><code>- Third item</code></p>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell component="th" style={{fontWeight:"bold"}} scope="row">
                                Link
                            </TableCell>
                            <TableCell>
                                <p><code>[title](https://www.example.com)</code></p>
                               
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell component="th" style={{fontWeight:"bold"}} scope="row">
                                Image
                            </TableCell>
                            <TableCell>
                                <p><code>![alt text](image.jpg)</code></p>
                               
                            </TableCell>
                          </TableRow>

                      </TableBody>
                    </Table>
                  </TableContainer>
              </Grid> 
            </Grid>}
        </div>}
  </div>
  )
}

const Section = ({ _id, name, onBack, onSaveSection, current, add, ...rest}) => {
    const [title, setTitle] = useState("")
    const [items, setItems] = useState([])
    const [sectionId, setSectionId] = useState(0)
    const [isNewAdded, setIsNewAdded] = useState(false)
    const [openGallery, setOpenGallery] = useState(false)
    const classes = useStyles()
    const dispatch = useDispatch()
    const { section } = useSelector( ({faq}) => faq )
    const { status } = useSelector( ({notification}) => notification )
    const itemMaster = {
      _id:0,
      sequence:0,
      status:true,
      title:"",
      content:"",
    }

    useEffect(()=>{
      setTitle(name)
      setSectionId(_id)
      setItems(rest.faq.filter(it=>it.statusActive==true))
      //setItems(rest.items)
      return () => {
        setTitle("")
        setSectionId(0)
        setItems([])
      }
    },[])

    useEffect(()=>{
      if( isNewAdded && section ) {
        setSectionId(section?.faqSection?._id)
      }
      
    },[section])

    useEffect(()=>{
      if( status == STATUSES.SUCCESS ) {
        onBack()
      }
    },[status])

    const onSave = () => {
      if( add ) {
        dispatch( addFaqSection({title}) )
        setIsNewAdded(true)
      } else {
        if( title != name ) {
          dispatch(updateFaqSection(sectionId, {title}))
        }
      }

      if( !isEmpty(items) ) {
        if( add ) {
          Promise.all(items.map(per => dispatch(addFaq({
            title:per.title,
            content:per.content,
            sectionId:sectionId
          })))).then(values=>{
            onBack()
          })

        } else {
          
          let promises = items.map(per => {
            if( isNumber(per._id ) ) {
              return dispatch(addFaq({
                title:per.title,
                content:per.content,
                sectionId:sectionId,
                sequence:per.sequence
              }))
            } else {
              return dispatch(updateFaq(per._id,{
                title:per.title,
                content:per.content,
                sequence:per.sequence
              }))
            }
            
          })
          Promise.all(promises).then(values=>{
            onBack()
          })
        }
      }
    }
    
    const onToggleGallery = () => {
      setOpenGallery(!openGallery)
    }

    const handleChange = (e) => {
        setTitle(e.target.value)
    }

    const onAddQuestion = () => {
      let newItem = []
      itemMaster._id = (items.length-1) + 1
      itemMaster.sequence = (items.length-1) + 1
      newItem.push(itemMaster)
      setItems(prev=>[...prev, ...newItem])
    }

    const onRemove = (id) => {
     
      let heatItems = items
      heatItems.splice(id, 1)
      setItems((prev)=>[...heatItems])

      if( !add ) {
        dispatch(removeFaq(id))
      }
    }

    const onSetTitle = (id, value) => {
      let ad = items 
      ad[id].title = value
      setItems(prev=>[...ad])
    }

    const onSetDesc = (id, value) => {
      let ad = items 
      ad[id].content = value
      setItems(prev=>[...ad])
    }

    const onUp = (item, index) => {
      if( item.sequence < 1 ) {
        return
      }
      let ad = items 
      ad[index].sequence = item.sequence - 1
      ad[index-1].sequence = item.sequence + 1
      setItems(prev=>[...ad])
    }

    const onDown = (item, index) => {
      let ad = items 
      if( item.sequence >= ad.length-1 ) {
        return
      }
      ad[index].sequence = item.sequence + 1
      ad[index+1].sequence = item.sequence - 1
    
      setItems(prev=>[...ad])
    }

    let reItems = items.sort((a,b)=>a.sequence-b.sequence)

    console.table(reItems)

    return(<Grid 
            container 
            direction="row" 
            justify="flex-start" 
            alignItems={"flex-start"}
        >
        <Grid item xs={12} lg={12}>
            <Grid 
              container 
              direction="row" 
              justify="space-between" 
              alignItems={"center"}
            >
              <Grid item xs={6} lg={6}>
                  <Button variant="outlined" style={{marginBottom:20}} color="default" onClick={()=>onBack()}>
                    Kembali
                  </Button>
              </Grid>
              <Grid item>
                  <MediaGallery 
                      title={"Pick One"}
                      expanded={openGallery} 
                      doCollapse={onToggleGallery} 
                      disableInsert={true}
                      pushItem={(item)=>{
                        onToggleGallery()
                      }}
                  />
              </Grid>
            </Grid>
        </Grid>

        <Grid item xs={12} lg={12}>
          <Grid 
            container 
            direction="row" 
            justify="flex-start" 
            alignItems={"flex-start"}
          >
            <Grid item xs={10} lg={10}>
              <TextField
                  required
                  id="title"
                  fullWidth
                  name="title"
                  label="Section"
                  variant="outlined"
                  onChange={handleChange}
                  value={title}
                  placeholder="New section"
              />

              {sectionId != -1 && <>
                <IconButton onClick={onAddQuestion} size="large" color="default">
                  <AddIcon />
                </IconButton>
                <IconButton onClick={onToggleGallery} size="large" color="default">
                  <ImageIcon />
                </IconButton>
              </>}

                <div style={{marginTop:20}}>
                    {!isEmpty(reItems) && <div>
                        {reItems.map((item,index)=><Grid key={index} container 
                          direction="row" 
                          style={{marginBottom:10}}
                          justify="flex-start" 
                          alignItems={"flex-start"}>
                            <Grid item xs={10} lg={10}>
                              <QAnswer {...item} index={index} onSetTitle={onSetTitle} onSetDesc={onSetDesc} />
                            </Grid>
                            <Grid item xs={2} lg={2} style={{alignItems:"flex-start", display:"flex"}}>
                              <IconButton onClick={()=>onRemove(item._id)} size="large" color="default" variant="outlined">
                                  <DeleteIcon />
                              </IconButton>
                              {index > 0 && <IconButton onClick={()=>onUp(item, index)} size="large" color="default" variant="outlined">
                                  <Up />
                              </IconButton>}
                              {(index < items.length-1) && <IconButton onClick={()=>onDown(item, index)} size="large" color="default" variant="outlined">
                                  <Down />
                              </IconButton>}
                            </Grid>
                        </Grid>)}
                    </div>}
                </div>
              
            </Grid>

            <Grid item xs={2}lg={2}>
              <Button variant="contained" size="large" disableElevation style={{marginBottom:20,marginLeft:20}} color="primary" onClick={()=>onSave()}>
                {sectionId   !== -1 ? 'Save': 'Create'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
    </Grid>)
}

const QAnswer = (props) => {
  const classes = useStyles();
  const [title, setTitle] = useState("")
  const [desc, setDescription] = useState("")
  const [expanded, setExpanded] = useState(true)

  useEffect(()=>{
    setTitle(props.title)
    setDescription(props.content)
  },[props])

  const handleTitle = (e) => {
    setTitle(e.target.value)
  }

  const handleContent = (e) => {
    setDescription(e.target.value)
  }

  const onBlurTitle = (e) => {
    if( title != props.title ) {
      props.onSetTitle(props.index,title)
    }
   
  }

  const onBlurContent = (e) => {
    if( desc != props.content ) {
      let rawDesc = desc
     
      props.onSetDesc(props.index,desc)
    }
  }

  const urlify = (text) => {
    let regex = /((https:\/\/drive.google.com\/file\/d)[a-zA-Z0-9_](\/\S*)?)/g 
    return text.replace(regex, (url) => {
      console.log("url",url)
      return `${url}/TEST`
      
    })
  }

  return (
      <Accordion expanded={expanded} onChange={(e,expand)=>setExpanded(expand)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <TextField fullWidth={true} id="question-basic" onChange={(e)=>handleTitle(e)} onBlur={(e)=>onBlurTitle()} placeholder="New Question" label="Pertanyaan" value={title} />
        </AccordionSummary>
        <AccordionDetails>
            <TextField id="desc-basic" rows={5} fullWidth={true} onChange={(e)=>handleContent(e)} onBlur={(e)=>onBlurContent()} label="Jawaban" placeholder="Here the answer"  multiline={true} value={desc} />
        </AccordionDetails>
      </Accordion>
    );
}

FAQ.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  common: state.common
});

export default connect(mapStateToProps, { })(FAQ)